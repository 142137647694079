import { RoomStayDTO } from 'src/core/Reservation/infrastructure/Reservation.api.dto'
import { mapPriceDTO } from './mapPriceDTO'
import { Price } from 'src/core/Shared/domain/Price.model'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export const calculateOriginalDeposit = (roomStays: RoomStayDTO[]): Price => {
  const priceOptions = { getConverted: false }
  const originalCurrency = mapPriceDTO(
    roomStays[0].rate.stay.base,
    priceOptions,
  ).currency

  const originalDepositValue = roomStays.reduce((prev, stay) => {
    const originalDepositValue = isDefined(stay.rate.guarantee.deposit)
      ? mapPriceDTO(stay.rate.guarantee.deposit, priceOptions).value
      : 0

    return prev + originalDepositValue
  }, 0)

  return {
    value: originalDepositValue,
    currency: originalCurrency,
  }
}
