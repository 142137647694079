import { useCookies as useCookiesBase } from 'react-cookie'

export const useCookies = () => {
  const [cookies, setCookie, removeCookie] = useCookiesBase()

  return {
    cookies,
    setCookie,
    removeCookie,
  }
}
