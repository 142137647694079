import { AvailabilityCriteria } from 'src/core/Availability/domain/Availability.model'
import {
  isDefined,
  isEmpty,
  isString,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Time } from 'src/core/Shared/infrastructure/Time'

type AvailabilityCriteriaType = 'single' | 'multi'

interface Primitives {
  market: string
  hotel: string
  checkIn: string
  checkOut: string
  adults: string
  children?: string
  childrenAges?: string
  currency: string
  coupon?: string
  groupCode?: string
  membership: boolean
  roomIds?: string[]
  rateIds?: string[]
  ratesCurrencies?: string[]
}

const childrenAgesSeparator = '|'
const roomSeparator = ','

export const buildAvailabilityCriteriaFromPrimitives = (
  primitives: Primitives,
): AvailabilityCriteria => {
  const {
    market,
    hotel,
    checkIn,
    checkOut,
    adults,
    children,
    childrenAges,
    currency,
    membership,
    coupon,
    groupCode,
  } = primitives
  // Nos basamos en que adults es igual que rooms ya que se comparan como required params.
  // Por ahora no usamos rooms porque es opcional y adults es obligatorio.
  const numberOfRooms = adults.split(roomSeparator).length

  return {
    market,
    hotel,
    checkIn: Time.fromString(checkIn).toUTCDate(),
    checkOut: Time.fromString(checkOut).toUTCDate(),
    adults: getAdultsByRoom(adults, roomSeparator),
    children: getChildrenByRoom(children, numberOfRooms, roomSeparator),
    childrenAges: getChildrenAgesByRoom(
      childrenAges,
      numberOfRooms,
      roomSeparator,
      childrenAgesSeparator,
    ),
    groupCode,
    coupons: isDefined(coupon) ? [coupon] : undefined,
    currency,
    membership,
  }
}
const getAdultsByRoom = (
  adults: string,
  roomSeparator: string,
): AvailabilityCriteria['adults'] => {
  if (!isString(adults)) {
    return [0]
  }

  return adults.split(roomSeparator).map(adultByRoom => parseInt(adultByRoom))
}

const getChildrenByRoom = (
  children: string | undefined,
  numberOfRooms: number,
  roomSeparator: string,
): AvailabilityCriteria['children'] => {
  if (!isString(children)) {
    return Array.from({ length: numberOfRooms }).map(() => 0)
  }

  return children
    .split(roomSeparator)
    .map(childrenByRoom => parseInt(childrenByRoom))
}

const getChildrenAgesByRoom = (
  childrenAges: string | undefined,
  numberOfRooms: number,
  roomSeparator: string,
  childrenAgesSeparator: string,
) => {
  if (!isString(childrenAges)) {
    return Array.from({ length: numberOfRooms }).map(() => [])
  }

  return childrenAges
    .split(roomSeparator)
    .map(room =>
      isEmpty(room)
        ? []
        : room.split(childrenAgesSeparator).map(age => parseInt(age)),
    )
}

export const isMultiroomFromPrimitives = ({
  rooms,
  adults,
}: {
  rooms: unknown
  adults: unknown
}) => {
  if (isUndefined(rooms) || isUndefined(adults)) {
    return false
  }

  const roomCount = typeof rooms === 'string' ? Number(rooms) : 0
  if (Number.isNaN(roomCount) || roomCount <= 1) {
    return false
  }

  const moreThanOneOccupancy =
    typeof adults === 'string' && adults.split(',').length > 1

  return moreThanOneOccupancy
}

export const getAvailabilityCriteriaType = (
  availabilityCriteria: AvailabilityCriteria | undefined,
): AvailabilityCriteriaType | undefined => {
  if (isUndefined(availabilityCriteria)) {
    return undefined
  }

  return availabilityCriteria.adults.length >= 2 ? 'multi' : 'single'
}
