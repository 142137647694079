import { HotelRepository } from 'src/core/Hotel/domain/Hotel.repository'
import { CheckInCheckOutTime } from 'src/core/Hotel/domain/Hotel.model'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export const getCheckInAndCheckOutTime =
  ({ hotelRepository }: Dependencies) =>
  async (hotelId: string): Promise<CheckInCheckOutTime> => {
    const checkInAndCheckOutTime =
      await hotelRepository.getCheckInAndCheckOutTime(hotelId)

    if (isUndefined(checkInAndCheckOutTime)) {
      // Si no devuelve valor la llamada o da fallo, devolvemos valores por defecto
      return {
        checkInTime: '14:00',
        checkOutTime: '12:00',
      }
    }

    return checkInAndCheckOutTime
  }

interface Dependencies {
  hotelRepository: HotelRepository
}
