import { useForm } from 'react-hook-form'
import {
  isEmpty,
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { CouponType as CouponTypeModel } from 'src/core/Shared/domain/CouponType'
import { ReservationCouponType } from 'src/core/Reservation/domain/Coupon'

export enum CouponFormType {
  None = 'none',
  PromotionalCode = 'promotionalCode',
  GroupCode = 'groupCode',
}

export const formCouponTypeMap: {
  [key in CouponFormType]: ReservationCouponType
} = {
  [CouponFormType.None]: 'empty',
  [CouponFormType.GroupCode]: 'group',
  [CouponFormType.PromotionalCode]: 'promotional',
}

export interface CouponsFormValues {
  type: CouponFormType
  value: string
}

export const useCouponsForm = (initialCoupon?: {
  type: CouponTypeModel
  value: string
}) => {
  const initialValues = isDefined(initialCoupon)
    ? {
        type: couponTypeMap[initialCoupon.type],
        value: initialCoupon.value,
      }
    : undefined
  const methods = useForm<CouponsFormValues>({ defaultValues: initialValues })

  const getCoupon = (): CouponsFormValues | undefined => {
    const value = methods.getValues('value')
    const type = methods.getValues('type')

    return isEmpty(value)
      ? undefined
      : {
          value,
          type,
        }
  }

  const getCouponWithEmptyOption = (
    formData: CouponsFormValues,
  ): CouponsFormValues => {
    return isEmpty(formData.value)
      ? { value: '', type: CouponFormType.None }
      : formData
  }

  const areValuesSameAsOld = () => {
    const sameCode = initialValues?.value === getCoupon()?.value
    const sameType = isUndefined(initialValues?.type)
      ? CouponFormType.None
      : initialValues?.type === methods.getValues('type')

    return sameCode && sameType
  }

  return {
    methods,
    getCoupon,
    getCouponWithEmptyOption,
    areValuesSameAsOld,
  }
}

const couponTypeMap: { [key in CouponTypeModel]: CouponFormType } = {
  group: CouponFormType.GroupCode,
  myBarcelo: CouponFormType.None,
  promotional: CouponFormType.PromotionalCode,
}
