import { container } from 'src/core/Shared/_di'

export const handleItineraryNumberErrorNotFound = () => {
  container.resolve('cartRepository').deleteCart()
}

export const handleItineraryNumberErrorDoesNotMatch = (error: unknown) => {
  if (error instanceof Error) {
    container.resolve('logger').warning(error.message)
  } else {
    container.resolve('logger').warning(JSON.stringify(error))
  }
  container.resolve('cartRepository').deleteCart()
}
