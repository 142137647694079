import {
  FocusEventHandler,
  FormEventHandler,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  useState,
} from 'react'
import styles from './CheckboxField.module.scss'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { Text } from '../Text'
import { CheckedCheckboxIcon } from 'src/ui/components/atoms/Icon/_icons/system/CheckedCheckboxIcon'
import { UncheckedCheckboxIcon } from 'src/ui/components/atoms/Icon/_icons/system/UncheckedCheckboxIcon'
import { UncheckedHoveredCheckboxIcon } from 'src/ui/components/atoms/Icon/_icons/system/UncheckedHoveredCheckboxIcon'
import { DisabledCheckboxIcon } from 'src/ui/components/atoms/Icon/_icons/system/DisabledCheckboxIcon'

interface Props {
  children: ReactNode
  checked: boolean
  error?: { message?: string }
  onChange?: FormEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  name: string
  min?: string | number
  max?: string | number
  maxLength?: number
  minLength?: number
  pattern?: string
  required?: boolean
  disabled?: boolean
}

const CheckboxWithRef: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    children,
    checked,
    error,
    onChange,
    onBlur,
    name,
    min,
    max,
    maxLength,
    minLength,
    pattern,
    required,
    disabled,
    ...props
  },
  ref,
) => {
  const [hover, setHover] = useState(false)

  const getColor = () => {
    if (disabled && !checked) {
      return 'icon-disabled'
    }

    if (error) {
      return 'support-error'
    }

    return 'icon-dark'
  }

  const getIcon = () => {
    if (disabled && !checked) {
      return DisabledCheckboxIcon
    }

    if (checked) {
      return CheckedCheckboxIcon
    }

    if (hover) {
      return UncheckedHoveredCheckboxIcon
    }

    return UncheckedCheckboxIcon
  }

  const toggleHover = () => {
    if (!disabled && !checked) {
      setHover(!hover)
    }
  }

  return (
    <>
      <input
        {...props}
        type="checkbox"
        ref={ref}
        id={name}
        className={styles.checkbox}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        min={min}
        max={max}
        maxLength={maxLength}
        minLength={minLength}
        pattern={pattern}
        required={required}
        disabled={disabled}
      />
      <label
        htmlFor={name}
        className={styles.label}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
      >
        <Icon size="l" color={getColor()} icon={getIcon()} />
        {children}
      </label>

      {error && (
        <Text fontStyle="s-300" color="support-error" className={styles.error}>
          {error.message}
        </Text>
      )}
    </>
  )
}

export const CheckboxField = forwardRef(CheckboxWithRef)
