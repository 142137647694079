import { useModal, createModal } from 'src/ui/hooks/useModal'
import { Text, Button, Modal } from 'src/ui/components'
import { Flex } from 'src/ui/styles/objects/Flex'
import { useTrans } from 'src/ui/hooks/useTrans'
import { container } from 'src/core/Shared/_di'
import { useAsyncMutation } from 'src/ui/hooks/useMutation'
import { useUser } from 'src/ui/contexts/UserContext'
import { useState } from 'react'

export const useHandleLogoutError = () => {
  const { showModal, hideModal } = useModal(LogoutErrorModal)
  const { token } = useUser()

  const { performMutation: handleSubmit } = useAsyncMutation(
    'handleSubmit',
    async () => {
      await container.resolve('logout')(token)
      hideModal()
    },
  )

  const handleLogoutError = (error: unknown) => {
    container.resolve('logger').error(error)

    showModal({
      onClose: hideModal,
      onSubmit: handleSubmit,
    })
  }

  return {
    handleLogoutError,
  }
}

interface Props {
  onClose: () => void
  onSubmit: () => void
}

const LogoutErrorModal = createModal(({ onClose, onSubmit }: Props) => {
  const { trans } = useTrans(['common'])

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleOnSubmit = () => {
    setIsSubmitting(true)
    onSubmit()
  }

  return (
    <Modal
      size="m"
      type="transactional"
      onClose={onClose}
      data-quantum-modal="LogoutErrorModal"
    >
      <Text fontStyle="xl-700" color="dark" as="h1" centered className="mb-m">
        {trans('logout-error-modal_title')}
      </Text>
      <Text fontStyle="m-300" color="dark" as="p" centered>
        {trans('logout-error-modal_description')}
      </Text>
      <Flex justifyContent="center">
        <Button
          className="mt-xl"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          size={{
            mobile: 'fullWidth',
            laptop: 'large',
          }}
          onClick={handleOnSubmit}
        >
          {trans('logout-error-modal_submit')}
        </Button>
      </Flex>
    </Modal>
  )
})
