import { useEffect } from 'react'

interface Props {
  onClose?: () => void
}

export const useEscapeOnClose = ({ onClose }: Props) => {
  useEffect(() => {
    if (!onClose) {
      return
    }

    const handleOnEscPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault()
        onClose?.()
      }
    }
    document.addEventListener('keydown', handleOnEscPress, false)

    return () =>
      document.removeEventListener('keydown', handleOnEscPress, false)
  }, [onClose])
}
