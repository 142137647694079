import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useCountry } from '../contexts/CountryContext'
import { useTrans } from './useTrans'

export const useCallCenter = () => {
  const { trans } = useTrans(['common'])

  const country = useCountry()

  const callCenterPhone = isDefined(country) ? country.callCenter.phone : ''
  const callCenterFreePhone =
    isDefined(country) && country.callCenter.free
      ? ` (${trans('call-center_free')})`
      : ''

  return {
    callCenterCountryCode: country.code,
    callCenterPhone,
    callCenterFreePhone,
  }
}
