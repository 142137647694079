import {
  isEmpty,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export const mapImageWithDomain = (
  image: string | undefined,
  defaultImage: string,
) => {
  if (isUndefined(image) || isEmpty(image)) {
    return defaultImage
  }
  return image
}
