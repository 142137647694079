import { Price } from 'src/core/Shared/domain/Price.model'

export type ExtraType =
  | 'PER_PERSON_STAY'
  | 'PER_PERSON_NIGHT'
  | 'PER_UNITS_STAY'
  | 'PER_UNITS_NIGHT'
  | 'PER_STAY'
  | 'PER_NIGHT'
  | 'PER_TYPE_GUEST_STAY'
  | 'PER_TYPE_GUEST_NIGHT'

export interface AppliedExtraSummary {
  netPrice: Price
  tax: {
    percentage: string
    amount: Price
  }
  grossPrice: Price
}

export interface AppliedExtra {
  id: string
  type: ExtraType
  applied: true
  name: string
  required: boolean
  summary: AppliedExtraSummary
  description: string
  imageUrl: string
}

export interface AppliedExtraPerUnit extends AppliedExtra {
  quantity: number
}

export interface AppliedExtraPerGuest extends AppliedExtra {
  adultsQuantity: number
  childrenQuantity: number
}

export interface NotAppliedExtra {
  id: string
  applied: false
}

export type BookingConfirmationExtras = (AppliedExtraPerUnit &
  AppliedExtraPerGuest)[]

export const isExtraPerUnit = (type: ExtraType): boolean =>
  type === 'PER_UNITS_STAY' ||
  type === 'PER_UNITS_NIGHT' ||
  type === 'PER_PERSON_STAY' ||
  type === 'PER_PERSON_NIGHT'

export const isExtraPerStay = (type: ExtraType): boolean =>
  type === 'PER_STAY' || type === 'PER_NIGHT'

export const isExtraPerGuest = (type: ExtraType): boolean =>
  type === 'PER_TYPE_GUEST_STAY' || type === 'PER_TYPE_GUEST_NIGHT'

export const isFreeAppliedExtra = (extra: AppliedExtra): boolean => {
  const { netPrice } = extra.summary
  return netPrice.value === 0
}

export const getExtraTotalQuantity = (
  extra: AppliedExtraPerUnit | AppliedExtraPerGuest,
): number => {
  if (isExtraPerUnit(extra.type) || isExtraPerStay(extra.type)) {
    return Number((extra as AppliedExtraPerUnit).quantity)
  }

  return (
    Number((extra as AppliedExtraPerGuest).adultsQuantity) +
    Number((extra as AppliedExtraPerGuest).childrenQuantity ?? 0)
  )
}

export const getExtraQuantities = (
  extra: AppliedExtraPerUnit | AppliedExtraPerGuest | NotAppliedExtra,
) => {
  if (isAppliedExtraPerUnitOrPerGuest(extra)) {
    if (isExtraPerUnit(extra.type) || isExtraPerStay(extra.type)) {
      return { quantity: (extra as AppliedExtraPerUnit).quantity }
    }

    return {
      adultsQuantity: (extra as AppliedExtraPerGuest).adultsQuantity,
      childrenQuantity: (extra as AppliedExtraPerGuest).childrenQuantity ?? 0,
    }
  }
}

export const isAppliedExtraPerUnitOrPerGuest = (
  object: AppliedExtraPerUnit | AppliedExtraPerGuest | NotAppliedExtra,
): object is AppliedExtraPerUnit | AppliedExtraPerGuest =>
  'quantity' in object || 'adultsQuantity' in object
