import { FC, ReactNode } from 'react'
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs'
import { Text } from 'src/ui/components/atoms/Text'
import styles from './CustomTabs.module.scss'
import { classNames } from 'src/ui/utils/classnames'

interface Tabs {
  title: string
  container: ReactNode
}
interface Props {
  tabs: Tabs[]
  className?: string
}

export const CustomTabs: FC<Props> = ({ tabs, className }) => {
  return (
    <Tabs selectedTabClassName={styles.tabActive}>
      <TabList className={classNames(styles.tabListContainer, className)}>
        {tabs.map(tab => {
          return (
            <Tab key={tab.title} className={styles.tab}>
              <Text fontStyle="m-300">{tab.title}</Text>
            </Tab>
          )
        })}
      </TabList>

      {tabs.map(tab => {
        return <TabPanel key={tab.title}>{tab.container}</TabPanel>
      })}
    </Tabs>
  )
}
