import { NextRouter } from 'next/router'
import { CheckInCheckOut } from 'src/core/Shared/domain/CheckInCheckOut'
import { Time } from 'src/core/Shared/infrastructure/Time'
import {
  isUndefined,
  omit,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { CouponsFormValues, CouponFormType } from 'src/ui/hooks/useCouponsForm'
import { QueryParams } from './useApplicationRouter.model'
import {
  AvailabilityMealplanFilter,
  AvailabilityRoomTypeFilter,
} from 'src/core/Availability/domain/Availability.model'
import { ParsedUrlQuery } from 'node:querystring'
import { urlSeparator } from './useApplicationRouter.model'

interface Guests {
  adults: number[]
  children: number[]
  childrenAges?: Array<Array<number>>
}

export const queryBuilder = (router: NextRouter) => ({
  buildMealplanFiltersQuery: (newFilter: AvailabilityMealplanFilter) => {
    if (router.query.mealplan === newFilter.id) {
      return queryBuilder(router).buildQueryWithoutMealplanFilters()
    }

    return { ...router.query, mealplan: newFilter.id } as ParsedUrlQuery
  },
  buildRoomTypeFiltersQuery: (newFilter: AvailabilityRoomTypeFilter) => {
    return { ...router.query, roomcode: newFilter } as ParsedUrlQuery
  },

  buildQueryWithoutMealplanFilters: () =>
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (({ mealplan, ...properties }) => properties)(router.query),

  buildQueryWithoutRoomTypeFilter: () =>
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (({ roomcode, ...properties }) => properties)(router.query),

  buildGuestsQuery: (guests: Guests) => {
    const { adults, children, childrenAges } = guests

    const hasToParseChildren =
      children.length > 1 || (children.length === 1 && children[0] > 0)

    const query = {
      ...router.query,
      adult: adults.toString(),
      child: hasToParseChildren ? children.toString() : undefined,
      childages:
        childrenAges && hasToParseChildren
          ? childrenAges.map(childAge => childAge.join(urlSeparator)).join(',')
          : undefined,
      rooms: adults.length > 1 ? adults.length.toString() : undefined,
    }

    return cloneWithoutEmptyQueryParams(query)
  },
  buildDatesQuery: ({ checkIn, checkOut }: CheckInCheckOut) => ({
    ...router.query,
    arrive: Time.fromDate(checkIn).format('YYYY-MM-DD'),
    depart: Time.fromDate(checkOut).format('YYYY-MM-DD'),
  }),
  buildCouponQuery: (coupon?: CouponsFormValues) => {
    router.query = omit(router.query, ['coupon', 'groupcode'])
    if (isUndefined(coupon)) {
      return router.query
    }

    return {
      ...router.query,
      ...couponParamMap(coupon?.value)[coupon?.type],
    }
  },
  buildEditAvailabilityCriteriaQuery: (
    guests: Guests,
    dates: CheckInCheckOut,
    coupon?: CouponsFormValues,
  ) => {
    let guestsQuery = queryBuilder(router).buildGuestsQuery(guests)
    guestsQuery = omit(guestsQuery, ['coupon', 'groupcode'])

    if (isUndefined(coupon)) {
      return {
        ...guestsQuery,
        arrive: Time.fromDate(dates.checkIn).format('YYYY-MM-DD'),
        depart: Time.fromDate(dates.checkOut).format('YYYY-MM-DD'),
      }
    }

    return {
      ...guestsQuery,
      arrive: Time.fromDate(dates.checkIn).format('YYYY-MM-DD'),
      depart: Time.fromDate(dates.checkOut).format('YYYY-MM-DD'),
      ...couponParamMap(coupon?.value)[coupon?.type],
    }
  },
})

const couponParamMap = (
  couponValue?: string,
): { [key in CouponFormType]: object | undefined } => ({
  [CouponFormType.GroupCode]: { groupcode: couponValue },
  [CouponFormType.PromotionalCode]: { coupon: couponValue },
  [CouponFormType.None]: undefined,
})

const cloneWithoutEmptyQueryParams = (
  query: Record<string, string | undefined>,
): QueryParams => {
  return JSON.parse(JSON.stringify(query)) as QueryParams
}
