import { WithInjectedParams } from 'src/core/Shared/_di/types'
import { Sentry } from './sentry'
import { Logger } from 'src/core/Shared/domain/Logger'
import { ConsoleLogger } from 'src/core/Shared/domain/ConsoleLogger'
import { EnvManager } from 'src/core/Shared/domain/EnvManager'
import { noop } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

interface LoggerDependencies {
  sentry: Sentry
  consoleLogger: ConsoleLogger
  envManager: EnvManager
}

export const logger: WithInjectedParams<Logger, LoggerDependencies> = ({
  sentry,
  consoleLogger,
  envManager,
}) => {
  const loggerConfig = envManager.getLoggerConfig()

  return {
    error: error => {
      const loggerConfigMap: { [key: string]: (error: unknown) => void } = {
        console: consoleLogger.error,
        sentry: sentry.sendError,
      }

      loggerConfig.error.forEach(config => {
        loggerConfigMap[config](error)
      })
    },
    warning: message => {
      const loggerConfigMap: { [key: string]: (message: string) => void } = {
        console: consoleLogger.warning,
        sentry: sentry.sendWarning,
      }

      loggerConfig.warning.forEach(config => {
        loggerConfigMap[config](message)
      })
    },
    info: (message, context) => {
      const loggerConfigMap: {
        [key: string]: (
          message: string,
          context?: Record<string, unknown>,
        ) => void
      } = {
        console: consoleLogger.info,
        sentry: noop,
      }
      loggerConfig.info.forEach(config => {
        loggerConfigMap[config](message, context)
      })
    },
    setUser: email => {
      const loggerConfigMap: {
        [key: string]: (email: string) => void
      } = {
        console: noop,
        sentry: sentry.setUser,
      }
      loggerConfig.setUser.forEach(config => {
        loggerConfigMap[config](email)
      })
    },
    setContext: (context, key) => {
      const loggerConfigMap: {
        [key: string]: (context: Record<string, unknown>) => void
      } = {
        console: noop,
        sentry: () => sentry.addContextInfo(context, key),
      }

      loggerConfig.setContext.forEach(config => {
        loggerConfigMap[config](context)
      })
    },
  }
}
