import { CouponDiscountType } from 'src/core/Shared/domain/CouponDiscount'
import { Time } from 'src/core/Shared/infrastructure/Time'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { ReservationCoupon } from 'src/core/Reservation/domain/ReservationCoupon'
import { CouponType } from 'src/core/Reservation/domain/CouponType'

const couponValidationConstants = {
  channelToVerify: 'WEB',
}

export enum CouponValidationType {
  INVALID = 'INVALID',
  NOT_VALID_YET = 'NOT_VALID_YET',
  TIMED_OUT = 'TIMED_OUT',
  NOT_IN_DATE_RANGE = 'NOT_IN_DATE_RANGE',
  VALID = 'VALID',
}

export enum CouponInvalidReasonType {
  NOT_FOUND = 'NOT_FOUND',
  NOT_ACTIVE = 'NOT_ACTIVE',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export type CouponValidationResult = {
  type: CouponValidationType
  validity?: {
    bookingStartDate: Date
    bookingEndDate: Date
    arrivalStartDate: Date
    arrivalEndDate: Date
  }
  invalidReason?: CouponInvalidReasonType
}

interface CouponRule {
  active: boolean
  channels: string[]
  validity: {
    arrivalStartDate: Date
    arrivalEndDate: Date
    bookingStartDate: Date
    bookingEndDate: Date
  }
  discount: {
    /** El valor se encuentra con el signo negativo */
    value: number
    type: CouponDiscountType
  }
}

export interface CouponValidation {
  id: string
  rule: CouponRule
}

export const getValidRule = (rule: CouponRule, checkIn: string) => {
  if (
    rule.active &&
    rule.channels.includes(couponValidationConstants.channelToVerify) &&
    isValidDate(rule.validity, checkIn)
  ) {
    return rule
  }
}

export const checkCouponValidation = (
  couponValidation: CouponValidation | undefined,
  checkIn: string,
  checkOut: string,
): CouponValidationResult => {
  if (isUndefined(couponValidation)) {
    return {
      type: CouponValidationType.INVALID,
      invalidReason: CouponInvalidReasonType.NOT_FOUND,
    }
  }

  const { rule } = couponValidation
  if (!rule) {
    return {
      type: CouponValidationType.INVALID,
      invalidReason: CouponInvalidReasonType.NOT_FOUND,
    }
  }

  if (!rule.channels.includes(couponValidationConstants.channelToVerify)) {
    return {
      type: CouponValidationType.INVALID,
      invalidReason: CouponInvalidReasonType.NOT_APPLICABLE,
    }
  }

  if (
    !rule.active &&
    Time.now().startOf('day') <
      Time.fromDate(rule.validity.bookingStartDate).startOf('day')
  ) {
    return {
      type: CouponValidationType.INVALID,
      invalidReason: CouponInvalidReasonType.NOT_ACTIVE,
    }
  }

  if (
    !rule.active &&
    Time.now().startOf('day') >=
      Time.fromDate(rule.validity.bookingStartDate).startOf('day') &&
    Time.now().startOf('day') <=
      Time.fromDate(rule.validity.bookingEndDate).startOf('day')
  ) {
    return {
      type: CouponValidationType.INVALID,
      invalidReason: CouponInvalidReasonType.NOT_ACTIVE,
    }
  }

  if (
    Time.now().startOf('day') >
    Time.fromDate(rule.validity.bookingEndDate).startOf('day')
  ) {
    return { type: CouponValidationType.TIMED_OUT, validity: rule.validity }
  }

  if (
    Time.fromDate(rule.validity.bookingStartDate).startOf('day') >
    Time.now().startOf('day')
  ) {
    return {
      type: CouponValidationType.NOT_VALID_YET,
      validity: rule.validity,
    }
  }

  const arrivalStart = Time.fromDate(rule.validity.arrivalStartDate).startOf(
    'day',
  )
  const arrivalEnd = Time.fromDate(rule.validity.arrivalEndDate).startOf('day')
  const checkInDate = Time.fromString(checkIn).startOf('day')
  const checkOutDate = Time.fromString(checkOut).startOf('day')

  if (arrivalStart > checkInDate || checkOutDate > arrivalEnd) {
    return {
      type: CouponValidationType.NOT_IN_DATE_RANGE,
      validity: rule.validity,
    }
  }

  return { type: CouponValidationType.VALID }
}

const isValidDate = (
  validity: {
    arrivalStartDate: Date
    arrivalEndDate: Date
    bookingStartDate: Date
    bookingEndDate: Date
  },
  checkIn: string,
) => {
  const { arrivalStartDate, arrivalEndDate, bookingStartDate, bookingEndDate } =
    validity
  const isValidArrivalDate =
    Time.fromDate(arrivalStartDate).startOf('day') <=
      Time.fromString(checkIn).startOf('day') &&
    Time.fromString(checkIn).startOf('day') <=
      Time.fromDate(arrivalEndDate).startOf('day')
  const isValidBookingDate =
    Time.fromDate(bookingStartDate).startOf('day') <=
      Time.now().startOf('day') &&
    Time.now().startOf('day') <=
      Time.fromDate(bookingEndDate).add(1, 'day').startOf('day')

  return isValidArrivalDate && isValidBookingDate
}

export const transformTextToValidCoupon = (value: string) => {
  const couponInvalidChars = /[^a-zA-Z0-9]/g

  const totalRemoveChars = value.match(couponInvalidChars)?.length || 0
  const newValue = value.replace(couponInvalidChars, '')
  const validCoupon = newValue.trim().toUpperCase()

  return {
    validCoupon,
    totalRemoveChars,
  }
}

export const isCouponStaffOrFriends = (coupon: ReservationCoupon) => {
  return (
    coupon.type === CouponType.Promotional &&
    coupon.applies &&
    isStaffOrFriendsCode(coupon.name)
  )
}

export const isStaffOrFriendsCode = (coupon: string | undefined) => {
  return getStaffAndFriendsCoupons().some(staffCoupon => staffCoupon === coupon)
}

export const getStaffAndFriendsCoupons = () => {
  return ['BSTAFF2024', 'BFRIENDS2024']
}
