export interface WindowEvents {
  emitToParent: (event: CustomEvent) => void
  on: (eventName: string, fn: (event: Event) => void) => void
  off: (eventName: string, fn: (event: Event) => void) => void
}

export const windowEvents: WindowEvents = {
  emitToParent: (event: CustomEvent) => {
    window.parent.document.dispatchEvent(event)
  },
  on: (eventName: string, fn: (event: Event) => void) => {
    window.document.addEventListener(eventName, fn)
  },
  off: (eventName: string, fn: (event: Event) => void) => {
    window.document.removeEventListener(eventName, fn)
  },
}
