import {
  ModalDef,
  NiceModalHandler,
  useModal as useNiceModal,
} from '@ebay/nice-modal-react'
import NiceModal from '@ebay/nice-modal-react'
import {
  ComponentProps,
  ComponentType,
  FC,
  JSXElementConstructor,
  useEffect,
} from 'react'
import { useMedia } from 'src/ui/hooks/useMedia'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

interface NiceModalHocProps {
  id: string
  defaultVisible?: boolean
  keepMounted?: boolean
}

interface NiceModalCustomHandler<Props = Record<string, unknown>>
  extends NiceModalHandler {
  visibleModal: boolean
  showModal: (args: Props) => Promise<unknown>
  hideModal: () => void
  removeModal: () => void
}

type NiceModalArgs<T> = T extends
  | keyof JSX.IntrinsicElements
  | JSXElementConstructor<any>
  ? Omit<ComponentProps<T>, 'id'>
  : Record<string, unknown>

function useModal(): NiceModalCustomHandler
function useModal(
  modal: string,
  args?: Record<string, unknown>,
  mediaConfig?: {
    mobile: boolean
    tablet: boolean
    laptop: boolean
    desktop: boolean
  },
): NiceModalCustomHandler
function useModal<
  T extends FC<any>,
  ComponentProps extends NiceModalArgs<T>,
  // eslint-disable-next-line @typescript-eslint/ban-types
  PreparedProps extends Partial<ComponentProps> = {} | ComponentProps,
  RemainingProps = Omit<ComponentProps, keyof PreparedProps> &
    Partial<ComponentProps>,
  ResolveType = unknown,
>(
  modal: T,
  args?: PreparedProps,
  mediaConfig?: {
    mobile: boolean
    tablet: boolean
    laptop: boolean
    desktop: boolean
  },
): Omit<NiceModalCustomHandler, 'showModal'> & {
  showModal: Partial<RemainingProps> extends RemainingProps
    ? (args?: RemainingProps) => Promise<ResolveType>
    : (args: RemainingProps) => Promise<ResolveType>
}

function useModal(modalComponent?: any, props?: any, mediaConfig?: any): any {
  const { show, hide, remove, visible } = useNiceModal(modalComponent)
  const { media } = useMedia()

  useEffect(() => {
    if (isUndefined(mediaConfig)) {
      return
    }

    if (
      (media === 'mobile' && !mediaConfig?.mobile) ||
      (media === 'tablet' && !mediaConfig?.tablet) ||
      (media === 'laptop' && !mediaConfig?.laptop) ||
      (media === 'desktop' && !mediaConfig?.desktop)
    ) {
      hide()
    }
  }, [mediaConfig, hide, media])

  useEffect(() => {
    return () => {
      hide()
    }
  }, [])

  return {
    showModal: show,
    hideModal: hide,
    visibleModal: visible,
    removeModal: remove,
  }
}

export { useModal }

// eslint-disable-next-line @typescript-eslint/ban-types
export const createModal = <P extends {}>(
  Comp: ComponentType<P>,
): FC<P & NiceModalHocProps> => {
  return NiceModal.create(Comp)
}

export const hideModal = (id: string) => {
  return NiceModal.hide(id)
}

export const showModal = (id: string, props: Record<string, unknown>) => {
  return NiceModal.show(id, props)
}

export const ModalRegister = ModalDef
