import { routes } from '../navigation/routes'
import { useApplicationRouter } from './useApplicationRouter'

export const usePageNotFound = () => {
  const { route } = useApplicationRouter()
  const isPageNotFound = route === routes.notFound

  return {
    isPageNotFound,
  }
}
