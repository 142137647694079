import { FC } from 'react'

interface Props {
  color: string
}

export const CheckedCheckboxIcon: FC<Props> = ({ color }) => (
  <>
    <path
      d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
      fill={color}
    />
    <path
      d="M16.5 8.5L11.1612 15.6184C11.0395 15.7808 10.8533 15.8823 10.6509 15.8967C10.4485 15.9112 10.2497 15.837 10.1063 15.6935L7.5 13.0872"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default CheckedCheckboxIcon
