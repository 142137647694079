import { useModal } from 'src/ui/hooks/useModal'
import { ExpiredCartModal } from 'src/ui/views/_components/ExpiredCartModal'

export const useHandleExpiredCartError = () => {
  const { showModal: showExpiredCartModal } = useModal(ExpiredCartModal)

  const handleExpiredCartError = () => {
    showExpiredCartModal()
  }

  return {
    handleExpiredCartError,
  }
}
