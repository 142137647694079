import { useModal } from 'src/ui/hooks/useModal'
import { container } from 'src/core/Shared/_di'
import { CustomEvent } from 'src/core/Shared/infrastructure/eventsManager'
import { NoBookingErrorModal } from 'src/ui/errors/NoBookingError/NoBookingErrorModal'

export const useHandleNoBookingError = () => {
  const {
    showModal: showNoBookingErrorModal,
    hideModal: hideNoBookingErrorModal,
  } = useModal(NoBookingErrorModal)

  const deleteCart = () => {
    container.resolve('deleteCart')()
  }

  const handleRepeatSearch = () => {
    hideNoBookingErrorModal()
    deleteCart()
    container
      .resolve('eventsManager')
      .emit(CustomEvent.GET_AVAILABILITY_FRESH_DATA)
  }

  const onClose = () => {
    deleteCart()
  }

  const handleNoBookingError = () => {
    showNoBookingErrorModal({
      onRetrySearch: handleRepeatSearch,
      onClose: onClose,
    })
  }

  return {
    handleNoBookingError,
  }
}
