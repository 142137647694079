import { AnalyticsActionsSharedClickRegister } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'

export const clickRegister: AnalyticsActionsSharedClickRegister = () => {
  const linkParams = {
    ga4_event_name: 'click link',
    event: 'click link',
    event_category: 'Booking Process',
    event_action: 'Room Selection',
    event_label: 'Sign Up',
  }
  container
    .resolve('logger')
    .info('Analytics/links/actions/shared/clickRegister', {
      linkParams,
      utag_data: window.utag_data,
    })
  window.utag.link(linkParams)
}
