import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import { useQueryService } from 'src/ui/hooks/useQuery'
import { container } from 'src/core/Shared/_di'
import { Currencies } from 'src/core/User/domain/Currency.model'

import {
  isEmpty,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

const defaultState = {
  getCurrencySymbol: () => '',
  isCurrencyValid: () => true,
  currencies: {
    USD: {
      symbol: 'USD',
      name: 'Dólar estadounidense',
      exponent: 2,
    },
  },
}

export type IsCurrencyValid = (code: string) => boolean

export const CurrencyContext = createContext<{
  getCurrencySymbol: (code: string) => string
  isCurrencyValid: IsCurrencyValid
  currencies: Currencies
}>(defaultState)

interface Props {
  setIsLoading: (isLoading: boolean) => void
  children: ReactNode
}

export const CurrencyProvider: FC<Props> = ({ setIsLoading, children }) => {
  const { data: currencies } = useQueryService(
    'get-currencies',
    [],
    container.resolve('getCurrencies'),
  )

  useEffect(() => {
    if (isUndefined(currencies)) return

    setIsLoading(false)
  }, [currencies])

  const getCurrencySymbol = (code: string) => {
    if (
      isUndefined(currencies) ||
      isEmpty(currencies) ||
      isUndefined(currencies[code])
    ) {
      return code
    }

    return currencies[code].symbol
  }

  const isCurrencyValid = useCallback(
    (code: string) => {
      if (isUndefined(currencies)) {
        return false
      }

      return Object.keys(currencies).some(currencyCode => code === currencyCode)
    },
    [currencies],
  )

  const availableCurrencies = currencies ?? defaultState.currencies

  return (
    <CurrencyContext.Provider
      value={{
        getCurrencySymbol,
        isCurrencyValid,
        currencies: availableCurrencies,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  )
}

export const useCurrency = () => useContext(CurrencyContext)
