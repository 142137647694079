import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import type { WithInjectedParams } from 'src/core/Shared/_di/types'
import type { StorageClient } from 'src/core/Shared/infrastructure/sessionStorageClient'
import type { Cryptography } from 'src/core/Shared/infrastructure/cryptography'
import type { ReservationGuest } from 'src/core/Reservation/domain/Reservation.model'
import {
  ItineraryNumberError,
  ItineraryNumberErrorType,
} from 'src/core/Reservation/domain/ItineraryNumberError'
import type { Sentry } from 'src/core/Shared/infrastructure/errors/sentry'

export type ReservationFields = {
  reservationId: string
  itineraryNumber: string
  isPaid: boolean
}

interface ReservationManagerDependencies {
  sessionStorageClient: StorageClient
  cryptography: Cryptography
  sentry: Sentry
}

export interface ReservationStorageRepository {
  setReservationFields: (reservationId: string, itineraryNumber: string) => void
  getUnsafeReservationFields: () => ReservationFields | undefined
  getReservationFields: (itineraryNumber: string) => ReservationFields
  setReservationAlreadyPaid: (itineraryNumber: string) => void
  removeMaxVisitedStep: () => void
  setGuestData: (guestData: ReservationGuest) => void
  getGuestData: () => ReservationGuest | undefined
}

export const reservationStorageRepository: WithInjectedParams<
  ReservationStorageRepository,
  ReservationManagerDependencies
> = ({ sessionStorageClient, cryptography, sentry }) => ({
  setReservationFields: (reservationId, itineraryNumber) => {
    const encodedFields = {
      ...encodeReservationFields(cryptography, reservationId, itineraryNumber),
      isPaid: false,
    }
    const itineraryNumberRecord =
      sessionStorageClient.get<string[]>('itineraryNumberRecord') ?? []

    sessionStorageClient.set('requiredParamsToGetReservation', encodedFields)
    sessionStorageClient.set('itineraryNumberRecord', [
      ...itineraryNumberRecord,
      itineraryNumber,
    ])
  },
  getUnsafeReservationFields: () => {
    const reservationFields = sessionStorageClient.get<
      ReservationFields | undefined
    >('requiredParamsToGetReservation')

    if (isUndefined(reservationFields)) {
      return
    }

    const reservationId = cryptography.decodeBase64(
      reservationFields.reservationId,
    )
    const itineraryNumber = cryptography.decodeBase64(
      reservationFields.itineraryNumber,
    )

    return {
      reservationId,
      itineraryNumber,
      isPaid: reservationFields.isPaid,
    }
  },
  getReservationFields: currentItineraryNumber => {
    const reservationFields = sessionStorageClient.get<
      ReservationFields | undefined
    >('requiredParamsToGetReservation')

    if (
      isUndefined(reservationFields) &&
      isItineraryNumberPartOfTheRecord(
        sessionStorageClient,
        currentItineraryNumber,
      )
    ) {
      throw new ItineraryNumberError(
        'Old itinerary number',
        ItineraryNumberErrorType.Old,
      )
    } else if (isUndefined(reservationFields)) {
      sentry.addContextInfo(
        JSON.parse(sessionStorageClient.getAll() ?? '{}'),
        'session-storage',
      )

      throw new ItineraryNumberError(
        `Itinerary number ${currentItineraryNumber} not found in reservation fields`,
        ItineraryNumberErrorType.NotFound,
      )
    }

    const reservationId = cryptography.decodeBase64(
      reservationFields.reservationId,
    )
    const itineraryNumber = cryptography.decodeBase64(
      reservationFields.itineraryNumber,
    )

    if (itineraryNumber !== currentItineraryNumber) {
      if (
        isItineraryNumberPartOfTheRecord(
          sessionStorageClient,
          currentItineraryNumber,
        )
      ) {
        throw new ItineraryNumberError(
          'Old itinerary number',
          ItineraryNumberErrorType.Old,
        )
      }

      throw new ItineraryNumberError(
        `Current itinerary number: ${currentItineraryNumber} does not match with saved itinerary number: ${itineraryNumber}`,
        ItineraryNumberErrorType.DoesNotMatch,
      )
    }

    return {
      reservationId,
      itineraryNumber,
      isPaid: reservationFields.isPaid,
    }
  },
  setReservationAlreadyPaid: itineraryNumber => {
    const repository = reservationStorageRepository({
      sessionStorageClient,
      cryptography,
      sentry,
    })

    const reservationFields = repository.getReservationFields(itineraryNumber)
    if (isUndefined(reservationFields)) {
      return
    }

    const encodedFields = {
      ...encodeReservationFields(
        cryptography,
        reservationFields.reservationId,
        reservationFields.itineraryNumber,
      ),
      isPaid: true,
    }

    sessionStorageClient.set('requiredParamsToGetReservation', encodedFields)
  },
  removeMaxVisitedStep: () => sessionStorageClient.remove('maxVisitedStep'),
  setGuestData: (guestData: ReservationGuest) => {
    sessionStorageClient.set('guestData', guestData)
  },
  getGuestData: () => {
    return sessionStorageClient.get<ReservationGuest | undefined>('guestData')
  },
})

const isItineraryNumberPartOfTheRecord = (
  sessionStorageClient: StorageClient,
  itineraryNumber: string,
) => {
  const itineraryNumberRecord =
    sessionStorageClient.get<string[]>('itineraryNumberRecord') ?? []

  return itineraryNumberRecord.includes(itineraryNumber)
}

const encodeReservationFields = (
  cryptography: Cryptography,
  reservationId: string,
  itineraryNumber: string,
) => {
  return {
    reservationId: cryptography.encodeBase64(reservationId),
    itineraryNumber: cryptography.encodeBase64(itineraryNumber),
  }
}
