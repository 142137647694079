import { WithInjectedParams } from 'src/core/Shared/_di/types'
import stores from './stores.json'
import { StoreCode } from './StoreCode.model'
import { StoreConfig } from '../domain/StoreConfig'

interface StoreRepository {
  get: (store: StoreCode) => StoreConfig
}

export const apiStoreRepository: WithInjectedParams<
  StoreRepository,
  void
> = () => ({
  get: (store: StoreCode) => {
    return stores[store]
  },
})
