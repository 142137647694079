import React, { FC, useEffect, useState } from 'react'
import { ImageLoader } from 'src/ui/utils/images'
import { useEscapeOnClose } from 'src/ui/hooks/useEscapeOnClose'
import { RoomImageSlide } from 'src/core/Availability/domain/Availability.model'
import { CarouselFullScreen } from 'src/ui/components/molecules/CarouselFullScreen'
import { Swiper as SwiperClass } from 'swiper/types'
import { Gallery } from 'src/ui/components/molecules/Gallery'
import styles from 'src/ui/components/molecules/Carousel/RoomImagesFullScreen/RoomImagesFullScreen.module.scss'
import { classNames } from 'src/ui/utils/classnames'
import { useLockBodyScroll } from 'src/ui/hooks/useLockBodyScroll'

interface Props {
  imageSlides: RoomImageSlide[]
  loader: ImageLoader
  swiperRef?: SwiperClass | undefined
  onClose: () => void
  activeIndex: number | undefined
}

const LIMIT_TO_SHOW_CAROUSEL = 5

export const RoomImagesFullScreen: FC<Props> = ({
  imageSlides,
  loader,
  onClose,
  activeIndex,
}) => {
  // Utilizamos este estado para controlar si se muestra el carrusel en la galería con CSS (desmontarlo y montarlo daba problemas)
  const [isCarouselInGalleryOpened, setIsCarouselInGalleryOpened] =
    useState(false)
  // Al ser un carrusel infinito, el índice no empieza en 0 si no en 1
  const [carouselInGalleryIndexToShow, setCarouselInGalleryIndexToShow] =
    useState(1)

  const { lockBodyScroll, releaseBodyScroll } = useLockBodyScroll()
  useEffect(() => {
    lockBodyScroll()

    return () => {
      releaseBodyScroll()
    }
  }, [lockBodyScroll, releaseBodyScroll])

  const handleCloseCarousel = () => {
    if (!isCarouselInGalleryOpened) {
      onClose()
    }

    setIsCarouselInGalleryOpened(false)
  }

  useEscapeOnClose({ onClose: handleCloseCarousel })

  if (imageSlides.length < LIMIT_TO_SHOW_CAROUSEL) {
    return (
      <CarouselFullScreen
        imageSlides={imageSlides}
        alt="alt"
        loader={loader}
        initialSlideIndex={activeIndex ?? 1}
        onClose={handleCloseCarousel}
      />
    )
  }

  return (
    <>
      <Gallery
        imageSlides={imageSlides}
        loader={loader}
        isCarouselInGalleryOpened={isCarouselInGalleryOpened}
        onClose={onClose}
        onOpenCarousel={(index: number) => {
          setIsCarouselInGalleryOpened(true)
          setCarouselInGalleryIndexToShow(index)
        }}
      />
      <CarouselFullScreen
        className={classNames(
          isCarouselInGalleryOpened ? styles.show : styles.visuallyHidden,
        )}
        imageSlides={imageSlides}
        alt="alt"
        loader={loader}
        initialSlideIndex={carouselInGalleryIndexToShow}
        onClose={handleCloseCarousel}
      />
    </>
  )
}
