import { classNames } from 'src/ui/utils/classnames'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { FC } from 'react'

import styles from './FullScreenNavigationButton.module.scss'
import CloseIcon from 'src/ui/components/atoms/Icon/_icons/interaction/CloseIcon'
import { Text } from 'src/ui/components/atoms/Text'
import { useTrans } from 'src/ui/hooks/useTrans'
import { useEscapeOnClose } from 'src/ui/hooks/useEscapeOnClose'

interface Props {
  onClose: () => void
}

export const FullScreenCloseButton: FC<Props> = ({ onClose }) => {
  const { trans } = useTrans(['new-reservation'])
  useEscapeOnClose({ onClose })

  return (
    <div onClick={onClose} className={styles.closeButtonWrapper}>
      <Text className={styles.closeButtonLabel} fontStyle="s-500" color="light">
        {trans('available-rooms_carousel_close')}
      </Text>
      <div className={classNames(styles.closeButton)}>
        <Icon size="s" icon={CloseIcon} />
      </div>
    </div>
  )
}
