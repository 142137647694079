import { Button, Modal } from 'src/ui/components'
import { Text } from 'src/ui/components/atoms/Text'
import { createModal } from 'src/ui/hooks/useModal'
import { useTrans } from 'src/ui/hooks/useTrans'

interface Props {
  onRetrySearch: () => void
  onClose: () => void
}

export const NoBookingErrorModal = createModal(
  ({ onRetrySearch, onClose }: Props) => {
    const { trans } = useTrans(['new-reservation'])

    return (
      <Modal
        size="m"
        type="passive"
        onClose={onClose}
        data-quantum-modal="NoBookingErrorModal"
      >
        <Text
          as="h2"
          fontStyle={{ mobile: 'xl-700', laptop: '2xl-700' }}
          color="dark"
          centered
          className="mb-l"
        >
          {trans('no-booking-error-modal_title')}
        </Text>
        <Text fontStyle="m-300" className="mb-xl" as="p" centered>
          {trans('no-booking-error-modal_message')}
        </Text>
        <Button variant="primary" type="button" onClick={onRetrySearch}>
          {trans('no-booking-error-modal_repeat-search')}
        </Button>
      </Modal>
    )
  },
)
