import { NextRouter } from 'next/router'
import { ParsedUrlQuery } from 'node:querystring'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { routes } from 'src/ui/navigation/routes'
import { QueryParamsErrors } from './useApplicationRouter.model'
import { QueryParamKey } from './useApplicationRouter.model'
import { container } from 'src/core/Shared/_di'
import { QueryUtils } from './buildQueryUtils'

export interface ApplicationNavigator {
  to: (pathname: string, query: ParsedUrlQuery) => Promise<void>
  toSameWithReload: (query: ParsedUrlQuery) => Promise<boolean>
  toSameWithoutReload: (query: ParsedUrlQuery) => Promise<boolean>
  toSameWithReplace: (query: ParsedUrlQuery) => Promise<boolean>
  toAvailabilityFromStepper: () => Promise<void>
  toAvailabilityWhenDeletedCart: () => Promise<void>
  toAvailabilityWhenEdited: (query: ParsedUrlQuery) => Promise<void>
  toAvailabilityOnStaffAndFriendsCouponError: () => Promise<void>
  toAvailabilityOnNotFoundError: (query: ParsedUrlQuery) => Promise<void>
  toAvailabilityOnCannotEditCouponError: (
    query: ParsedUrlQuery,
  ) => Promise<void>
  toAvailabilityWithDefaultsForErrors: (
    errors: Pick<
      QueryParamsErrors,
      | 'hasGuestError'
      | 'hasDatesError'
      | 'hasMarketPriceError'
      | 'hasCurrencyError'
    >,
  ) => Promise<void>
  toExtrasFromCart: (
    itineraryNumber: string | undefined,
    query: ParsedUrlQuery,
  ) => Promise<void>
  toExtrasFromAvailability: (
    promotionalCouponMustBeEliminated: boolean,
    itineraryNumber: string | undefined,
  ) => Promise<void>
  toPersonalDataFromStepper: (
    itineraryNumber: string | undefined,
  ) => Promise<void>
  toPersonalDataFromExtrasAutomatically: (
    itineraryNumber: string | undefined,
  ) => Promise<void>
  toPersonalDataFromExtrasWithRedirectView: (
    itineraryNumber: string | undefined,
  ) => Promise<void>
  toPersonalDataFromExtras: (
    itineraryNumber: string | undefined,
  ) => Promise<void>
  toChoosePaymentFromIngenicoCallback: (query: ParsedUrlQuery) => Promise<void>
  toChoosePaymentFromStepper: () => Promise<void>
  toChoosePaymentFromExtrasAutomatically: (
    itineraryNumber: string | undefined,
  ) => Promise<void>
  toChoosePaymentFromExtrasWithRedirectView: (
    itineraryNumber: string | undefined,
  ) => Promise<void>
  toChoosePaymentFromExtrasIfLogged: (
    itineraryNumber: string | undefined,
  ) => Promise<void>
  toChoosePaymentFromPersonalData: (
    itineraryNumber: string | undefined,
  ) => Promise<void>
  toChoosePaymentWithError: (
    error: string,
    itineraryNumber: string | undefined,
  ) => Promise<void>
  toChoosePaymentWithoutError: () => Promise<void>
  toBookingConfirmationFromChoosePayment: (
    itineraryNumber: string | undefined,
  ) => Promise<void>
  toBookingConfirmationOnPaymentAccepted: (
    query: ParsedUrlQuery,
  ) => Promise<void>
  toBookingConfirmationFromIngenicoCallback: (
    query: ParsedUrlQuery,
  ) => Promise<void>
  navigateWhenHavingUser: (itineraryNumber: string) => Promise<void>
}

export const buildNavigator = (
  nextRouter: NextRouter,
  queryUtils: QueryUtils,
): ApplicationNavigator => {
  const { push, pathname, query: routerQuery, replace } = nextRouter

  return {
    to: async (pathname, query) => {
      await push({ pathname, query }, undefined, {
        shallow: true,
      })
    },
    toSameWithReload: async query => {
      const url = {
        pathname,
        query,
      }
      return await push(url, undefined, {
        shallow: false,
      })
    },
    toSameWithoutReload: async query => {
      const url = {
        pathname,
        query,
      }
      return await push(url, undefined, {
        shallow: true,
      })
    },
    toSameWithReplace: async query => {
      const url = {
        pathname,
        query,
      }
      return await replace(url, undefined, {
        shallow: true,
      })
    },
    toAvailabilityFromStepper: async () => {
      await push(
        { pathname: routes.availability, query: routerQuery },
        undefined,
        {
          shallow: false,
        },
      )
    },
    toAvailabilityWhenDeletedCart: async () => {
      const newQuery = removeParamsFromQuery(routerQuery, 'itineraryNumber')
      await push(
        { pathname: routes.availability, query: newQuery },
        undefined,
        {
          shallow: false,
        },
      )
    },
    toAvailabilityWhenEdited: async query => {
      await push({ pathname: routes.availability, query }, undefined, {
        shallow: false,
      })
    },
    toAvailabilityOnStaffAndFriendsCouponError: async () => {
      await push(
        { pathname: routes.availability, query: routerQuery },
        undefined,
        {
          shallow: false,
        },
      )
    },
    toAvailabilityOnNotFoundError: async query => {
      await push({ pathname: routes.availability, query }, undefined, {
        shallow: false,
      })
    },
    toAvailabilityOnCannotEditCouponError: async query => {
      await push({ pathname: routes.availability, query }, undefined, {
        shallow: false,
      })
    },
    toAvailabilityWithDefaultsForErrors: async ({
      hasGuestError,
      hasDatesError,
      hasMarketPriceError,
      hasCurrencyError,
    }) => {
      let query = { ...routerQuery }

      if (hasGuestError) {
        query = {
          ...query,
          rooms: undefined,
          adult: '2',
          child: undefined,
          childages: undefined,
        }
      }

      if (hasDatesError) {
        const { arrive, depart } = datesManager.getDefaultDates()
        query = {
          ...query,
          arrive,
          depart,
        }
      }

      if (hasMarketPriceError) {
        query = {
          ...query,
          marketprice: 'ROW',
        }
      }

      if (hasCurrencyError) {
        query = {
          ...query,
          currency: queryUtils.getCurrencyParam(),
        }
      }

      const withoutEmptyParams = (
        query: Record<string, string | string[] | undefined>,
      ) => JSON.parse(JSON.stringify(query))

      await push(
        {
          pathname: routes.availability,
          query: withoutEmptyParams(query),
        },
        undefined,
        {
          shallow: true,
        },
      )
    },
    toExtrasFromCart: async (itineraryNumber, query) => {
      await push({
        pathname: routes.extras,
        query: {
          ...query,
          itineraryNumber: isDefined(itineraryNumber)
            ? itineraryNumber
            : routerQuery.itineraryNumber,
        },
      })
    },
    toExtrasFromAvailability: async (
      promotionalCouponMustBeEliminated,
      itineraryNumber,
    ) => {
      const paramsToRemove: QueryParamKey[] = promotionalCouponMustBeEliminated
        ? ['mealplan', 'rate', 'coupon', 'roomStay']
        : ['mealplan', 'rate', 'roomStay']

      const newQuery = removeParamsFromQuery(routerQuery, paramsToRemove)

      await push({
        pathname: routes.extras,
        query: { ...newQuery, itineraryNumber },
      })
    },
    toPersonalDataFromStepper: async itineraryNumber => {
      container.resolve('stepperManager').setPersonalData()
      const url = {
        pathname: routes.personalData,
        query: { ...routerQuery, itineraryNumber },
      }
      await push(url)
    },
    toPersonalDataFromExtrasAutomatically: async itineraryNumber => {
      container.resolve('stepperManager').setPersonalData()
      const url = {
        pathname: routes.personalData,
        query: { ...routerQuery, itineraryNumber },
      }
      await replace(url)
    },
    toPersonalDataFromExtrasWithRedirectView: async itineraryNumber => {
      container.resolve('stepperManager').setPersonalData()
      const newQuery = removeParamsFromQuery(routerQuery, 'redirectView')
      const url = {
        pathname: routes.personalData,
        query: { ...newQuery, itineraryNumber },
      }
      await push(url)
    },
    toPersonalDataFromExtras: async itineraryNumber => {
      container.resolve('stepperManager').setPersonalData()
      const url = {
        pathname: routes.personalData,
        query: { ...routerQuery, itineraryNumber },
      }
      await push(url)
    },
    toChoosePaymentFromIngenicoCallback: async query => {
      container.resolve('stepperManager').setChoosePayment()
      const url = {
        pathname: routes.choosePayment,
        query,
      }
      await push(url)
    },
    toChoosePaymentFromStepper: async () => {
      container.resolve('stepperManager').setChoosePayment()
      const url = {
        pathname: routes.choosePayment,
        query: routerQuery,
      }
      await push(url)
    },
    toChoosePaymentFromExtrasAutomatically: async itineraryNumber => {
      container.resolve('stepperManager').setChoosePayment()
      const url = {
        pathname: routes.choosePayment,
        query: {
          ...routerQuery,
          itineraryNumber: isDefined(itineraryNumber)
            ? itineraryNumber
            : routerQuery.itineraryNumber,
        },
      }
      await replace(url)
    },
    toChoosePaymentFromExtrasWithRedirectView: async itineraryNumber => {
      const query = removeParamsFromQuery(routerQuery, 'redirectView')
      container.resolve('stepperManager').setChoosePayment()
      const url = {
        pathname: routes.choosePayment,
        query: {
          ...query,
          itineraryNumber: isDefined(itineraryNumber)
            ? itineraryNumber
            : query.itineraryNumber,
        },
      }
      await push(url)
    },
    toChoosePaymentFromExtrasIfLogged: async itineraryNumber => {
      container.resolve('stepperManager').setChoosePayment()
      const url = {
        pathname: routes.choosePayment,
        query: {
          ...routerQuery,
          itineraryNumber: isDefined(itineraryNumber)
            ? itineraryNumber
            : routerQuery.itineraryNumber,
        },
      }
      await push(url)
    },
    toChoosePaymentFromPersonalData: async itineraryNumber => {
      container.resolve('stepperManager').setChoosePayment()
      const url = {
        pathname: routes.choosePayment,
        query: {
          ...routerQuery,
          itineraryNumber: isDefined(itineraryNumber)
            ? itineraryNumber
            : routerQuery.itineraryNumber,
        },
      }
      await push(url)
    },
    toChoosePaymentWithError: async (error, itineraryNumber) => {
      container.resolve('stepperManager').setChoosePayment()
      const url = {
        pathname: routes.choosePayment,
        query: {
          ...routerQuery,
          itineraryNumber: isDefined(itineraryNumber)
            ? itineraryNumber
            : routerQuery.itineraryNumber,
          paymentStatus: error,
        },
      }
      await push(url)
    },
    toChoosePaymentWithoutError: async () => {
      container.resolve('stepperManager').setChoosePayment()
      const newQuery = removeParamsFromQuery(routerQuery, 'paymentStatus')
      const url = {
        pathname: routes.choosePayment,
        query: newQuery,
      }
      await push(url)
    },
    toBookingConfirmationFromChoosePayment: async itineraryNumber => {
      await push({
        pathname: routes.bookingConfirmation,
        query: {
          ...routerQuery,
          itineraryNumber: isDefined(itineraryNumber)
            ? itineraryNumber
            : routerQuery.itineraryNumber,
        },
      })
    },
    toBookingConfirmationOnPaymentAccepted: async query => {
      await push({
        pathname: routes.bookingConfirmation,
        query: {
          ...query,
          itineraryNumber: query.itineraryNumber,
        },
      })
    },
    toBookingConfirmationFromIngenicoCallback: async query => {
      await push({
        pathname: routes.bookingConfirmation,
        query: {
          ...query,
          itineraryNumber: query.itineraryNumber,
        },
      })
    },
    navigateWhenHavingUser: async itineraryNumber => {
      if (pathname === routes.personalData) {
        container.resolve('stepperManager').setChoosePayment()
        const url = {
          pathname: routes.choosePayment,
          query: {
            ...routerQuery,
            itineraryNumber,
          },
        }
        await push(url)

        return
      }

      await push(
        {
          pathname: pathname,
          query: { ...routerQuery, itineraryNumber },
        },
        undefined,
        {
          shallow: true,
        },
      )
    },
  }
}

const removeParamsFromQuery = (
  query: ParsedUrlQuery,
  paramToRemove: QueryParamKey | QueryParamKey[],
) => {
  const queryWithoutParam = { ...query }

  if (Array.isArray(paramToRemove)) {
    paramToRemove.forEach(param => {
      delete queryWithoutParam[param]
    })
  } else {
    delete queryWithoutParam[paramToRemove]
  }

  return queryWithoutParam
}
