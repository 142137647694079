import { AnalyticsActionsSharedChangeDates } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'
import { Time } from 'src/core/Shared/infrastructure/Time'

export const changeDates: AnalyticsActionsSharedChangeDates = dates => {
  const linkParams = {
    ga4_event_name: 'cta',
    event: 'cta',
    event_category: 'Booking Process',
    event_action: 'Room Selection',
    event_label: 'Check in Date Change',
    booking_check_in_date: Time.fromDate(dates.checkIn).format('YYYYMMDD'),
    booking_check_out_date: Time.fromDate(dates.checkOut).format('YYYYMMDD'),
  }
  container
    .resolve('logger')
    .info('Analytics/links/actions/shared/changeDates', {
      linkParams,
      utag_data: window.utag_data,
    })
  window.utag.link(linkParams)
}
