import NextImage, { ImageProps } from 'next/image'
import { FC, useState } from 'react'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

interface Props extends ImageProps {
  defaultSrc?: string
}

export const Image: FC<Props> = props => {
  const [hasErrorLoadingImage, setHasErrorLoadingImage] = useState(false)

  const { src, defaultSrc, ...restProps } = props
  const imageUrl =
    hasErrorLoadingImage && isDefined(defaultSrc) ? defaultSrc : src

  return (
    <NextImage
      src={imageUrl}
      onError={() => {
        setHasErrorLoadingImage(true)
      }}
      {...restProps}
    />
  )
}
