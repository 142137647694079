import { ParsedUrlQuery } from 'node:querystring'
import {
  AvailabilityMealplanFilter,
  AvailabilityMealplanId,
  AvailabilityRoomTypeFilter,
} from 'src/core/Availability/domain/Availability.model'
import {
  PaymentIngenicoData,
  PaymentValidation,
} from 'src/core/Payment/domain/Payment.model'
import {
  isDefined,
  isString,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { MarketPriceCode } from 'src/core/User/infrastructure/MarketPriceCode'
import { QueryParamsErrors } from './useApplicationRouter.model'
import { QueryParams } from './useApplicationRouter.model'
import { QueryParamKey } from './useApplicationRouter.model'
import { NextRouter } from 'next/router'
import { IsCurrencyValid } from 'src/ui/contexts/CurrencyContext'
import { CouponsFormValues } from 'src/ui/hooks/useCouponsForm'
import { CurrencyISOCode } from 'src/core/Shared/domain/Price.model'
import { DEFAULT_LANGUAGE } from 'src/core/Shared/infrastructure/locales/i18nUtils'
import { CheckInCheckOut } from 'src/core/Shared/domain/CheckInCheckOut'
import { getAvailabilityCriteriaParams } from 'src/ui/hooks/useApplicationRouter/getAvailabilityCriteriaParams'
import { queryBuilder } from './queryBuilder'
import { container } from 'src/core/Shared/_di'
import { StoreCode } from 'src/core/User/infrastructure/StoreCode.model'
import { RequiredParams } from './useApplicationRouter.model'

export const DefaultParamValues = {
  store: 'en-ww',
  country: 'NA',
  currency: 'USD',
  locale: DEFAULT_LANGUAGE,
}

export interface QueryUtils {
  getAvailabilityCriteriaParams: (isContextCurrencyValid: IsCurrencyValid) => {
    errors?: QueryParamsErrors
    data?: RequiredParams
  }
  getMealplanParam: () => AvailabilityMealplanId | undefined
  getRoomTypeFilterParam: () => AvailabilityRoomTypeFilter | undefined
  getPaymentValidationParams: () => PaymentValidation | undefined
  getRawParam: (
    param: QueryParamKey,
    defaultValue?: string,
  ) => string | undefined
  getRoomsNumberParam: () => number
  getMarketPriceCodeParam: () => MarketPriceCode
  getCouponParam: () => string | undefined
  getCurrencyParam: () => CurrencyISOCode
  getIngenicoData: () => PaymentIngenicoData | undefined
  getMarketCampaign: () => string | undefined
  getRateParam: () => string | undefined
  setCurrencyParam: (currency: string) => void
  setHotelParam: (hotel: string) => void
  setItineraryNumber: (itineraryNumber: string) => void
  removeParam: (param: QueryParamKey) => Promise<void>
  removeParams: (params: QueryParamKey[]) => Promise<void>
  buildMealplanFiltersQuery: (
    clickedFilter: AvailabilityMealplanFilter,
  ) => ParsedUrlQuery
  buildRoomTypeFiltersQuery: (
    newFilter: AvailabilityRoomTypeFilter,
  ) => ParsedUrlQuery
  buildQueryWithoutMealplanFilters: () => ParsedUrlQuery
  buildQueryWithoutRoomTypeFilter: () => ParsedUrlQuery
  buildGuestsQuery: (guests: {
    adults: number[]
    children: number[]
    childrenAges?: Array<Array<number>>
  }) => ParsedUrlQuery
  buildDatesQuery: (dates: CheckInCheckOut) => ParsedUrlQuery
  buildEditAvailabilityCriteriaQuery: (
    guests: {
      adults: number[]
      children: number[]
      childrenAges?: Array<Array<number>>
    },
    dates: CheckInCheckOut,
    coupon?: CouponsFormValues,
  ) => ParsedUrlQuery
  buildCouponQuery: (coupon?: CouponsFormValues) => ParsedUrlQuery
}

export const buildQueryUtils = (
  router: NextRouter,
  getRawParams: (params: QueryParamKey[]) => QueryParams | undefined,
): QueryUtils => ({
  getAvailabilityCriteriaParams: getAvailabilityCriteriaParams(getRawParams),
  getMealplanParam: () => {
    const result = getRawParams(['mealplan'])

    if (isUndefined(result) || Array.isArray(result.mealplan)) return

    return result.mealplan?.toUpperCase() as AvailabilityMealplanId
  },
  getRoomTypeFilterParam: () => {
    const roomCode = getRawParams(['roomcode'])?.roomcode
    if (isUndefined(roomCode)) {
      return
    }

    if (Array.isArray(roomCode)) {
      return roomCode[0] as AvailabilityRoomTypeFilter
    }

    return roomCode as AvailabilityRoomTypeFilter
  },
  getPaymentValidationParams: () => {
    const result = getRawParams([
      'itineraryNumber',
      'paymentStatus',
      'statusCode',
    ])

    if (isUndefined(result)) return undefined

    return mapPaymentValidationFromUrl(result)
  },
  getRawParam: (param, defaultValue) => {
    const queryParams = getRawParams([param])

    if (isUndefined(queryParams) || isUndefined(queryParams[param])) {
      return defaultValue
    }

    return queryParams[param]
  },
  getRoomsNumberParam: () => {
    const result = getRawParams(['rooms'])

    if (isUndefined(result)) return 1

    if (isUndefined(result.rooms)) return 1

    return parseInt(result.rooms as string)
  },
  getMarketPriceCodeParam: () => {
    const queryParams = getRawParams(['marketprice'])

    if (isUndefined(queryParams)) {
      // TODO: gestionar no happy path (que no venga el param)
      return 'USA'
    }

    return String(queryParams.marketprice) as MarketPriceCode
  },
  getCouponParam: () => {
    const queryParams = getRawParams(['coupon'])
    const coupon = queryParams?.coupon as string

    return coupon?.toUpperCase()
  },
  getCurrencyParam: () => {
    const defaultGlobalCurrency = 'USD'
    const queryParams = getRawParams(['currency', 'store'])

    if (isUndefined(queryParams)) {
      return defaultGlobalCurrency
    }

    if (isDefined(queryParams.currency) && isString(queryParams.currency)) {
      const isValidCurrency = container
        .resolve('currencyRepository')
        .isValidCurrency(queryParams.currency)

      if (isValidCurrency) {
        return queryParams.currency as CurrencyISOCode
      }
    }

    const storeConfig = container
      .resolve('storeRepository')
      .get(queryParams.store as StoreCode)

    if (isDefined(storeConfig)) {
      return storeConfig.defaultCurrency as CurrencyISOCode
    } else {
      return defaultGlobalCurrency
    }
  },
  getIngenicoData: () => {
    const queryParams = getRawParams([
      'orderNumber',
      'paymentCurrency',
      'statusCode',
      'paymentMethodCode',
      'token',
      'transactionId',
      'paymentAmount',
      'orderSignature',
      'merchantAccount',
    ])

    if (isUndefined(queryParams)) {
      return undefined
    }

    return queryParams as PaymentIngenicoData
  },
  getMarketCampaign: () => {
    const queryParams = getRawParams(['marketcampaign'])

    if (
      isUndefined(queryParams) ||
      isUndefined(queryParams.marketcampaign) ||
      Array.isArray(queryParams.marketcampaign)
    ) {
      return undefined
    }

    return queryParams.marketcampaign
  },
  getRateParam: () => {
    const queryParams = getRawParams(['rate'])

    if (
      isUndefined(queryParams) ||
      isUndefined(queryParams.rate) ||
      Array.isArray(queryParams.rate)
    ) {
      return
    }

    return queryParams.rate
  },
  setCurrencyParam: async (currency: string) => {
    await router.push(
      {
        pathname: router.pathname,
        query: { ...router.query, currency: currency },
      },
      undefined,
      {
        shallow: true,
      },
    )
  },
  setItineraryNumber: async (itineraryNumber: string) => {
    return await router.push(
      {
        pathname: router.pathname,
        query: { ...router.query, itineraryNumber },
      },
      undefined,
      {
        shallow: true,
      },
    )
  },
  setHotelParam: async (hotel: string) => {
    return await router.push(
      {
        pathname: router.pathname,
        query: { ...router.query, hotel },
      },
      undefined,
      {
        shallow: true,
      },
    )
  },
  removeParam: async param => {
    const params = new URLSearchParams(
      router.query as unknown as URLSearchParams,
    )
    params.delete(param)

    await router.replace(
      { pathname: router.pathname, query: params.toString() },
      undefined,
      {
        shallow: true,
      },
    )
  },
  removeParams: async params => {
    const paramsURL = new URLSearchParams(
      router.query as unknown as URLSearchParams,
    )

    params.map(param => {
      paramsURL.delete(param)
    })

    await router.replace(
      { pathname: router.pathname, query: paramsURL.toString() },
      undefined,
      {
        shallow: true,
      },
    )
  },
  buildMealplanFiltersQuery: queryBuilder(router).buildMealplanFiltersQuery,
  buildRoomTypeFiltersQuery: queryBuilder(router).buildRoomTypeFiltersQuery,
  buildQueryWithoutMealplanFilters:
    queryBuilder(router).buildQueryWithoutMealplanFilters,
  buildQueryWithoutRoomTypeFilter:
    queryBuilder(router).buildQueryWithoutRoomTypeFilter,
  buildGuestsQuery: queryBuilder(router).buildGuestsQuery,
  buildDatesQuery: queryBuilder(router).buildDatesQuery,
  buildEditAvailabilityCriteriaQuery:
    queryBuilder(router).buildEditAvailabilityCriteriaQuery,
  buildCouponQuery: queryBuilder(router).buildCouponQuery,
})

const mapPaymentValidationFromUrl = (
  queryParams: QueryParams,
): PaymentValidation | undefined => {
  const { paymentStatus, itineraryNumber, statusCode } = queryParams

  if (typeof paymentStatus !== 'string') return undefined
  if (typeof itineraryNumber !== 'string') return undefined
  if (typeof statusCode !== 'string') return undefined

  return {
    paymentStatus,
    itineraryNumber,
    statusCode,
  }
}
