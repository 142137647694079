import { AnalyticsRequestReservation } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'
import { buildUtagDataForReservation } from './preReservation'

export const preReservationFromCouponChange: AnalyticsRequestReservation =
  preReservation => {
    const linkParams = {
      ga4_event_name: 'coupon_change',
      event: 'coupon_change',
      event_category: 'Booking Process',
      event_action: 'Change coupon',
      event_label: 'edit coupon',

      availability_link: false,
      ...buildUtagDataForReservation(preReservation),
    }
    container
      .resolve('logger')
      .info(
        'Analytics/links/requests/preReservationFromCouponChange',
        linkParams,
      )
    window.utag.link(linkParams)
  }
