import { WithInjectedParams } from 'src/core/Shared/_di/types'
import { Currencies } from '../domain/Currency.model'
import { CurrencyRepository } from '../domain/Currency.repository'
import currencies from './currencies.json'

export const apiCurrencyRepository: WithInjectedParams<
  CurrencyRepository,
  void
> = () => ({
  getCurrencies: () => {
    return currencies as Currencies
  },
  isValidCurrency: (currency: string) => {
    return Object.keys(currencies).some(key => key === currency)
  },
})
