import qs from 'qs'
import { isEmpty } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export const serializeRequest = (
  params: Record<string, unknown>,
  childrenAges: number[][] | undefined,
) =>
  qs
    .stringify(
      {
        ...params,
        children_ages: childrenAges?.every(isEmpty)
          ? undefined
          : childrenAges?.map(ages => [ages.join('*')]),
      },
      {
        arrayFormat: 'repeat',
        encode: false,
      },
    )
    .replace(/\*/g, ',')
