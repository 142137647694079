import { FC, ReactNode } from 'react'
import { classNames } from 'src/ui/utils/classnames'
import styles from './Tag.module.scss'

interface Props {
  children: ReactNode
  className?: string
}

export const Tag: FC<Props> = ({ children, className, ...props }) => {
  return (
    <div className={classNames(styles.tag, className)} {...props}>
      {children}
    </div>
  )
}
