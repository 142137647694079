import { createContext, FC, ReactNode, useEffect, useState } from 'react'
import { BREAKPOINTS, BreakpointType } from 'src/ui/styles/settings'

const defaultMedia: BreakpointType = 'mobile'

const mediaQueries = {
  mobile: `(max-width: ${BREAKPOINTS.mobile.max}px)`,
  tablet: `(min-width: ${BREAKPOINTS.tablet.min}px) AND (max-width: ${BREAKPOINTS.tablet.max}px)`,
  laptop: `(min-width: ${BREAKPOINTS.laptop.min}px) AND (max-width: ${BREAKPOINTS.laptop.max}px)`,
  desktop: `(min-width: ${BREAKPOINTS.desktop.min}px)`,
}

export const MediaContext = createContext<BreakpointType>(defaultMedia)

interface Props {
  children: ReactNode
}

export const MediaProvider: FC<Props> = ({ children }) => {
  const [media, setMedia] = useState<BreakpointType>(calculateMedia())

  useEffect(() => {
    const desktopMediaQuery = window.matchMedia(mediaQueries.desktop)
    const laptopMediaQuery = window.matchMedia(mediaQueries.laptop)
    const tabletMediaQuery = window.matchMedia(mediaQueries.tablet)
    const mobileMediaQuery = window.matchMedia(mediaQueries.mobile)

    desktopMediaQuery.addEventListener('change', desktopHandler)
    laptopMediaQuery.addEventListener('change', laptopHandler)
    tabletMediaQuery.addEventListener('change', tabletHandler)
    mobileMediaQuery.addEventListener('change', mobileHandler)

    return () => {
      desktopMediaQuery.removeEventListener('change', desktopHandler)
      laptopMediaQuery.removeEventListener('change', laptopHandler)
      tabletMediaQuery.removeEventListener('change', tabletHandler)
      mobileMediaQuery.removeEventListener('change', mobileHandler)
    }
  }, [])

  const desktopHandler = (event: MediaQueryListEvent) => {
    if (event.matches) {
      setMedia('desktop')
    }
  }

  const laptopHandler = (event: MediaQueryListEvent) => {
    if (event.matches) {
      setMedia('laptop')
    }
  }

  const tabletHandler = (event: MediaQueryListEvent) => {
    if (event.matches) {
      setMedia('tablet')
    }
  }

  const mobileHandler = (event: MediaQueryListEvent) => {
    if (event.matches) {
      setMedia('mobile')
    }
  }

  return <MediaContext.Provider value={media}>{children}</MediaContext.Provider>
}

const calculateMedia = (): BreakpointType => {
  if (typeof window === 'undefined') return defaultMedia

  const { matchMedia } = window
  if (matchMedia(mediaQueries.desktop).matches) return 'desktop'
  if (matchMedia(mediaQueries.laptop).matches) return 'laptop'
  if (matchMedia(mediaQueries.tablet).matches) return 'tablet'
  return 'mobile'
}
