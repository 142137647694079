import { RoomStayDTO } from 'src/core/Reservation/infrastructure/Reservation.api.dto'
import { mapPriceDTO } from './mapPriceDTO'
import { Price, PriceOptions } from 'src/core/Shared/domain/Price.model'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export const calculateTotalGuarantee = (
  roomStays: RoomStayDTO[],
  priceOptions: PriceOptions,
): { deposit: Price; pending: Price } => {
  const currency = mapPriceDTO(
    roomStays[0].rate.stay.base,
    priceOptions,
  ).currency

  const { deposit, pending } = roomStays.reduce(
    (prev, stay) => {
      const stayDepositValue = isDefined(stay.rate.guarantee.deposit)
        ? mapPriceDTO(stay.rate.guarantee.deposit, priceOptions).value
        : 0
      const stayPendingValue = isDefined(stay.rate.breakdown.payAtHotel)
        ? mapPriceDTO(stay.rate.breakdown.payAtHotel, priceOptions).value
        : 0
      const { deposit: prevBeforeTaxValue, pending: prevAfterTaxValue } = prev

      return {
        deposit: prevBeforeTaxValue + stayDepositValue,
        pending: prevAfterTaxValue + stayPendingValue,
      }
    },
    {
      deposit: 0,
      pending: 0,
    },
  )

  return {
    deposit: { value: deposit, currency },
    pending: { value: pending, currency },
  }
}
