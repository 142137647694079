import { AnalyticsRequestCurrentPaymentMethod } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'

export const currentPaymentMethod: AnalyticsRequestCurrentPaymentMethod =
  currentPaymentMethod => {
    const linkParams = {
      currentPaymentMethod: currentPaymentMethod,
    }
    container
      .resolve('logger')
      .info('Analytics/links/requests/currentPaymentMethod', {
        linkParams,
        utag_data: window.utag_data,
      })
    window.utag.link(linkParams)
  }
