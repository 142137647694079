import { AnalyticsActionsSharedLogout } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'

export const logout: AnalyticsActionsSharedLogout = () => {
  container.resolve('logger').info('Analytics/links/actions/shared/logout')

  window.utag.link({
    tealium_event: 'cbe_user_logged_out',
    event_action: 'logout',
    event_category: 'mybarcelo',
    event_label: 'cbe_app',
    user_is_logged: '0',
    user_type: 'reader',
  })
}
