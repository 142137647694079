import { TFunction, useTranslation } from 'next-i18next'
import { Namespace } from 'src/core/Shared/infrastructure/locales/types'

export type Translator = TFunction

const getTranslationKeys = () =>
  process.env.NEXT_PUBLIC_SHOW_TRANSLATION_KEYS === 'only_keys'

export const useTrans = (nameSpaces: Namespace[]): { trans: Translator } => {
  const { t } = useTranslation(nameSpaces)

  if (!getTranslationKeys()) {
    return { trans: t }
  }

  const trans = (term: string) => {
    return term
  }

  // @ts-ignore
  return { trans }
}
