import axios, { AxiosInstance } from 'axios'
import { getApiAcceptLanguage } from './locales/i18nUtils'
import { isDefined } from './wrappers/javascriptUtils'
import { BasicApiClient } from 'src/core/Shared/infrastructure/basicApiClient'

export const availabilityApiClientAxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_AVAILABILITY_BASE_URL,
})

availabilityApiClientAxiosInstance.interceptors.request.use(config => {
  if (isDefined(config.headers)) {
    config.headers['Accept-Language'] = getApiAcceptLanguage()
  }
  return config
})

export class AvailabilityApiClient extends BasicApiClient {
  constructor({
    availabilityApiClientAxiosInstance,
  }: {
    availabilityApiClientAxiosInstance: AxiosInstance
  }) {
    super(availabilityApiClientAxiosInstance)
  }
}
