import useWindowDimensions from 'src/ui/hooks/useWindowDimension'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { setCssVariable } from 'src/ui/utils/setCssVariable'

export const useMaxModalHeight = () => {
  const dimensions = useWindowDimensions()

  if (isDefined(dimensions)) {
    setCssVariable('--max-modal-height', `${dimensions.height * 0.01}px`)
  }
}
