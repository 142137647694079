import { TotalPrice } from 'src/core/Reservation/domain/Reservation.model'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { mapPriceDTO } from 'src/core/Shared/infrastructure/mappers/mapPriceDTO'
import { ExtendedPriceDTO } from 'src/core/Shared/infrastructure/dto/Price.dto'

export const calculateTotalPrice = (total: ExtendedPriceDTO): TotalPrice => {
  if (
    isDefined(total.converted) &&
    total.currency !== total.converted.currency
  ) {
    return {
      original: mapPriceDTO(total, { getConverted: false }),
      converted: mapPriceDTO(total.converted, { getConverted: true }),
      isSameCurrency: false,
    }
  }

  return {
    original: mapPriceDTO(total, { getConverted: false }),
    converted: mapPriceDTO(total, { getConverted: false }),
    isSameCurrency: true,
  }
}
