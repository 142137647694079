import { useModal } from 'src/ui/hooks/useModal'
import { QuotaErrorModal } from 'src/ui/views/AvailableRooms/QuotaErrorModal'
import { container } from 'src/core/Shared/_di'
import { isMultiroomFromPrimitives } from 'src/core/Availability/domain/AvailabilityCriteria'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { CustomEvent } from 'src/core/Shared/infrastructure/eventsManager'

export const useHandleNoQuotaError = () => {
  const { queryUtils } = useApplicationRouter()
  const { showModal: showNoQuotaErrorModal, hideModal: hideNoQuotaErrorModal } =
    useModal(QuotaErrorModal)

  const rooms = queryUtils.getRawParam('rooms')
  const adults = queryUtils.getRawParam('adult')

  const calculateMultiroom = () => {
    return isMultiroomFromPrimitives({
      rooms,
      adults,
    })
  }

  const deleteCart = () => {
    container.resolve('deleteCart')()
  }

  const handleRepeatSearch = () => {
    hideNoQuotaErrorModal()
    deleteCart()
    container
      .resolve('eventsManager')
      .emit(CustomEvent.GET_AVAILABILITY_FRESH_DATA)
  }

  const onClose = () => {
    deleteCart()
  }

  const handleNoQuotaError = () => {
    showNoQuotaErrorModal({
      isMultiroom: calculateMultiroom(),
      onRetrySearch: handleRepeatSearch,
      onClose: onClose,
    })
  }

  return {
    handleNoQuotaError,
  }
}
