import { Price, PriceOptions } from 'src/core/Shared/domain/Price.model'
import { ExtendedPriceDTO } from 'src/core/Shared/infrastructure/dto/Price.dto'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export const mapPriceDTO = (
  price: ExtendedPriceDTO,
  { getConverted }: PriceOptions,
): Price => {
  const exponentDivisor = Math.pow(
    10,
    getConverted && isDefined(price.converted)
      ? price.converted.exponent
      : price.exponent,
  )

  if (!getConverted || isUndefined(price.converted)) {
    return {
      value: price.value / exponentDivisor,
      currency: price.currency,
    }
  }

  return {
    value: price.converted.value / exponentDivisor,
    currency: price.converted.currency,
  }
}
