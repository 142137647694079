import { EnvManager, Env } from 'src/core/Shared/domain/EnvManager'

export const envManager: EnvManager = {
  getEnv: () => process.env.NEXT_PUBLIC_ENV as Env,
  isProduction: () => process.env.NEXT_PUBLIC_ENV === 'production',
  getBaseImagesUrl: () => process.env.NEXT_PUBLIC_IMAGES_BASE_URL as string,
  getLoggerConfig: () =>
    JSON.parse(process.env.NEXT_PUBLIC_LOGGER_CONFIG as string),
  getCookiesConfig: () =>
    JSON.parse(process.env.NEXT_PUBLIC_COOKIES_CONFIG as string),
  isApiMockingEnabled: () => process.env.NEXT_PUBLIC_API_MOCKING === 'enabled',
  getAuthConfig: () =>
    JSON.parse(process.env.NEXT_PUBLIC_AUTH_CONFIG as string),
  hasToAppendScripts: () => {
    return (
      process.env.NEXT_PUBLIC_ENV !== 'mock' &&
      process.env.NEXT_PUBLIC_DISABLE_SCRIPTS !== 'true'
    )
  },
}
