import React, { FC } from 'react'
import styles from './Gallery.module.scss'
import { Image } from 'src/ui/components/atoms/Image'
import { ImageLoader } from 'src/ui/utils/images'
import { Text } from 'src/ui/components/atoms/Text'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import CloseIcon from 'src/ui/components/atoms/Icon/_icons/interaction/CloseIcon'
import { useMedia } from 'src/ui/hooks/useMedia'
import { useEscapeOnClose } from 'src/ui/hooks/useEscapeOnClose'
import { useTrans } from 'src/ui/hooks/useTrans'
import { RoomImageSlide } from 'src/core/Availability/domain/Availability.model'

interface Props {
  imageSlides: RoomImageSlide[]
  loader: ImageLoader
  isCarouselInGalleryOpened: boolean
  onClose: () => void
  onOpenCarousel: (index: number) => void
}

export const Gallery: FC<Props> = ({
  imageSlides,
  loader,
  isCarouselInGalleryOpened,
  onClose,
  onOpenCarousel,
}) => {
  const { media } = useMedia()
  const { trans } = useTrans(['new-reservation'])
  useEscapeOnClose({
    onClose: () => {
      if (isCarouselInGalleryOpened) return
      onClose()
    },
  })

  const getImageClass = (index: number) => {
    if (index % 8 === 0) return styles.large
    if (index % 8 >= 1 && index % 8 <= 4) return styles.small
    if (index % 8 === 5 || index % 8 === 6) return styles.wide
    if (index % 8 === 7) return styles.lastLarge

    return styles.small
  }

  const isMobileOrTablet = media === 'mobile' || media === 'tablet'

  const getImageHeight = (index: number) => {
    if (isMobileOrTablet) {
      return 260
    }

    if (index % 8 === 0 || index % 8 === 7) {
      return 461
    }

    if (index % 8 >= 5) {
      return 226
    }

    return 226
  }

  const getImageWidth = (index: number) => {
    if (isMobileOrTablet) {
      return 382
    }

    if (index % 8 === 0 || index % 8 >= 5) {
      return 541
    }

    return 266
  }

  return (
    <div className={styles.overlay}>
      <div className={styles.header}>
        <Text as="p" fontStyle="l-700">
          {trans('available-rooms_carousel_gallery_title')}
        </Text>
        <div className={styles.closeButton} onClick={onClose}>
          <Text fontStyle="s-500">
            {trans('available-rooms_carousel_gallery_close')}
          </Text>
          <Icon size="l" icon={CloseIcon} />
        </div>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.container}>
        {imageSlides.map((imageSlide, index) => (
          <div
            key={index}
            className={getImageClass(index)}
            onClick={() => onOpenCarousel(index + 1)}
          >
            <Image
              className={styles.img}
              alt={`${imageSlide.alt} in gallery`}
              src={imageSlide.src}
              height={getImageHeight(index)}
              width={getImageWidth(index)}
              loader={loader}
              quality={75}
              priority={true}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
