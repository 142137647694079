import { FC } from 'react'
import { LoaderGradient, Text } from 'src/ui/components'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Image } from 'src/ui/components/atoms/Image'

import styles from './FullScreenLoader.module.scss'

interface Props {
  mainText?: string
  secondaryText?: string
}

export const FullScreenLoader: FC<Props> = ({ mainText, secondaryText }) => (
  <div
    className={styles.container}
    aria-live="polite"
    data-testid="full-screen-loader"
  >
    <div className={styles.content}>
      <div className={styles.loaderWrapper}>
        <LoaderGradient
          size="4xl"
          colors={['interactive-secondary-enabled', 'fill-ocean-100']}
        />
      </div>
      <Flex direction="column" gap="m" alignItems="center">
        {mainText ? (
          <Flex direction="column" alignItems="center" gap="m">
            <Text className={styles.mainText} fontStyle="2xl-700" color="dark">
              {mainText}
            </Text>
            <Text
              className={styles.secondaryText}
              fontStyle="m-300"
              color="dark"
            >
              {secondaryText}
            </Text>
          </Flex>
        ) : (
          <Image
            src="/assets/images/main-logo-dark.svg"
            height={47}
            width={135}
            alt="barcelo-logo"
            priority={true}
          />
        )}
      </Flex>
    </div>
  </div>
)
