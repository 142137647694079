import { AuthRepository } from 'src/core/User/domain/Auth.repository'
import {
  CustomEvent,
  EventsManager,
} from 'src/core/Shared/infrastructure/eventsManager'
import { HttpClient } from 'src/core/Shared/infrastructure/httpClient'
import {
  UserDTO,
  UserLoyaltyProfileDTO,
} from 'src/core/User/infrastructure/User.api.dto'
import { AxiosError } from 'axios'
import { UnauthorizedError } from 'src/core/Shared/domain/UnauthorizedError'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { CartRepository } from 'src/core/Cart/domain/Cart.repository'
import { LogoutError } from 'src/core/User/domain/LogoutError'
import { mapUserExists } from './mapUserExists'
import { mapUser } from './mapUser'

interface RepositoryDependencies {
  eventsManager: EventsManager
  authApiClient: HttpClient
  cartRepository: CartRepository
}

export const apiAuthRepository = ({
  eventsManager,
  authApiClient,
  cartRepository,
}: RepositoryDependencies): AuthRepository => ({
  login: () => {
    eventsManager.emit(CustomEvent.LOGIN)
  },
  logout: async token => {
    try {
      if (isDefined(token)) {
        await authApiClient.post(
          '/oauth2/logout',
          {
            token,
            token_type_hint: 'access_token',
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
        )
      }

      cartRepository.deleteCart()
      eventsManager.emit(CustomEvent.LOGOUT)
    } catch (error) {
      throw new LogoutError('Error while revoking token')
    }
  },
  getUser: async token => {
    try {
      const userDto = await authApiClient.get<UserDTO>('/userinfo', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      return mapUser(userDto)
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          throw new UnauthorizedError(
            'Token expired while getting user data',
            error.response?.status,
          )
        }

        const any4xxError = new RegExp(/4\d\d/)
        if (any4xxError.test(error.response?.status.toString() ?? '')) {
          throw new UnauthorizedError(
            `Unknown error while getting user data with status ${error.response?.status}`,
            error.response!.status,
          )
        }
      }

      throw error
    }
  },
  findUserExistsByEmail: async email => {
    try {
      const { data: userLoyaltyProfileDTO } =
        await authApiClient.get<UserLoyaltyProfileDTO>(`/users/check`, {
          params: {
            email,
          },
        })

      return mapUserExists(userLoyaltyProfileDTO)
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 404) {
          return {
            found: false,
          }
        }
      }
      throw error
    }
  },
})
