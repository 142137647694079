export const FullScreenIcon = () => (
  <>
    <rect
      opacity="0.486331"
      width="24"
      height="24"
      rx="2"
      fill="#0B0D0E"
      fillOpacity="0.6"
    />
    <path
      d="M8.64688 15.7058L4.65381 19.6989"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.1177 8.99987V4.23517H15.353"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.23535 15.3528V20.1175H9.00006"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.1176 4.23517L16.1245 8.22824"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.7061 15.7058L19.6991 19.6989"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.23535 8.99987V4.23517H9.00006"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.1177 15.3528V20.1175H15.353"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.23535 4.23517L8.22842 8.22824"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default FullScreenIcon
