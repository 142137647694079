import { useState, useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'

interface WindowDimensions {
  width: number
  height: number
}

function getWindowDimensions(): WindowDimensions | undefined {
  if (typeof window === 'undefined') return undefined
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export default function useWindowDimensions(): WindowDimensions | undefined {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  )

  const handleResize = useDebouncedCallback(() => {
    setWindowDimensions(getWindowDimensions())
  }, 200)

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return windowDimensions
}
