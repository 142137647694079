import styles from './Dropdown.module.scss'
import { classNames } from 'src/ui/utils/classnames'

import { FC, ReactNode, RefObject, useEffect, useRef } from 'react'

interface Props {
  show: boolean
  triggerRef?: RefObject<HTMLElement>
  children: ReactNode
  className?: string
  blockBodyScroll?: boolean
  onClickOutside: () => void
  autoFocus?: boolean
}

export const Dropdown: FC<Props> = ({
  show,
  children,
  triggerRef,
  className,
  blockBodyScroll = false,
  onClickOutside,
  autoFocus = true,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (autoFocus) {
      dropdownRef.current?.focus()
    }

    const handleClickEsc = (event: KeyboardEvent) => {
      if (event.key !== 'Escape') return
      document.body.style.overflowY = 'unset'
      onClickOutside()
    }
    document.addEventListener('keydown', handleClickEsc)

    const handleClickOutside = (event: MouseEvent) => {
      if (!show) {
        return
      }

      const hasClickedInDropdown =
        dropdownRef.current &&
        event.target instanceof Node &&
        dropdownRef.current.contains(event.target)

      if (hasClickedInDropdown) {
        return
      }

      const hasClickedInTrigger =
        event.target instanceof Node &&
        triggerRef?.current?.contains(event.target)

      if (hasClickedInTrigger) {
        return
      }
      onClickOutside()
    }
    document.addEventListener('mousedown', handleClickOutside)

    if (blockBodyScroll && dropdownRef?.current) {
      document.body.style.overflowY =
        dropdownRef.current.scrollHeight > 800 ? 'hidden' : 'unset'
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleClickEsc)

      if (
        blockBodyScroll &&
        dropdownRef?.current &&
        dropdownRef.current.scrollHeight > 800
      ) {
        document.body.style.overflowY = 'unset'
      }
    }
  }, [blockBodyScroll, onClickOutside, triggerRef])

  if (!show) {
    return null
  }

  return (
    <div
      className={classNames(styles.container, className)}
      ref={dropdownRef}
      tabIndex={1}
      data-testid="dropdown"
    >
      {children}
    </div>
  )
}
