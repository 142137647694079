import axios, { AxiosInstance } from 'axios'
import { getApiAcceptLanguage } from './locales/i18nUtils'
import { isDefined } from './wrappers/javascriptUtils'
import { BasicApiClient } from './basicApiClient'

export const authApiClientAxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_AUTH_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

authApiClientAxiosInstance.interceptors.request.use(config => {
  if (isDefined(config.headers)) {
    config.headers['Accept-Language'] = getApiAcceptLanguage()
  }
  return config
})

export class AuthApiClient extends BasicApiClient {
  constructor({
    authApiClientAxiosInstance,
  }: {
    authApiClientAxiosInstance: AxiosInstance
  }) {
    super(authApiClientAxiosInstance)
  }
}
