import {
  captureException,
  captureMessage,
  setContext,
  setUser,
} from '@sentry/nextjs'
import {
  AvailabilityError,
  AvailabilityErrorType,
} from 'src/core/Availability/domain/AvailabilityError'
import { Time } from '../Time'

export interface Sentry {
  sendError: (error: unknown) => void
  sendWarning: (message: string) => void
  addContextInfo: (
    key: { [p: string]: unknown } | undefined,
    context?: string,
  ) => void
  setUser: (email: string) => void
}

export const sentry: Sentry = {
  sendError: error => {
    const basicExtraData = {
      'Client Time': Time.now().format('YYYY-MM-DD HH:mm:ssZ'),
    }

    if (error instanceof AvailabilityError) {
      if (
        error.type ===
        AvailabilityErrorType.AVAILABILITY_SERVICE_UNAVAILABLE_ERROR
      ) {
        return
      }
    }

    if (!(error instanceof Error)) {
      captureException(new Error('Non-Error exception'), {
        extra: {
          ...basicExtraData,
          'Non-Error exception body': JSON.stringify(error, null, 2),
        },
      })
      return
    }

    captureException(error, {
      ...basicExtraData,
      extra: { Error: JSON.stringify(error, null, 2) },
    })
  },
  sendWarning: message => captureMessage(message, 'warning'),
  addContextInfo: (context, key) =>
    setContext(key ?? 'no-key', context ?? null),
  setUser: email => setUser({ email }),
}
