import useSWR, { useSWRConfig, SWRConfiguration } from 'swr'
import {
  isDefined,
  isNull,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useEffect, useRef } from 'react'

type QueryError = new (message: string, ...args: any[]) => Error

interface ErrorWithType extends Error {
  message: string
  type: number
}

type ErrorDefinition = {
  error: QueryError
  type?: number
  handler: (error: Error) => void
}
export type ErrorDefinitions = Array<ErrorDefinition>

interface QueryOptions<Data> {
  revalidateIfStale?: boolean
  revalidateOnMount?: boolean
  shouldRetryOnError?: boolean
  onSuccess?: (data: Data) => void
  /**
   * Cuándo sobreescribimos el onError perdemos el manejo de errores generales.
   */
  overrideOnError?: (error: QueryError) => void
  quietError?: boolean
  errorDefinitions?: ErrorDefinitions
}

export const useQueryService = <Data>(
  key: string,
  deps: unknown[] | null,
  service: (() => Promise<Data>) | (() => Data),
  config?: QueryOptions<Data>,
) => {
  const swrConfig = useSWRConfig()
  const quietError = isDefined(config) && config.quietError
  const waiter = useRef(new Waiter())

  const defaultErrorHandler = (): Partial<SWRConfiguration> => ({
    onError: error => {
      swrConfig.onError(error, key, swrConfig)
    },
  })

  const customErrorHandler = (): Partial<SWRConfiguration> => ({
    onError: error => {
      if (isDefined(config) && isDefined(config.overrideOnError)) {
        config.overrideOnError(error)
        return
      }

      if (isDefined(config?.errorDefinitions)) {
        const errorFromDefinitions = config?.errorDefinitions.find(
          errorDefinition => {
            const hasToHandleErrorWithType =
              isErrorWithType(error) && isDefined(errorDefinition.type)

            return error instanceof errorDefinition.error &&
              hasToHandleErrorWithType
              ? error.type === errorDefinition.type
              : error instanceof errorDefinition.error
          },
        )

        if (isDefined(errorFromDefinitions)) {
          handleErrorWithDefinitions(errorFromDefinitions, error)
          return
        }

        if (quietError) {
          return
        }

        swrConfig.onError(error, key, swrConfig)
      }
    },
  })

  useEffect(() => {
    waiter.current.markAsReady()
  }, [waiter])

  return useSWR<Data>(
    // Usa solo los valores definidos para las dependencias. Así, mismos usos
    // con paso de parámetros distinto funcionan igual. P.e. un opcional
    // "(x?: Type) => Promise" que en un caso no se le pasa parámetro "()" y en
    // otro se le pasa una variable que es undefined "(noExiste)".
    isNull(deps) ? null : [key, ...deps.filter(isDefined)],
    async () => {
      await waiter.current.wait()
      return service()
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...config,
      ...(isDefined(config?.overrideOnError) ||
      isDefined(config?.errorDefinitions)
        ? customErrorHandler()
        : defaultErrorHandler()),
    },
  )
}

const handleErrorWithDefinitions = (
  errorDefinition: ErrorDefinition,
  error: Error,
) => {
  const hasToHandleErrorWithType =
    isErrorWithType(error) && isDefined(errorDefinition.type)
  if (hasToHandleErrorWithType) {
    handleErrorWithType(error, errorDefinition)
    return
  }

  errorDefinition.handler(error)
}

const handleErrorWithType = (
  error: ErrorWithType,
  errorDefinition: ErrorDefinition,
) => {
  if (error.type === errorDefinition.type) {
    errorDefinition.handler(error)
    return
  }
}

const isErrorWithType = (error: any): error is ErrorWithType =>
  typeof error.type !== 'undefined'

class Waiter {
  private readonly readyPromise: Promise<void>
  private resolveReady!: () => void

  constructor() {
    this.readyPromise = new Promise<void>(resolve => {
      this.resolveReady = resolve
    })
  }

  public markAsReady() {
    this.resolveReady()
  }

  public wait(): Promise<void> {
    return this.readyPromise
  }
}
