import { User, UserLevel } from '../domain/User'
import { UserDTO } from './User.api.dto'
import { mapDiscount } from './mapDiscount'

export const mapUser = (userDto: UserDTO): User => {
  return {
    email: userDto.email,
    givenName: userDto.given_name,
    familyName: userDto.family_name,
    middleName: userDto.middle_name,
    discount: mapDiscount(userDto.discount),
    level: mapUserLevel(userDto.profile_level_code),
    address: userDto.address,
    city: userDto.city,
    crmId: userDto.crm_id,
    crsId: userDto.crs_id,
    phoneNumber: userDto.phone_number,
    postalCode: userDto.postalCode,
    profileLevelCode: userDto.profile_level_code,
    state: userDto.state,
    street: userDto.street,
  }
}

const mapUserLevel = (
  userLevel: UserDTO['profile_level_code'] | undefined,
): UserLevel => {
  switch (userLevel) {
    case undefined:
      return UserLevel.NotFound
    case '-1':
      return UserLevel.NotFound
    case '1':
      return UserLevel.Start
    case '2':
      return UserLevel.Intense
    case '3':
      return UserLevel.Unique
    default:
      return UserLevel.NotFound
  }
}
