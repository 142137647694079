import { ParsedUrlQuery } from 'node:querystring'
import { envManager } from './envManager'
import { isDefined, isEmpty, isUndefined } from './wrappers/javascriptUtils'

export interface PixelsManager {
  arePixelsEnabled: (query: { [k: string]: string } | ParsedUrlQuery) => boolean
}

export const pixelsManager: PixelsManager = {
  arePixelsEnabled: query => {
    if (isEmpty(query)) {
      return true
    }

    const hasToAppendScriptsByQuery =
      (isDefined(query.pixels) && query.pixels === 'true') ||
      isUndefined(query.pixels)

    return envManager.hasToAppendScripts() && hasToAppendScriptsByQuery
  },
}
