import { classNames } from 'src/ui/utils/classnames'
import { FC } from 'react'

import styles from './Divider.module.scss'

interface Props {
  dir: 'horizontal' | 'vertical'
  className?: string
}
export const Divider: FC<Props> = ({ dir, className }) => (
  <div
    className={classNames(
      dir === 'horizontal' && styles.divider__horizontal,
      dir === 'vertical' && styles.divider__vertical,
      className,
    )}
  />
)
