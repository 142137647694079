import { CheckInCheckOutTimeDTO } from 'src/core/Hotel/infrastructure/Hotel.api.dto'
import { CheckInCheckOutTime } from 'src/core/Hotel/domain/Hotel.model'

export const mapCheckInAndCheckOutTime = (
  checkInCheckOutDTO: CheckInCheckOutTimeDTO,
): CheckInCheckOutTime => {
  return {
    checkInTime: formatHour(checkInCheckOutDTO.checkInTime),
    checkOutTime: formatHour(checkInCheckOutDTO.checkOutTime),
  }
}

const formatHour = (hour: string) => `${hour.slice(0, 2)}:${hour.slice(2)}`
