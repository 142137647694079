import { Hotel } from 'src/core/Hotel/domain/Hotel.model'
import { HotelRepository } from 'src/core/Hotel/domain/Hotel.repository'
import { Analytics } from 'src/core/Shared/domain/Analytics'

export const getHotelById =
  ({ hotelRepository, analytics }: Dependencies) =>
  (hotelId: string, language: string, hasChildren: boolean) =>
  async (): Promise<Hotel> => {
    const hotel = await hotelRepository.getById(hotelId, language, hasChildren)
    analytics.requests.hotel(hotel)

    return hotel
  }

interface Dependencies {
  hotelRepository: HotelRepository
  analytics: Analytics
}
