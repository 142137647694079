import { FC } from 'react'

export const InfoIconNotification: FC = () => (
  <>
    <rect x="0.5" y="0.5" width="21" height="21" rx="10.5" fill="#e5f4fd" />
    <path
      d="M11 13.25V11M11 8.75H11.0056M16.625 11C16.625 14.1066 14.1066 16.625 11 16.625C7.8934 16.625 5.375 14.1066 5.375 11C5.375 7.8934 7.8934 5.375 11 5.375C14.1066 5.375 16.625 7.8934 16.625 11Z"
      stroke="#004b81"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default InfoIconNotification
