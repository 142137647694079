import { AnalyticsRequestAvailability } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'

export const availability: AnalyticsRequestAvailability = availability => {
  const mealPlansIds = new Set()
  const rateNames = new Set()
  const rateIds = new Set()
  const roomIds = new Set()

  availability.stays.forEach(stay => {
    stay.rooms.forEach(room => {
      roomIds.add(room.id)

      room.mealplans.forEach(mealplan => {
        mealPlansIds.add(mealplan.id)

        mealplan.rates.forEach(rate => {
          rateIds.add(rate.id)
          rateNames.add(rate.name)
        })
      })
    })
  })

  const availabilityData = {
    booking_availability: '1',
    availability_link: true,

    available_rooms_quantity: availability.stays.map(stay => {
      return stay.rooms.length
    }),
    available_board_basis: Array.from(mealPlansIds.values()),
    available_rate_plans: Array.from(rateNames.values()),
    available_rate_codes: Array.from(rateIds.values()),
    available_rooms_list: Array.from(roomIds.values()),
  }

  container
    .resolve('logger')
    .info(
      'Analytics/links/requests/availability - availabilityData',
      availabilityData,
    )
  window.utag.link(availabilityData)

  if (window.utag_data.availability_link && window.utag_data.hotel_link) {
    const bothLinksData = {
      // Room Selection Specific Variables
      ecommerce_action: 'checkout',
      ecommerce_actionField_step: '1',
      ecommerce_action_field_list_gtag: 'step 1',
      ecommerce_action_field_step_gtag: '1',
      ecommerce_action_gtag: 'begin_checkout',
    }
    container
      .resolve('logger')
      .info(
        'Analytics/links/requests/availability - bothLinksData',
        bothLinksData,
      )
    window.utag.link(bothLinksData)
  }
}
