import { i18n } from 'next-i18next'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { AvailableLanguageConfig } from './types'

const { defaultLanguage } = require('../../../../../i18n-config')
const availableLanguagesRaw = require('/available-languages.json')

export const getApiAcceptLanguage = () => {
  const actualLanguageConfig = getCurrentLanguageConfig()

  return (
    actualLanguageConfig?.languageProperties?.apiLanguage ?? DEFAULT_LANGUAGE
  )
}

export const getCurrentLanguageConfig = () => {
  const actualLanguage = availableLanguagesRaw.find(
    (rawLng: AvailableLanguageConfig) => {
      return (
        i18n?.language?.toLowerCase() ===
        rawLng.languageProperties?.cbeLanguage.toLowerCase()
      )
    },
  )

  if (isUndefined(actualLanguage)) {
    return availableLanguagesRaw.find((rawLng: AvailableLanguageConfig) => {
      return rawLng.languageProperties?.cbeLanguage === DEFAULT_LANGUAGE
    })
  }

  return actualLanguage
}

export const DEFAULT_LANGUAGE = defaultLanguage
