import { ConsoleLogger } from 'src/core/Shared/domain/ConsoleLogger'

export const consoleLogger: ConsoleLogger = {
  error: console.error,
  warning: console.warn,
  info: (message, context) =>
    console.info('logger info', message, JSON.stringify(context, null, 2)),
  setUser: email => console.info(email),
  setContext: context =>
    console.info('logger setContext', JSON.stringify(context, null, 2)),
}
