import useSWRMutation from 'swr/mutation'
import { useSWRConfig } from 'swr'

export const useSyncMutation = <Params extends unknown[], Return>(
  key: string,
  fn: (...params: Params) => Return,
) => {
  const config = useSWRConfig()

  // @ts-ignore
  const performMutation = (...data: Params): ReturnType<typeof fn> => {
    try {
      return fn(...data) as ReturnType<typeof fn>
    } catch (error) {
      config.onError(error, key, config)
    }
  }

  return {
    performMutation,
  }
}

export const useAsyncMutation = <Params extends unknown[], Return>(
  key: string,
  fn: (...params: Params) => Return,
) => {
  const config = useSWRConfig()

  const mutation = (
    url: string,
    {
      arg,
    }: {
      arg: Params
    },
  ) => {
    return fn(...arg)
  }
  const { trigger } = useSWRMutation(key, mutation)

  const performMutation = async (
    ...data: Params
  ): // @ts-ignore
  Return => {
    try {
      // @ts-ignore
      return (await trigger(data as never)) as Return
    } catch (error) {
      config.onError(error, key, config)
    }
  }

  return {
    performMutation,
  }
}
