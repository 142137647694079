export class ItineraryNumberError extends Error {
  constructor(message: string, public type: ItineraryNumberErrorType) {
    super(message)
  }

  static isNotFound(error: unknown): error is ItineraryNumberError {
    return (
      error instanceof ItineraryNumberError &&
      error.type === ItineraryNumberErrorType.NotFound
    )
  }

  static doesNotMatch(error: unknown): error is ItineraryNumberError {
    return (
      error instanceof ItineraryNumberError &&
      error.type === ItineraryNumberErrorType.DoesNotMatch
    )
  }

  static isOld(error: unknown): error is ItineraryNumberError {
    return (
      error instanceof ItineraryNumberError &&
      error.type === ItineraryNumberErrorType.Old
    )
  }
}

export enum ItineraryNumberErrorType {
  NotFound,
  Old,
  DoesNotMatch,
}
