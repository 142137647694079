import { FC, ReactElement, ElementType } from 'react'
import { Trans } from 'next-i18next'

interface Props {
  i18nKey: string
  values?: object
  parent?: ElementType
  className?: string
  components: { [key in string]: ReactElement }
}

/**
 * Existing self-closing HTML tag names are reserved keys and won't work: link, img, media.
 */
export const Translate: FC<Props> = ({
  i18nKey,
  values,
  components,
  parent,
  className,
}) => (
  <Trans
    i18nKey={i18nKey}
    values={values}
    components={components}
    parent={parent}
    className={className}
  />
)
