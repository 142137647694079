import 'react-loading-skeleton/dist/skeleton.css'

import { FC } from 'react'
import { default as ReactSkeleton } from 'react-loading-skeleton'
import styles from './Skeleton.module.scss'
import { classNames } from 'src/ui/utils/classnames'

export type TextLineHeight =
  | '2xs'
  | 'xs'
  | 's'
  | 'm'
  | 'l'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'

interface Props {
  height?: number | string
  width?: number | '100%'
  count?: number
  textLineHeight?: TextLineHeight
  containerClassName?: string
  borderRadius?: number | string
  className?: string
  'data-testid'?: string
}

export const Skeleton: FC<Props> = ({
  height = 20,
  width = '100%',
  'data-testid': testId,
  ...props
}) => {
  const { textLineHeight } = props

  const sizes = {
    '2xs': `${toRem(16)}rem`,
    xs: `${toRem(20)}rem`,
    s: `${toRem(24)}rem`,
    m: `${toRem(30)}rem`,
    l: `${toRem(30)}rem`,
    xl: `${toRem(34)}rem`,
    '2xl': `${toRem(36)}rem`,
    '3xl': `${toRem(42)}rem`,
    '4xl': `${toRem(56)}rem`,
    '5xl': `${toRem(70)}rem`,
  }

  return (
    <ReactSkeleton
      containerClassName={classNames(
        styles.container,
        width === '100%' && styles.fullWidth,
      )}
      height={textLineHeight ? sizes[textLineHeight] : height}
      width={width}
      containerTestId={testId}
      {...props}
    />
  )
}

const toRem = (number: number) => number / 16
