import { createPortal } from 'react-dom'
import { FC, ReactNode, useEffect, useState } from 'react'
import { isNull } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

interface Props {
  isOpen: boolean
  children: ReactNode
}

export const PortalNotification: FC<Props> = ({ isOpen, children }) => {
  const [notificationRoot, setNotificationRoot] = useState<HTMLElement | null>(
    null,
  )

  useEffect(() => {
    setNotificationRoot(document.getElementById('notification-root'))
  }, [])

  if (isNull(notificationRoot)) {
    return null
  }

  const portalContent = isOpen ? children : null
  return createPortal(portalContent, notificationRoot)
}
