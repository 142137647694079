export interface Cryptography {
  encodeBase64: (value: string) => string
  decodeBase64: (value: string) => string
  encodeSha256: (value: string) => Promise<string>
}

export const cryptography: Cryptography = {
  encodeBase64: value => Buffer.from(value, 'utf8').toString('base64'),
  decodeBase64: value => Buffer.from(value, 'base64').toString('utf8'),
  encodeSha256: async value => {
    const msgBuffer = new TextEncoder().encode(value)
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)
    const hashArray = Array.from(new Uint8Array(hashBuffer))

    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
  },
}
