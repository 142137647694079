import { useModal, createModal } from 'src/ui/hooks/useModal'
import { Text, Modal, Button } from 'src/ui/components'
import { useTrans } from 'src/ui/hooks/useTrans'
import { container } from 'src/core/Shared/_di'

export const useHandleOldItineraryNumberError = () => {
  const { showModal, hideModal } = useModal(OldItineraryNumberErrorModal)

  const handleRepeatSearch = async () => {
    container.resolve('deleteCart')()
    hideModal()
  }

  const handleOldItineraryNumberError = () => {
    showModal({
      onRepeatSearch: handleRepeatSearch,
    })
  }

  return {
    handleOldItineraryNumberError,
  }
}

interface Props {
  onRepeatSearch: () => void
}

const OldItineraryNumberErrorModal = createModal(
  ({ onRepeatSearch }: Props) => {
    const { trans } = useTrans(['new-reservation'])

    return (
      <Modal
        size="m"
        type="passive"
        closable={false}
        data-quantum-modal="OldItineraryNumberErrorModal"
      >
        <Text
          as="h2"
          fontStyle={{ mobile: '2xl-700', laptop: '2xl-700' }}
          color="dark"
          centered
          className="mb-l"
          data-quantum-error="expired"
        >
          {trans('old-itinerary-number-error-modal_title')}
        </Text>
        <Text fontStyle="m-300" centered as="p" className="mb-xl">
          {trans('old-itinerary-number-error-modal_description')}
        </Text>
        <Button variant="primary" type="button" onClick={onRepeatSearch}>
          {trans('old-itinerary-number-error-modal_submit')}
        </Button>
      </Modal>
    )
  },
)
