import { AnalyticsRequestPaymentMethods } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'

export const paymentMethods: AnalyticsRequestPaymentMethods =
  paymentMethods => {
    const linkParams = {
      paymentMethods: paymentMethods,
    }
    container
      .resolve('logger')
      .info('Analytics/links/requests/paymentMethods', {
        linkParams,
        utag_data: window.utag_data,
      })
    window.utag.link(linkParams)
  }
