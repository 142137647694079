import { MarketPriceCode } from './MarketPriceCode'
import { MarketPrice } from 'src/core/User/domain/MarketPrice'
import { WithInjectedParams } from 'src/core/Shared/_di/types'
import markets from './market.json'

export interface MarketRepository {
  get: (market: MarketPriceCode) => MarketPrice
}

export const apiMarketRepository: WithInjectedParams<
  MarketRepository,
  void
> = () => ({
  get: (market: MarketPriceCode) => {
    return markets[market]
  },
})
