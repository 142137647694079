import { AnalyticsActionsHeaderClickPhone } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'

export const clickPhone: AnalyticsActionsHeaderClickPhone = () => {
  const linkParams = {
    ga4_event_name: 'click link',
    event: 'click link',
    event_category: 'header',
    event_action: 'phone booking',
    event_label: 'phone booking',
  }
  container
    .resolve('logger')
    .info('Analytics/links/actions/header/clickPhone', {
      linkParams,
      utag_data: window.utag_data,
    })
  window.utag.link(linkParams)
}
