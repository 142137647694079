import { AnalyticsActionsHeaderClickMyReservations } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'

export const clickMyReservations: AnalyticsActionsHeaderClickMyReservations =
  () => {
    const linkParams = {
      ga4_event_name: 'click link',
      event: 'click link',
      event_category: 'my Barcelo menu',
      event_action: 'reservations',
      event_label: 'my reservations',
    }
    container
      .resolve('logger')
      .info('Analytics/links/actions/header/clickMyReservations', {
        linkParams,
        utag_data: window.utag_data,
      })
    window.utag.link(linkParams)
  }
