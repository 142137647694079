import { createContext, FC, ReactNode, useContext, useMemo } from 'react'
import { useQueryService } from 'src/ui/hooks/useQuery'
import { container } from 'src/core/Shared/_di'

import { Country } from 'src/core/User/domain/Country.model'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { DefaultParamValues } from 'src/ui/hooks/useApplicationRouter/buildQueryUtils'

const defaultState: Country = {
  code: DefaultParamValues.country,
  callCenter: { phone: '', free: false },
}

export const CountryContext = createContext<Country>(defaultState)

interface Props {
  children: ReactNode
}

export const CountryProvider: FC<Props> = ({ children }) => {
  const { queryUtils, isRouterReady } = useApplicationRouter()

  const countryParam = queryUtils.getRawParam('country')

  const { data: country, isValidating } = useQueryService(
    'get-country',
    isRouterReady ? [countryParam] : null,
    () =>
      isDefined(countryParam)
        ? container.resolve('countryRepository').getCountry(countryParam)
        : Promise.resolve(undefined),
  )

  const getProviderValue = (): Country => {
    if (isValidating || isUndefined(country)) {
      return defaultState
    }

    return country
  }

  const memoizedCountry = useMemo(getProviderValue, [isValidating, country])

  return (
    <CountryContext.Provider value={memoizedCountry}>
      {children}
    </CountryContext.Provider>
  )
}

export const useCountry = () => useContext(CountryContext)
