import { AnalyticsActionsConfirmReservation } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'

/**
 * Se lanza en cuanto el usuario pulsa el botón de "continuar" en paso de pago
 */
export const confirmReservation: AnalyticsActionsConfirmReservation = () => {
  const linkParams = {
    ga4_event_name: 'click link',
    event: 'click link',
    event_category: 'conversion',
    event_action: 'payment_details_CBE',
    event_label: 'confirm_button',
  }
  container
    .resolve('logger')
    .info(
      'Analytics/links/actions/choosePayment/confirmReservation',
      linkParams,
    )
  window.utag.link(linkParams)
}
