import { SWRConfig, useSWRConfig } from 'swr'
import { ReactNode, FC } from 'react'

interface Props {
  children: ReactNode
  onError: (error: unknown, parentOnError: () => void) => void
}

export const AsyncErrorBoundary: FC<Props> = ({ children, onError }) => {
  const config = useSWRConfig()

  return (
    <SWRConfig
      value={{
        onError: (error, key) =>
          onError(error, () => config.onError(error, key, config)),
        shouldRetryOnError: false,
      }}
    >
      {children}
    </SWRConfig>
  )
}
