import { ReactNode, FC } from 'react'
import NiceModal from '@ebay/nice-modal-react'

interface ModalsProviderProps {
  children: ReactNode
}

export const ModalsProvider: FC<ModalsProviderProps> = ({ children }) => {
  return <NiceModal.Provider>{children}</NiceModal.Provider>
}
