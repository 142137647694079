import { container } from 'src/core/Shared/_di'
import {
  isUndefined,
  isNull,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { ParsedUrlQuery } from 'node:querystring'

type utagActionType = 'view' | 'link'

export const MAX_RETRIES = 30
export const RETRY_INTERVAL = 300

export const launchAnalytics = (
  analyticFunction: (...args: any[]) => void,
  args: any[],
  actionType: utagActionType,
  query: ParsedUrlQuery | null,
  retries: number = 0,
) => {
  try {
    const urlParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlParams)

    const arePixelsDisabled = !container
      .resolve('pixelsManager')
      .arePixelsEnabled(params)

    if (arePixelsDisabled) return

    if (actionType === 'view' && isNull(query)) {
      container.resolve('logger').warning('Analytics: query is missing')
      return
    }

    if (
      isUndefined(window.utag) ||
      isUndefined(window.utag.view) ||
      isUndefined(window.utag.link)
    ) {
      retries += 1
      if (retries < MAX_RETRIES) {
        setTimeout(
          () =>
            launchAnalytics(analyticFunction, args, actionType, query, retries),
          RETRY_INTERVAL,
        )
      } else {
        container
          .resolve('logger')
          .warning(
            `Analytics ${actionType}: window.utag is missing (${analyticFunction.name}) after all retries`,
          )
      }
      return
    }

    analyticFunction(...args)
  } catch (error) {
    container.resolve('logger').error(error)
  }
}
