import { FC } from 'react'
import { Skeleton } from '../Skeleton'

interface Props {
  width?: number | '100%'
  height?: number | string
}

export const ButtonSkeleton: FC<Props> = ({ height = 50, width = 100 }) => {
  return <Skeleton height={height} width={width} borderRadius="100px" />
}
