import styles from './Button.module.scss'
import { FC, MouseEventHandler, ReactNode } from 'react'
import { classNames } from 'src/ui/utils/classnames'
import { Loader } from 'src/ui/components/atoms/Loader'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export type ButtonSize = 'small' | 'large' | 'fullWidth' | 'smallFullWidth'

interface Props {
  children: ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement>
  type?: 'button' | 'submit'
  variant?: 'primary' | 'secondary' | 'on-image'
  size?:
    | ButtonSize
    | {
        mobile: ButtonSize
        tablet?: ButtonSize
        laptop?: ButtonSize
        desktop?: ButtonSize
      }
  className?: string
  isLoading?: boolean
  isDisabled?: boolean
  'data-testid'?: string
  width?: string
}

export const Button: FC<Props> = ({
  children,
  onClick,
  type,
  className,
  size = 'fullWidth',
  variant = 'primary',
  isLoading,
  isDisabled,
  'data-testid': testId,
  width,
  ...rest
}) => {
  const getClassName = () => {
    if (typeof size === 'string') {
      return classNames(
        styles[`mobile-${size}`],
        styles[`tablet-${size}`],
        styles[`laptop-${size}`],
        styles[`desktop-${size}`],
        className,
      )
    }

    const mobile = size.mobile
    const tablet = size.tablet || mobile
    const laptop = size.laptop || tablet
    const desktop = size.desktop || laptop

    return classNames(
      styles[`mobile-${mobile}`],
      styles[`tablet-${tablet}`],
      styles[`laptop-${laptop}`],
      styles[`desktop-${desktop}`],
      className,
    )
  }

  const getWidth = () => {
    if (isDefined(width)) {
      return styles[width]
    }
  }

  return (
    <button
      className={classNames(
        styles.button,
        getClassName(),
        styles[variant],
        getWidth(),
        isDisabled && styles[`${variant}__disabled`],
      )}
      onClick={onClick}
      type={type}
      disabled={isDisabled || isLoading}
      data-testid={testId}
      {...rest}
    >
      <div className={classNames(isLoading && styles.contentLoading)}>
        {children}
      </div>
      {isLoading && (
        <div className={styles.loaderWrapper}>
          <Loader
            size="l"
            color={
              variant === 'secondary'
                ? 'interactive-primary-active'
                : 'icon-light'
            }
          />
        </div>
      )}
    </button>
  )
}
