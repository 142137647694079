import { Cart, isPendingExtraPerUnit } from 'src/core/Cart/domain/Cart.model'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { ModifyExtrasRoomStayRequestBodyDTO } from './ExtrasRequest.api.dto'

export const mapApplyExtrasRequest = (
  cart: Cart,
): ModifyExtrasRoomStayRequestBodyDTO[] => {
  return cart.roomStays
    .map(({ id, pendingExtras }) => {
      if (isUndefined(pendingExtras) || isUndefined(pendingExtras.extras)) {
        return
      }

      return {
        id,
        extras: pendingExtras.extras.map(pendingExtra => {
          if (isPendingExtraPerUnit(pendingExtra)) {
            return {
              id: pendingExtra.extra.id,
              quantity: pendingExtra.count,
            }
          }

          return {
            id: pendingExtra.extra.id,
            adultsQuantity: pendingExtra.adultsCount,
            childrenQuantity: pendingExtra.childrenCount,
          }
        }),
      }
    })
    .filter(isDefined)
}
