import isUndefined from 'lodash/isUndefined'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import isNull from 'lodash/isNull'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import isString from 'lodash/isString'
import uniqueId from 'lodash/uniqueId'
import pick from 'lodash/pick'
import uniqBy from 'lodash/uniqBy'
import uniq from 'lodash/uniq'
import first from 'lodash/first'
import head from 'lodash/head'
import flattenDeep from 'lodash/flattenDeep'
import remove from 'lodash/remove'
import merge from 'lodash/merge'

export {
  isUndefined,
  isEmpty,
  noop,
  isNull,
  cloneDeep,
  isString,
  isEqual,
  uniqueId,
  pick,
  uniqBy,
  uniq,
  first,
  head,
  flattenDeep,
  remove,
  merge,
}

export const isDefined = <T>(value: T | undefined): value is T =>
  !isUndefined(value)

export const omit = <T extends object, K extends keyof T>(
  obj: T,
  keys: K[],
): Omit<T, K> => {
  const result: Partial<T> = {}

  Object.keys(obj).forEach(key => {
    if (!keys.includes(key as K)) {
      result[key as keyof T] = obj[key as keyof T]
    }
  })

  return result as Omit<T, K>
}

export const isNotEmptyString = (value: string | undefined): value is string =>
  isDefined(value) && value !== ''

export const removeHtmlTags = (html: string) => {
  return html.replace(/(<([^>]+)>)/gi, '')
}
