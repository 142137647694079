import { classNames } from 'src/ui/utils/classnames'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'

import styles from 'src/ui/components/molecules/CarouselFullScreen/FullScreenNavigationButton/FullScreenNavigationButton.module.scss'
import { ChevronIcon } from 'src/ui/components/atoms/Icon/_icons/navigation/ChevronIcon'
import { FC } from 'react'
import { Swiper as SwiperClass } from 'swiper/types'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

interface Props {
  swiperRef: SwiperClass
  isRTL: boolean
}

export const FullScreenNavigationPreviousButton: FC<Props> = ({
  swiperRef,
  isRTL,
}) => {
  function handlePreviousSlide() {
    if (isUndefined(swiperRef)) return

    return !isRTL ? swiperRef.slidePrev?.() : swiperRef.slideNext?.()
  }

  if (isUndefined(swiperRef)) return null

  return (
    <div
      onClick={handlePreviousSlide}
      className={classNames(styles.navigation, styles.leftNavigation)}
    >
      <Icon size="s" icon={ChevronIcon} rotate={-180} />
    </div>
  )
}
