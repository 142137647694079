import { AsyncErrorBoundary } from './AsyncErrorBoundary'
import { ReactNode, FC, useEffect } from 'react'
import { container } from 'src/core/Shared/_di'
import { CustomEvent } from 'src/core/Shared/infrastructure/eventsManager'
import { useErrorBoundary } from 'react-error-boundary'
import { useHandleNoQuotaError } from 'src/ui/errors/handlers/useHandleNoQuotaError'
import { useHandleOldItineraryNumberError } from 'src/ui/errors/handlers/useHandleOldItineraryNumberError'
import { useHandleExpiredCartError } from 'src/ui/errors/handlers/useHandleExpiredCartError'
import { ReservationError } from 'src/core/Reservation/domain/ReservationError'
import { UnauthorizedError } from 'src/core/Shared/domain/UnauthorizedError'
import { ItineraryNumberError } from 'src/core/Reservation/domain/ItineraryNumberError'
import {
  handleItineraryNumberErrorNotFound,
  handleItineraryNumberErrorDoesNotMatch,
} from 'src/ui/errors/handlers/handleItineraryNumberError'
import { useHandleNoBookingError } from 'src/ui/errors/handlers/useHandleNoBookingError'
import { useHandleUnauthorizedError } from 'src/ui/errors/handlers/handleUnauthorizedError'
import { useHandleLogoutError } from 'src/ui/errors/handlers/useHandleLogoutError'
import { LogoutError } from 'src/core/User/domain/LogoutError'
import { setQuantumUrlToLoggerContext } from 'src/core/Shared/infrastructure/errors/quantum'

interface Props {
  children: ReactNode
}

export const GlobalAsyncErrorBoundary: FC<Props> = ({ children }) => {
  const { showBoundary } = useErrorBoundary()

  useEffect(() => {
    return container
      .resolve('eventsManager')
      .on(CustomEvent.APPLICATION_ERROR, showBoundary)
  }, [showBoundary])

  useEffect(() => {
    setQuantumUrlToLoggerContext()
  }, [])

  const { handleNoQuotaError } = useHandleNoQuotaError()
  const { handleNoBookingError } = useHandleNoBookingError()
  const { handleOldItineraryNumberError } = useHandleOldItineraryNumberError()
  const { handleExpiredCartError } = useHandleExpiredCartError()
  const { handleUnauthorizedError } = useHandleUnauthorizedError()
  const { handleLogoutError } = useHandleLogoutError()

  const handlers = [
    { condition: ReservationError.isNoQuota, handler: handleNoQuotaError },
    { condition: ReservationError.isExpired, handler: handleExpiredCartError },
    {
      condition: (error: unknown) => error instanceof UnauthorizedError,
      handler: handleUnauthorizedError,
    },
    {
      condition: (error: unknown) => error instanceof LogoutError,
      handler: handleLogoutError,
    },
    {
      condition: ItineraryNumberError.isNotFound,
      handler: handleItineraryNumberErrorNotFound,
    },
    {
      condition: ItineraryNumberError.isOld,
      handler: handleOldItineraryNumberError,
    },
    {
      condition: ItineraryNumberError.doesNotMatch,
      handler: handleItineraryNumberErrorDoesNotMatch,
    },
    {
      condition: ReservationError.isNonExistent,
      handler: handleNoBookingError,
    },
  ]

  const handleError = (error: unknown) => {
    const hasErrorHandled = handlers.some(({ condition, handler }) => {
      if (condition(error)) {
        handler(error)
        return true
      }
    })

    if (hasErrorHandled) {
      return
    }

    container.resolve('logger').error(error)
    showBoundary(error)
  }

  return (
    <AsyncErrorBoundary onError={handleError}>{children}</AsyncErrorBoundary>
  )
}
