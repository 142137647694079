import { CSSProperties, FC } from 'react'

import styles from './Loader.module.scss'

import { Color, SpaceType } from 'src/ui/styles/settings'
import { useTrans } from 'src/ui/hooks/useTrans'

interface Props {
  size: SpaceType
  color: Color
}

export const Loader: FC<Props> = ({ size, color }) => {
  const { trans } = useTrans(['common'])

  const styleProps = {
    '--size': `var(--b-space-${size})`,
    '--color': `var(--b-color-${color})`,
  } as CSSProperties

  return (
    <div
      className={styles.container}
      style={styleProps}
      data-testid="loader"
      aria-label={trans('loader_loading_aria-label')}
      role="alert"
    >
      <div className={styles.loader} />
    </div>
  )
}
