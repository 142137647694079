import { HotelRepository } from 'src/core/Hotel/domain/Hotel.repository'

interface getCampaignForCouponParams {
  marketprice: string
  language: string
  hotelId: string
  promotionalCoupon: string
}

export const getCampaignForCoupon =
  ({ hotelRepository }: Dependencies) =>
  async ({
    marketprice,
    language,
    hotelId,
    promotionalCoupon,
  }: getCampaignForCouponParams) => {
    const campaigns = await hotelRepository.getCampaignForCoupon({
      marketprice,
      language,
      hotelId,
      promotionalCoupon,
    })

    return campaigns
  }

interface Dependencies {
  hotelRepository: HotelRepository
}
