import { Time } from 'src/core/Shared/infrastructure/Time'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { AvailabilityCriteria } from 'src/core/Availability/domain/Availability.model'
import {
  getPromotionalCouponName,
  Reservation,
  ReservationGuest,
} from 'src/core/Reservation/domain/Reservation.model'
import { getExtraQuantities } from 'src/core/Shared/domain/Extra.model'
import { ReservationCriteria as ReservationCriteriaModel } from 'src/core/Reservation/domain/Reservation.model'
import { Price } from 'src/core/Shared/domain/Price.model'
import { ExtendedPriceDTO } from 'src/core/Shared/infrastructure/dto/Price.dto'
import currencies from 'src/core/User/infrastructure/currencies.json'

export class ReservationCriteria implements ReservationCriteriaModel {
  hotelId: string
  marketCampaign: string | undefined
  roomStays: ReservationCriteriaRoomStay[]
  comment?: string

  constructor(
    hotelId: string,
    marketCampaign: string | undefined,
    roomStays: ReservationCriteriaRoomStay[],
    comment?: string,
  ) {
    this.hotelId = hotelId
    this.marketCampaign = marketCampaign
    this.roomStays = roomStays
    this.comment = comment
  }

  static fromPrimitivesAndAvailabilityCriteria(
    hotelId: string,
    marketCampaign: string | undefined,
    stays: Array<{
      roomId: string
      rateId: string
      coupon?: string
      groupCode?: string
      originalTotalPrice?: {
        netPrice: Price
        grossPrice: Price
      }
    }>,
    availabilityCriteria: AvailabilityCriteria,
  ): ReservationCriteria {
    return new ReservationCriteria(
      hotelId,
      marketCampaign,
      stays.map(
        ({ roomId, rateId, coupon, groupCode, originalTotalPrice }, index) => ({
          ratePlanId: rateId,
          roomTypeId: roomId,
          startDate: Time.fromDate(availabilityCriteria.checkIn).format(
            'YYYY-MM-DD',
          ),
          endDate: Time.fromDate(availabilityCriteria.checkOut).format(
            'YYYY-MM-DD',
          ),
          roomCount: 1,
          ...(isDefined(coupon) && { coupon }),
          ...(isDefined(groupCode) && { groupCode }),
          occupancy: {
            adults: availabilityCriteria.adults[index],
            children: availabilityCriteria.children[index],
            childrenAges: isDefined(availabilityCriteria.childrenAges)
              ? availabilityCriteria.childrenAges[index]
              : null,
          },
          extras: [],
          guest: undefined,
          ...(isDefined(originalTotalPrice) && {
            originalTotalPrice: {
              base: ReservationCriteria.mapPriceToExtendedPriceDTO(
                originalTotalPrice.netPrice,
              ),
              total: ReservationCriteria.mapPriceToExtendedPriceDTO(
                originalTotalPrice.grossPrice,
              ),
            },
          }),
        }),
      ),
    )
  }

  static mapPriceToExtendedPriceDTO(price: Price): ExtendedPriceDTO {
    const exponent = currencies[price.currency].exponent
    const exponentDivisor = Math.pow(10, exponent)

    return {
      value: Number((price.value * exponentDivisor).toFixed()),
      currency: price.currency,
      exponent,
    }
  }

  static fromReservation(
    reservation: Reservation,
    { asUser }: { asUser: boolean },
  ): ReservationCriteria {
    return new ReservationCriteria(
      reservation.hotel.id,
      reservation.marketCampaign,
      reservation.roomStays.map(roomStay => ({
        roomCount: 1,
        startDate: Time.fromDate(reservation.checkIn).format('YYYY-MM-DD'),
        endDate: Time.fromDate(reservation.checkOut).format('YYYY-MM-DD'),
        roomTypeId: roomStay.room.id,
        ratePlanId: roomStay.rate.id,
        coupon: getPromotionalCouponName(roomStay),
        groupCode: roomStay.groupCode,
        occupancy: {
          adults: roomStay.occupancy.adults,
          children: roomStay.occupancy.children,
          childrenAges: roomStay.occupancy.childrenAges,
        },
        extras: roomStay.extras.map(extra => ({
          id: extra.id,
          ...getExtraQuantities(extra),
        })),
        guest: asUser ? undefined : roomStay.guest,
      })),
    )
  }
}

interface ReservationCriteriaRoomStay {
  roomCount: number
  startDate: string
  endDate: string
  roomTypeId: string
  ratePlanId: string
  coupon?: string
  groupCode?: string
  occupancy: {
    adults: number
    children: number
    childrenAges: number[] | null
  }
  extras: Array<{
    id: string
    quantity?: number
    adultsQuantity?: number
    childrenQuantity?: number
  }>
  guest: ReservationGuest | undefined
  originalTotalPrice?: {
    base: ExtendedPriceDTO
    total: ExtendedPriceDTO
  }
}
