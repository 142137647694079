import { CouponType } from 'src/core/Shared/domain/CouponType'

export class Coupon {
  private readonly type: ReservationCouponType
  private readonly value: string

  constructor(type: ReservationCouponType, value: string) {
    this.type = type
    this.value = value
  }

  getValue() {
    return this.value
  }

  getType() {
    return this.type
  }

  isEditable() {
    return this.type === 'promotional' || this.type === 'empty'
  }

  isEmpty() {
    return this.type === 'empty'
  }
}

export type ReservationCouponType = 'promotional' | 'group' | 'empty'

export const couponTypeMap: { [key in ReservationCouponType]: CouponType } = {
  empty: 'myBarcelo',
  group: 'group',
  promotional: 'promotional',
}
