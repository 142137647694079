import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { getApiAcceptLanguage } from './locales/i18nUtils'
import { isDefined, isEmpty } from './wrappers/javascriptUtils'
import { BasicApiClient } from './basicApiClient'
import type { CacheRequestConfig } from 'axios-cache-interceptor'

export const cmsApiClientAxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_HOTEL_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

cmsApiClientAxiosInstance.interceptors.request.use(config => {
  if (isDefined(config.headers)) {
    config.headers['Accept-Language'] = getApiAcceptLanguage()
  }

  return config
})

export class CmsApiClient extends BasicApiClient {
  private defaultLanguage = 'en_gb'
  constructor({
    cmsApiClientAxiosInstance,
  }: {
    cmsApiClientAxiosInstance: AxiosInstance
  }) {
    super(cmsApiClientAxiosInstance)
  }

  async get<P>(
    path: string,
    config?: AxiosRequestConfig & CacheRequestConfig,
  ): Promise<P & { data: P }> {
    const response = await super.get<P>(path, config)

    if (isEmpty(response.data)) {
      const defaultLanguagePath = path.replace(
        /(\.\w{2}(?:-\w{2})?\.)/,
        `.${this.defaultLanguage}.`,
      )

      // Reintentar la solicitud con el idioma por defecto
      return super.get<P & { data: P }>(defaultLanguagePath, {
        ...config,
        // @ts-ignore
        cache: isDefined(config?.cache)
          ? config?.cache
          : { interpretHeader: false },
      })
    }

    return response
  }
}
