import { ImageLoader } from 'src/ui/utils/images'
import { FC } from 'react'
import { RoomImageSlide } from 'src/core/Availability/domain/Availability.model'
import { classNames } from 'src/ui/utils/classnames'
import styles from './CarouselFullScreen.module.scss'
import { FullScreenCloseButton } from './FullScreenNavigationButton/FullScreenCloseButton'
import { Carousel } from 'src/ui/components/molecules/Carousel'

interface Props {
  imageSlides: RoomImageSlide[]
  alt: string
  isRounded?: boolean
  loader: ImageLoader
  initialSlideIndex: number
  className?: string
  onClose: () => void
}

export const CarouselFullScreen: FC<Props> = ({
  onClose,
  className,
  initialSlideIndex,
  ...props
}) => {
  const handleClose = () => {
    onClose()
  }

  return (
    <div
      className={classNames(className, styles.overlay)}
      onClick={handleClose}
    >
      <div className={styles.topSection}>
        <FullScreenCloseButton onClose={handleClose} />
      </div>
      <div
        className={classNames(styles.carouselSection)}
        onClick={e => e.stopPropagation()}
      >
        <Carousel
          {...props}
          isRounded={true}
          hasToShowFullScreenButton={false}
          initialSlideIndex={initialSlideIndex}
        />
      </div>
      <div className={styles.bottomSection} />
    </div>
  )
}
