import {
  isDefined,
  isEmpty,
  isNull,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { HOTEL_DEFAULTS, Hotel } from 'src/core/Hotel/domain/Hotel.model'
import { HotelDTO, HotelRoomDTO } from './Hotel.api.dto'
import { mapDestination } from './mapDestination'
import { mapRooms } from './mapRooms'
import { mapImageWithDomain } from './mapImageWithDomain'

export const mapHotel = (
  hotelDTO: HotelDTO,
  roomsDTO: HotelRoomDTO[],
): Hotel => {
  return {
    id: hotelDTO.sabre_id,
    guestOptions: mapGuestOptions(roomsDTO, hotelDTO),
    name: hotelDTO.name,
    image: getMainImage(hotelDTO.hotelImgMain_dm),
    destination: mapDestination(hotelDTO),
    reviews: {
      averageRate: hotelDTO.rate?.toString(),
    },
    contact: {
      phone: hotelDTO.phones?.[0],
      email: hotelDTO.emails?.[0],
    },
    rooms: mapRooms(roomsDTO),
    isAdultsOnly: mapAdultsOnly(hotelDTO),
    highlights: hotelDTO.highlights?.map(highlight => highlight.title),
    cityTax: mapCityTaxes(hotelDTO),
  }
}

const getMainImage = (image: string | undefined) => {
  if (isUndefined(image) || isEmpty(image)) {
    return HOTEL_DEFAULTS().IMAGE
  }

  return mapImageWithDomain(image, HOTEL_DEFAULTS().FALLBACK_IMAGE)
}

const mapGuestOptions = (roomsData: HotelRoomDTO[], hotelDto: HotelDTO) => {
  const largestRoom = roomsData.reduce(
    (largest, current) => {
      if (
        isDefined(current.guestLimit_total) &&
        current.guestLimit_total > (largest?.guestLimit_total || 0)
      ) {
        return current
      }
      return largest
    },
    null as HotelRoomDTO | null,
  )
  const defaultOptions = {
    maxGuests: 3,
    maxAdults: 2,
    maxChildren: 1,
    maxChildAge: 14,
  }

  if (isUndefined(largestRoom) || isNull(largestRoom)) {
    return defaultOptions
  }

  return {
    maxGuests: largestRoom.guestLimit_total ?? defaultOptions.maxGuests,
    maxAdults: largestRoom.guestLimit_adults ?? defaultOptions.maxAdults,
    maxChildren: largestRoom.guestLimit_children ?? defaultOptions.maxChildren,
    maxChildAge: hotelDto.minimum_age_for_adults ?? defaultOptions.maxChildAge,
  }
}

const mapAdultsOnly = (hotelDto: HotelDTO) => {
  return hotelDto.is_hotel_adults_only ?? false
}

function mapCityTaxes(hotelDTO: HotelDTO): Hotel['cityTax'] | undefined {
  const indicator = hotelDTO.city_taxes_indicator
  const value = hotelDTO.city_taxes_value
  if (isUndefined(indicator) || isUndefined(value)) {
    return undefined
  }

  return {
    indicator,
    value,
  }
}
