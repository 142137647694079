import { CouponType } from './CouponType'

interface BasicCoupon {
  applies: boolean
}

export interface LevelCoupon extends BasicCoupon {
  type: CouponType.Level
  levelDiscount: number
}

export interface PromotionalCoupon extends BasicCoupon {
  name: string
  type: CouponType.Promotional
}

interface GroupCoupon extends BasicCoupon {
  type: CouponType.Group
}

export type ReservationCoupon = LevelCoupon | PromotionalCoupon | GroupCoupon

export const isGroupCoupon = (
  coupon: ReservationCoupon,
): coupon is GroupCoupon => {
  return coupon.type === CouponType.Group
}

export const isLevelCoupon = (
  coupon: ReservationCoupon,
): coupon is LevelCoupon => {
  return coupon.type === CouponType.Level
}

export const isPromotionalCoupon = (
  coupon: ReservationCoupon,
): coupon is PromotionalCoupon => {
  return coupon.type === CouponType.Promotional
}
