import {
  ReservationCriteria,
  getPromotionalCouponName,
} from 'src/core/Reservation/domain/Reservation.model'
import { getExtraQuantities } from 'src/core/Shared/domain/Extra.model'
import { Time } from 'src/core/Shared/infrastructure/Time'
import { Cart } from '../domain/Cart.model'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export const mapCartIntoReservationCriteria = (
  cart: Cart,
): ReservationCriteria => ({
  hotelId: cart.roomStays[0].hotel.id,

  marketCampaign: cart.marketCampaign,
  roomStays: cart.roomStays.map(roomStay => {
    const { originalTotalPrice } = roomStay
    const hasOriginalPrice = isDefined(originalTotalPrice)
    const roomStayCriteria = {
      roomCount: 1,
      startDate: Time.fromDate(cart.checkIn).format('YYYY-MM-DD'),
      endDate: Time.fromDate(cart.checkOut).format('YYYY-MM-DD'),
      roomTypeId: roomStay.room.id,
      ratePlanId: roomStay.rate.id,
      coupon: getPromotionalCouponName(roomStay),
      groupCode: roomStay.groupCode,
      occupancy: {
        adults: roomStay.occupancy.adults,
        children: roomStay.occupancy.children,
        childrenAges: roomStay.occupancy.childrenAges,
      },
      extras: roomStay.extras.map(extra => ({
        id: extra.id,
        ...getExtraQuantities(extra),
      })),
      guest: roomStay.guest,
      ...(hasOriginalPrice && {
        originalTotalPrice: {
          base: originalTotalPrice?.base,
          total: originalTotalPrice?.total,
        },
      }),
    }

    return roomStayCriteria
  }),
})
