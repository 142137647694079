import { AnalyticsActionsBeforePaymentRedirection } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'

/**
 * Se lanza justo antes de redirigir a la pasarela de pago, tras la respuesta
 * correcta de payment dándonos la URL
 */
export const beforePaymentRedirection: AnalyticsActionsBeforePaymentRedirection =
  ({ paymentMethod }) => {
    const linkParams = {
      ga4_event_name: 'payment details process finished',
      event: 'payment details process finished',
      event_category: 'conversion',
      event_action: 'payment_details_CBE',
      event_label: 'confirm_button_redirect',
      booking_payment_method: paymentMethod.name,
    }
    container
      .resolve('logger')
      .info(
        'Analytics/links/actions/choosePayment/beforePaymentRedirection',
        linkParams,
      )
    window.utag.link(linkParams)
  }
