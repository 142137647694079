import { AnalyticsActionsSharedChangeGuests } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'

export const changeGuests: AnalyticsActionsSharedChangeGuests = guests => {
  const totalAdults = sum(guests.adults)
  const totalChildren = sum(guests.children)

  const linkParams = {
    ga4_event_name: 'cta',
    event: 'cta',
    event_category: 'Booking Process',
    event_action: 'Room Selection',
    event_label: 'Guest Number Change',
    booking_total_guests: totalAdults + totalChildren,
    booking_adults: guests.adults,
    booking_children: guests.children,
    booking_children_age: guests.childrenAges,
  }
  container
    .resolve('logger')
    .info('Analytics/links/actions/shared/changeGuests', {
      linkParams,
      utag_data: window.utag_data,
    })
  window.utag.link(linkParams)
}

const sum = (guests: number[]): number => {
  return guests.reduce((acc, el) => acc + el, 0)
}
