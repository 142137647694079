import { Button, Modal } from 'src/ui/components'
import { Text } from 'src/ui/components/atoms/Text'
import { createModal } from 'src/ui/hooks/useModal'
import { useTrans } from 'src/ui/hooks/useTrans'

interface Props {
  isMultiroom: boolean
  onRetrySearch: () => void
  onClose: () => void
}

export const QuotaErrorModal = createModal(
  ({ isMultiroom, onRetrySearch, onClose }: Props) => {
    const { trans } = useTrans(['new-reservation'])

    return (
      <Modal
        size="m"
        type="passive"
        onClose={onClose}
        data-quantum-modal="QuotaErrorModal"
      >
        <Text
          as="h2"
          fontStyle={{ mobile: 'xl-700', laptop: '2xl-700' }}
          color="dark"
          centered
          className="mb-l"
        >
          {isMultiroom
            ? trans('quota-error-modal_multiple-rooms_no-disponibility')
            : trans('quota-error-modal_single-room_no-disponibility')}
        </Text>
        <Text fontStyle="m-300" className="mb-xl" as="p" centered>
          {!isMultiroom &&
            trans('quota-error-modal_single-room_error-message') + ' '}
          {trans('quota-error-modal_general_error-message')}
        </Text>
        <Button variant="primary" type="button" onClick={onRetrySearch}>
          {trans('quota-error-modal_general_repeat-search')}
        </Button>
      </Modal>
    )
  },
)
