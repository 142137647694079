import { AnalyticsErrorTracker } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'

export const availabilityError: AnalyticsErrorTracker = error => {
  const linkParams = {
    booking_availability: '0',
    availability_link: false,

    ga4_event_name: 'error',
    event: 'error',
    event_category: 'error',
    event_action: 'booking engine error',
    event_label: `Availability - ${error.status} ${error.code}`,
  }
  container.resolve('logger').info('Analytics/links/errors/availability', {
    linkParams,
    utag_data: window.utag_data,
  })
  window.utag.link(linkParams)
}
