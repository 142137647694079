export class AvailabilityError extends Error {
  public typeName: string

  constructor(
    public type: AvailabilityErrorType,
    public message: string,
    public originalError: object | undefined,
  ) {
    super(`Availability: ${message} (${type.toString()})`)

    this.typeName = this.getTypeName(type)
  }

  static isHotelAvailabilityNotFoundError(error: Error): boolean {
    return (
      error instanceof AvailabilityError &&
      error.type === AvailabilityErrorType.HOTEL_AVAILABILITY_NOT_FOUND_ERROR
    )
  }

  private getTypeName(type: AvailabilityErrorType) {
    const mapTypeName: { [key in AvailabilityErrorType]: string } = {
      [AvailabilityErrorType.AVAILABILITY_SERVICE_BAD_REQUEST_ERROR]:
        'AVAILABILITY_SERVICE_BAD_REQUEST_ERROR',
      [AvailabilityErrorType.AVAILABILITY_SERVICE_UNPROCESSABLE_ENTITY_ERROR]:
        'AVAILABILITY_SERVICE_UNPROCESSABLE_ENTITY_ERROR',
      [AvailabilityErrorType.AVAILABILITY_SERVICE_UNAVAILABLE_ERROR]:
        'AVAILABILITY_SERVICE_UNAVAILABLE_ERROR',
      [AvailabilityErrorType.HOTEL_AVAILABILITY_NOT_FOUND_ERROR]:
        'HOTEL_AVAILABILITY_NOT_FOUND_ERROR',
      [AvailabilityErrorType.BAD_REQUEST_ERROR]: 'BAD_REQUEST_ERROR',
      [AvailabilityErrorType.INVALID_HOTEL_ERROR]: 'INVALID_HOTEL_ERROR',
      [AvailabilityErrorType.CURRENCY_CONVERSION_ERROR]:
        'CURRENCY_CONVERSION_ERROR',
      [AvailabilityErrorType.UNEXPECTED_ERROR]: 'UNEXPECTED_ERROR',
    }

    return mapTypeName[type]
  }
}

export enum AvailabilityErrorType {
  HOTEL_AVAILABILITY_NOT_FOUND_ERROR = 5,
  UNEXPECTED_ERROR = 6,
  BAD_REQUEST_ERROR = 7,
  AVAILABILITY_SERVICE_UNAVAILABLE_ERROR = 9,
  AVAILABILITY_SERVICE_UNPROCESSABLE_ENTITY_ERROR = 10,
  AVAILABILITY_SERVICE_BAD_REQUEST_ERROR = 11,
  CURRENCY_CONVERSION_ERROR = 14,
  INVALID_HOTEL_ERROR = 16,
}
