export type Routes = (typeof routes)[keyof typeof routes]

export const routes = {
  availability: '/',
  extras: '/extras',
  personalData: '/personal-data',
  choosePayment: '/choose-payment',
  bookingConfirmation: '/booking-confirmation',
  ingenicoCallback: '/ingenico-callback',
  notFound: '/404',
  management: '/management',
}
