import { FC } from 'react'
import { Text } from 'src/ui/components/atoms/Text'
import { Flex } from 'src/ui/styles/objects/Flex'
import styles from './NotificationContent.module.scss'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { NotificationTypes } from '../NotificationTypes.model'
import { WarningFilledIcon } from 'src/ui/components/atoms/Icon/_icons/system/alert/WarningFilledIcon'

import SuccessIconNotification from 'src/ui/components/atoms/Icon/_icons/notifications/SuccessIconNotification'
import InfoIconNotification from 'src/ui/components/atoms/Icon/_icons/notifications/InfoIconNotification'
import ErrorIconNotification from 'src/ui/components/atoms/Icon/_icons/notifications/ErrorIconNotification'

interface Props {
  title: string
  type: NotificationTypes
  description?: string
}

export const NotificationContent: FC<Props> = ({
  title,
  description,
  type,
}) => {
  const iconsByType = {
    success: SuccessIconNotification,
    error: ErrorIconNotification,
    warning: WarningFilledIcon,
    info: InfoIconNotification,
  }

  return (
    <section className={styles.notificationContent}>
      <Flex direction="row" gap="s" alignItems="flex-start">
        {type && <Icon size="l" color="icon-light" icon={iconsByType[type]} />}
        <Flex direction="column" alignItems="flex-start">
          <Text fontStyle="m-500" color="light">
            {title}
          </Text>
          {description && (
            <Text fontStyle="s-300" color="light">
              {description}
            </Text>
          )}
        </Flex>
      </Flex>
    </section>
  )
}
