import { CurrencyISOCode } from 'src/core/Shared/domain/Price.model'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import {
  Reservation,
  getPromotionalCouponInReservation,
  getSelectedRatesType,
  transformToMemberRate,
} from 'src/core/Reservation/domain/Reservation.model'
import {
  CustomEvent,
  EventsManager,
} from 'src/core/Shared/infrastructure/eventsManager'
import { ReservationRepository } from 'src/core/Reservation/domain/Reservation.repository'
import { ReservationStorageRepository } from 'src/core/Reservation/infrastructure/Reservation.storage.repository'
import { Analytics } from 'src/core/Shared/domain/Analytics'
import { RatePairStorageRepository } from 'src/core/Shared/infrastructure/RatePair.repository'
import { ReservationCriteria } from 'src/core/Reservation/domain/ReservationCriteria'
import { isStaffOrFriendsCode } from 'src/core/Hotel/domain/CouponValidation.model'

export const transformToMemberReservationAfterLogin =
  ({
    reservationRepository,
    reservationStorageRepository,
    ratePairStorageRepository,
    eventsManager,
    analytics,
  }: Dependencies) =>
  async (
    itineraryNumber: string,
    token: string,
    currency: CurrencyISOCode,
    onTransformingRates: () => void,
  ) => {
    const ratePairs = ratePairStorageRepository.get()

    if (isUndefined(ratePairs)) {
      return
    }

    const reservationFields =
      reservationStorageRepository.getReservationFields(itineraryNumber)

    const currentReservation =
      await reservationRepository.getByIdAndItineraryNumber(
        reservationFields.reservationId,
        reservationFields.itineraryNumber,
        undefined,
        currency,
        { getConverted: true },
      )

    if (currentReservation.status !== 'Pending') {
      return
    }

    onTransformingRates()

    const selectedRatesType = getSelectedRatesType(ratePairs)

    const hasToTransformToMemberRate =
      selectedRatesType === 'allStandard' &&
      !shouldCouponAvoidTransformation(currentReservation)

    const reservation = hasToTransformToMemberRate
      ? transformToMemberRate(ratePairs, currentReservation)
      : currentReservation

    const reservationCriteria = ReservationCriteria.fromReservation(
      reservation,
      { asUser: true },
    )

    const newReservation = await reservationRepository.create(
      reservationCriteria,
      token,
      currency,
      { getConverted: true },
    )

    reservationStorageRepository.setReservationFields(
      newReservation.id,
      newReservation.itineraryNumber,
    )

    eventsManager.emit(
      CustomEvent.UPDATED_PRE_RESERVE_FROM_RESERVE,
      newReservation,
    )

    analytics.requests.preReservationFromLogin(newReservation)

    return newReservation?.itineraryNumber
  }

interface Dependencies {
  reservationRepository: ReservationRepository
  reservationStorageRepository: ReservationStorageRepository
  ratePairStorageRepository: RatePairStorageRepository
  eventsManager: EventsManager
  analytics: Analytics
}

/**
 * Solución temporal para evitar la transformación de tarifas para los cupones
 * de STAFF y FRIENDS del año 2024
 */
function shouldCouponAvoidTransformation(reservation: Reservation) {
  const currentCoupon = getPromotionalCouponInReservation(reservation.roomStays)
  if (!isDefined(currentCoupon) || !currentCoupon.applies) {
    return false
  }
  return isStaffOrFriendsCode(currentCoupon.value)
}
