import axios, { AxiosInstance } from 'axios'
import { isDefined } from './wrappers/javascriptUtils'
import { getApiAcceptLanguage } from './locales/i18nUtils'
import { BasicApiClient } from 'src/core/Shared/infrastructure/basicApiClient'

export const userConfigApiClientAxiosInstance = axios.create({
  baseURL: '/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

userConfigApiClientAxiosInstance.interceptors.request.use(config => {
  if (isDefined(config.headers)) {
    config.headers['Accept-Language'] = getApiAcceptLanguage()
  }
  return config
})

export class UserConfigApiClient extends BasicApiClient {
  constructor({
    userConfigApiClientAxiosInstance,
  }: {
    userConfigApiClientAxiosInstance: AxiosInstance
  }) {
    super(userConfigApiClientAxiosInstance)
  }
}
