import {
  isEmpty,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import {
  HOTEL_ROOM_DEFAULTS,
  HotelRooms,
} from 'src/core/Hotel/domain/Hotel.model'
import { HotelRoomDTO, HotelRoomServiceDetailDTO } from './Hotel.api.dto'
import { mapImageWithDomain } from './mapImageWithDomain'

export const mapRooms = (roomsDTO: HotelRoomDTO[]) => {
  return roomsDTO.reduce(
    (rooms, roomDTO) => {
      const {
        room_code: roomCode,
        room_images_dm: roomImages,
        serviceList_details: roomServicesDetails,
        details_description: roomDescription,
        details_detailedDescription: roomDetailedDescription,
        room_size: roomSizeValue,
        guestLimit_total: roomGuests,
        guestLimit_adults: roomMaxAdults,
        guestLimit_children: roomMaxChildren,
      } = roomDTO

      return {
        ...rooms,
        [roomCode]: {
          description: roomDescription,
          detailedDescription: roomDetailedDescription,
          guests: getRoomGuests(roomGuests),
          maxAdults: getRoomMaxAdults(roomMaxAdults),
          maxChildren: getRoomMaxChildren(roomMaxChildren),
          size: getRoomSize(roomSizeValue),
          images: getRoomImages(roomImages),
          services: getRoomServices(roomServicesDetails),
        },
      }
    },
    {
      [HOTEL_ROOM_DEFAULTS().ID]: {
        guests: HOTEL_ROOM_DEFAULTS().GUESTS,
        maxAdults: HOTEL_ROOM_DEFAULTS().MAXADULTS,
        maxChildren: HOTEL_ROOM_DEFAULTS().MAXCHILDREN,
        images: HOTEL_ROOM_DEFAULTS().IMAGES,
        services: HOTEL_ROOM_DEFAULTS().SERVICES,
      },
    } as HotelRooms,
  )
}

const getRoomGuests = (roomGuests: number | undefined): number => {
  if (isUndefined(roomGuests)) {
    return HOTEL_ROOM_DEFAULTS().GUESTS
  }

  return roomGuests
}

const getRoomMaxAdults = (roomMaxAdults: number | undefined): number => {
  if (isUndefined(roomMaxAdults)) {
    return HOTEL_ROOM_DEFAULTS().MAXADULTS
  }

  return roomMaxAdults
}

const getRoomMaxChildren = (roomMaxChildren: number | undefined): number => {
  if (isUndefined(roomMaxChildren)) {
    return HOTEL_ROOM_DEFAULTS().MAXCHILDREN
  }

  return roomMaxChildren
}

const getRoomSize = (roomSizeValue: number | undefined) => {
  if (isUndefined(roomSizeValue) || roomSizeValue === 0) {
    return undefined
  }

  return {
    value: roomSizeValue,
  }
}

const getRoomImages = (roomImages: string[] | undefined) => {
  if (isUndefined(roomImages) || isEmpty(roomImages)) {
    return HOTEL_ROOM_DEFAULTS().IMAGES
  }

  return roomImages.map(image => {
    return mapImageWithDomain(image, HOTEL_ROOM_DEFAULTS().FALLBACK_IMAGE)
  })
}

const getRoomServices = (roomServicesDetail: HotelRoomServiceDetailDTO[]) => {
  if (isUndefined(roomServicesDetail)) {
    return HOTEL_ROOM_DEFAULTS().SERVICES
  }

  return roomServicesDetail.map(roomService => {
    return {
      id: roomService.service_name,
      text: roomService.service_description,
      icon: roomService.service_name,
    }
  })
}
