import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'node:querystring'
import { useCallback, useMemo } from 'react'
import {
  ApplicationNavigator,
  buildNavigator,
} from 'src/ui/hooks/useApplicationRouter/buildNavigator'
import {
  buildQueryUtils,
  QueryUtils,
} from 'src/ui/hooks/useApplicationRouter/buildQueryUtils'
import {
  isDefined,
  isEmpty,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { container } from 'src/core/Shared/_di'
import { transformTextToValidCoupon } from 'src/core/Hotel/domain/CouponValidation.model'
import {
  transformTextToChildAges,
  transformTextToValidChildren,
} from 'src/core/Availability/domain/Availability.model'
import { QueryParamKey } from './useApplicationRouter.model'
import { QueryParams } from './useApplicationRouter.model'

export interface ApplicationRouter {
  navigate: ApplicationNavigator
  queryUtils: QueryUtils
  getRawParams: (params: QueryParamKey[]) => QueryParams | undefined
  pathname: string
  query: ParsedUrlQuery
  asPath: string
  isRouterReady: boolean
  reload: () => void
  route: string
  registerPreviousRoutes: () => void
  currentView: string
  back: () => void
}

export const useApplicationRouter = (): ApplicationRouter => {
  const router = useRouter()

  const registerPreviousRoutes = () => {
    router.events?.on('routeChangeStart', () => {
      container
        .resolve('sessionStorageClient')
        .set('previousRoute', router.asPath)
    })
  }

  const getRawParams = useCallback(
    (params: QueryParamKey[] = []): QueryParams | undefined => {
      const { query } = router

      if (isUndefined(query) || isEmpty(query)) {
        return undefined
      }

      return params.reduce(
        (prev, param) => ({
          ...prev,
          [param]: normalizeQueryParam(param, query[param], query),
        }),
        {},
      )
    },
    [router],
  )

  const queryUtils = useMemo(
    () => buildQueryUtils(router, getRawParams),
    [router, getRawParams],
  )

  const navigate = useMemo(
    () => buildNavigator(router, queryUtils),
    [router, queryUtils],
  )

  return {
    navigate,
    queryUtils,
    getRawParams,
    pathname: router.pathname,
    query: router.query,
    asPath: router.asPath,
    isRouterReady: router.isReady,
    reload: router.reload,
    route: router.route,
    registerPreviousRoutes,
    currentView: getView(router.pathname),
    back: router.back,
  }
}

const getView = (pathname: string): string => {
  const pathSegments = pathname.split('/', 2)
  if (pathSegments.length <= 1) return ''
  return pathSegments[1]
}

const paramNormalizers: Record<
  string,
  (value: string, query: ParsedUrlQuery) => string
> = {
  coupon: value => transformTextToValidCoupon(value).validCoupon,
  groupcode: value => transformTextToValidCoupon(value).validCoupon,
  child: value => transformTextToValidChildren(value).validChildren,
  childages: (value, query) =>
    transformTextToChildAges(value, query['child'] as string | undefined)
      .validChildAges,
}

const normalizeQueryParam = (
  param: string,
  value: string | string[] | undefined,
  query: ParsedUrlQuery,
) => {
  if (isUndefined(value)) {
    return
  }

  if (Array.isArray(value)) {
    container.resolve('logger').warning(`Query param is an array: ${param}`)
    return normalizeTextValue(param, value[0], query)
  }

  return normalizeTextValue(param, value, query)
}

function normalizeTextValue(
  param: string,
  value: string,
  query: ParsedUrlQuery,
) {
  let normalizedValue = value
    .trim()
    .replace(/%E2%80%8B/g, '')
    .replaceAll('%7C', '|')

  if (isDefined(paramNormalizers[param])) {
    normalizedValue = paramNormalizers[param](normalizedValue, query)
  }
  return normalizedValue
}
