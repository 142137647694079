import {
  isEmpty,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export const mapImage = (image: string | undefined, defaultImage?: string) => {
  if (isUndefined(image) || isEmpty(image)) {
    if (isUndefined(defaultImage)) {
      return ''
    }
    return defaultImage
  }
  return image.split('/').reverse()[0].split('.')[0]
}
