import { CallCenter, Country } from 'src/core/User/domain/Country.model'
import { WithInjectedParams } from 'src/core/Shared/_di/types'
import { HttpClient } from 'src/core/Shared/infrastructure/httpClient'
import { CountryRepository } from 'src/core/User/domain/Country.repository'

interface RepositoryDependencies {
  userConfigApiClient: HttpClient
}

export const apiCountryRepository: WithInjectedParams<
  CountryRepository,
  RepositoryDependencies
> = ({ userConfigApiClient }) => ({
  getCountry: async (countryCode: string) => {
    const { data: countriesList } = await userConfigApiClient.get<
      Record<string, Country['callCenter']>
    >('countries.json')

    return mapCountry(countryCode, countriesList)
  },
})

const mapCountry = (
  countryCode: string,
  countriesList: Record<string, CallCenter>,
): Country => {
  return {
    code: countryCode.toUpperCase(),
    callCenter: countriesList[countryCode.toUpperCase()] ?? countriesList.NA,
  }
}
