import { AnalyticsRequestReservation } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'
import { buildUtagDataForReservation } from './preReservation'

export const preReservationFromLogin: AnalyticsRequestReservation =
  preReservation => {
    const linkParams = {
      ga4_event_name: 'login',
      event: 'login',
      event_category: 'Booking Process',
      event_action: 'Payment Details',
      event_label: 'myBarcelo login',

      availability_link: false,
      ...buildUtagDataForReservation(preReservation),
    }
    container
      .resolve('logger')
      .info('Analytics/links/requests/preReservationFromLogin', {
        linkParams,
        utag_data: window.utag_data,
      })
    window.utag.link(linkParams)
  }
