import { Time } from 'src/core/Shared/infrastructure/Time'
import { CampaignDTO } from 'src/core/Hotel/infrastructure/Campaign.api.dto'
import { mapImage } from './mapImage'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export const mapCampaign = (
  campaignDTO: CampaignDTO,
  promotionalCoupon: string,
) => {
  const campaign = campaignDTO.campaigns.find(campaign => {
    /* TODO: A falta de que BE cambie la propiedad campaing_cupon por campaign_coupon y eliminar campaing_cupon */
    return campaign.promotions.some(
      promotion =>
        promotion.campaign_coupon === promotionalCoupon ||
        promotion.campaing_cupon === promotionalCoupon,
    )
  })

  if (!isDefined(campaign)) {
    return
  }

  const featuredArguments = [
    campaign.promotions[0].argumentPolicy1?.argument_description,
    campaign.promotions[0].argumentPolicy2?.argument_description,
    campaign.promotions[0].argumentPolicy3?.argument_description,
  ]

  return {
    title: campaign.title,
    bookingStart: Time.fromUTCString(
      campaign.promotions[0].booking_start,
    ).toDate(),
    bookingEnd: Time.fromUTCString(campaign.promotions[0].booking_end).toDate(),
    featuredArguments,
    head: campaign.head,
    travelStart: Time.fromUTCString(
      campaign.promotions[0].travel_start,
    ).toDate(),
    travelEnd: Time.fromUTCString(campaign.promotions[0].travel_end).toDate(),
    previewImage: mapImage(campaign.preview_image),
    discount: campaign.promotions[0].value_rate,
  }
}
