import {
  FocusEvent,
  FocusEventHandler,
  FormEventHandler,
  forwardRef,
  ForwardRefRenderFunction,
  MouseEventHandler,
} from 'react'
import { Text } from 'src/ui/components/atoms/Text'
import styles from './TextField.module.scss'
import { classNames } from 'src/ui/utils/classnames'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { isEmpty } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { CloseIcon } from 'src/ui/components/atoms/Icon/_icons/interaction/CloseIcon'

interface Props {
  label?: string
  hint?: string
  type?: 'text' | 'email'
  autoFocus?: boolean
  error?: { message?: string }
  'data-testid'?: string
  ariaLabel?: string
  className?: string
  inputClassName?: string
  clearIconClassName?: string
  onClearInput?: () => void
  onChange?: FormEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  name: string
  min?: string | number
  max?: string | number
  maxLength?: number
  minLength?: number
  pattern?: string
  required?: boolean
  disabled?: boolean
  value?: string
  clearIconAriaLabel?: string
  placeholder?: string
}

const TextFieldWithRef: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    label = '',
    hint,
    type = 'text',
    autoFocus = false,
    error,
    'data-testid': testId,
    ariaLabel,
    className,
    inputClassName,
    clearIconClassName,
    onClearInput,
    onChange,
    onBlur,
    onFocus,
    name,
    min,
    max,
    maxLength,
    minLength,
    pattern,
    required,
    disabled = false,
    value,
    clearIconAriaLabel,
    ...props
  },
  ref,
) => {
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    onBlur?.(e)
  }

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    onFocus?.(e)
  }

  const onMouseDown: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault()
  }

  const showHelperText = !disabled && Boolean(error)
  const showClearIcon = !disabled && !isEmpty(value)

  return (
    <div className={classNames(className, styles.container)}>
      <label htmlFor={name}>
        <Text fontStyle="s-500" color={disabled ? 'disabled' : 'dark'}>
          {label}
        </Text>
        {required && (
          <Text fontStyle="s-700" color="support-error">
            <i aria-hidden="true"> *</i>
          </Text>
        )}
      </label>
      <input
        {...props}
        aria-label={ariaLabel}
        data-testid={testId}
        id={name}
        className={classNames(
          inputClassName,
          styles.input,
          error && styles.inputError,
        )}
        type={type}
        onFocus={handleFocus}
        autoFocus={autoFocus}
        onChange={onChange}
        onBlur={handleBlur}
        ref={ref}
        name={name}
        min={min}
        max={max}
        maxLength={maxLength}
        minLength={minLength}
        pattern={pattern}
        required={required}
        disabled={disabled}
        value={value}
      />

      {showHelperText && (
        <Text fontStyle="s-300" color="support-error">
          {error?.message}
        </Text>
      )}
      <Text fontStyle="s-300" color="mid">
        {hint}
      </Text>
      {showClearIcon && (
        <button
          type="button"
          className={classNames(clearIconClassName, styles.iconWrapper)}
          onMouseDown={onMouseDown}
          onClick={onClearInput}
          aria-label={clearIconAriaLabel}
          tabIndex={-1}
        >
          <Icon size="l" color="interactive-primary-active" icon={CloseIcon} />
        </button>
      )}
    </div>
  )
}

export const TextField = forwardRef(TextFieldWithRef)
