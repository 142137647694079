import { container } from 'src/core/Shared/_di'
import { useUser } from 'src/ui/contexts/UserContext'

export const useHandleUnauthorizedError = () => {
  const { token } = useUser()

  const handleUnauthorizedError = () => {
    container.resolve('logout')(token)
    container.resolve('cartRepository').deleteCart()
  }

  return {
    handleUnauthorizedError,
  }
}
