const HttpApi = require('i18next-http-backend').default
const {
  getAvailableLanguages,
  crowdinConfig,
  defaultLanguage,
} = require('./i18n-config')

const isBrowser = typeof window !== 'undefined'

module.exports = {
  backend: isBrowser ? crowdinConfig() : undefined,
  i18n: {
    defaultLocale: defaultLanguage,
    locales: getAvailableLanguages(),
  },
  react: {
    useSuspense: false,
  },
  serializeConfig: false,
  use: isBrowser ? [HttpApi] : [],
}
