import { container } from 'src/core/Shared/_di'
import { Price } from 'src/core/Shared/domain/Price.model'

export interface Hotel {
  id: string
  guestOptions: {
    maxGuests: number
    maxAdults: number
    maxChildren: number
    maxChildAge: number
  }
  name: string
  image: string
  destination: string
  reviews: {
    averageRate?: string
  }
  contact: {
    phone?: string
    email?: string
  }
  rooms: HotelRooms
  isAdultsOnly: boolean
  highlights: string[] | undefined
  cityTax?: CityTax
}

export type HotelRooms = Record<HotelRoomId, HotelRoom>

type HotelRoomId = string

export interface HotelRoom {
  description?: string
  detailedDescription?: string
  guests: number
  maxAdults: number
  maxChildren: number
  size?: {
    value: number
  }
  images: string[]
  services: Array<{
    id: string
    text: string
    icon: string
  }>
}

export interface HotelRoomSummary {
  id: string
  name: string
}

export interface NearbyHotel {
  id: string
  bestPriceAverage: { netPrice: Price; grossPrice: Price }
}

export interface CheckInCheckOutTime {
  checkInTime: string
  checkOutTime: string
}

export type CityTaxIndicator =
  | 'RPN'
  | 'PPN'
  | 'RPS'
  | 'PPS'
  | 'P3PN'
  | 'P3PS'
  | 'EXC'

export interface CityTax {
  indicator: CityTaxIndicator
  value: string
}

const getAdobePath = () => container.resolve('envManager').getBaseImagesUrl()

export const HOTEL_ROOM_DEFAULTS = () => ({
  ID: 'DEFAULT_ROOM',
  GUESTS: 0,
  MAXADULTS: 0,
  MAXCHILDREN: 0,
  SERVICES: [],
  IMAGES: [`${getAdobePath()}default-room`],
  FALLBACK_IMAGE: 'default-room',
  SERVICE_SRC: 'default-room-highlights-icon',
})

export const HOTEL_DEFAULTS = () => ({
  IMAGE: `${getAdobePath()}default-hotel`,
  FALLBACK_IMAGE: 'default-hotel',
})

export const getRoomInfoFromHotel = (
  room: HotelRoomSummary,
  hotel: Hotel,
): HotelRoom => {
  return hotel.rooms[room.id] ?? hotel.rooms[HOTEL_ROOM_DEFAULTS().ID]
}
