import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { IsCurrencyValid } from 'src/ui/contexts/CurrencyContext'
import { checkForRequiredParamsErrors } from './checkForRequiredParamsErrors'
import { RequiredParams } from './useApplicationRouter.model'
import { QueryParams } from './useApplicationRouter.model'
import { QueryParamKey } from './useApplicationRouter.model'

export const getAvailabilityCriteriaParams =
  (getRawParams: (params: QueryParamKey[]) => QueryParams | undefined) =>
  (isContextCurrencyValid: IsCurrencyValid) => {
    const queryParams = getRawParams([
      'marketprice',
      'hotel',
      'arrive',
      'depart',
      'adult',
      'child',
      'childages',
      'rooms',
      'coupon',
      'currency',
      'groupcode',
    ])

    if (isUndefined(queryParams)) {
      return {
        data: undefined,
      }
    }

    const errors = checkForRequiredParamsErrors(
      queryParams,
      isContextCurrencyValid,
    )
    if (isDefined(errors)) {
      return {
        errors,
        data: undefined,
      }
    }

    return {
      errors,
      data: {
        market: queryParams.marketprice,
        hotel: queryParams.hotel,
        checkIn: queryParams.arrive,
        checkOut: queryParams.depart,
        adults: queryParams.adult,
        currency: queryParams.currency,
        coupon: queryParams.coupon,
        groupCode: queryParams.groupcode,
        children: queryParams.child,
        childrenAges: queryParams.childages,
        rooms: queryParams.rooms,
      } as RequiredParams,
    }
  }
