export class ReservationError extends Error {
  constructor(public message: string, public type: ReservationErrorType) {
    super(`Reservation: ${message} (${type.toString()})`)
  }

  static isNoQuota(error: unknown): boolean {
    return (
      error instanceof ReservationError &&
      error.type === ReservationErrorType.NO_QUOTA
    )
  }

  static isExpired(error: unknown): boolean {
    return (
      error instanceof ReservationError &&
      error.type === ReservationErrorType.EXPIRED
    )
  }

  static isNonExistent(error: unknown): boolean {
    return (
      error instanceof ReservationError &&
      error.type === ReservationErrorType.NON_EXISTENT
    )
  }
}

export enum ReservationErrorType {
  NON_EXISTENT = 1,
  EXPIRED = 29,
  NO_QUOTA = 31,
}
