import { QueryParamValue } from './useApplicationRouter.model'
import { QueryParamsErrors } from './useApplicationRouter.model'
import { QueryParams } from './useApplicationRouter.model'
import { IsCurrencyValid } from 'src/ui/contexts/CurrencyContext'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'
import {
  isDefined,
  isUndefined,
  isEmpty,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { container } from 'src/core/Shared/_di'

export const checkForRequiredParamsErrors = (
  params: QueryParams,
  isContextCurrencyValid: IsCurrencyValid,
): QueryParamsErrors | undefined => {
  const hasNoParams = (Object.keys(params) as (keyof QueryParams)[]).every(
    paramKey => isUndefined(params[paramKey]),
  )
  if (hasNoParams) {
    return {
      hasDatesError: true,
      hasGuestError: true,
      hasMarketPriceError: true,
      hasHotelError: true,
      hasCurrencyError: true,
    }
  }

  const validDates = areDatesValid(params)
  const validGuests = areGuestsValid(params)
  const validMarketprice = isMarketpriceValid(params)
  const validCurrency = isCurrencyValid(params, isContextCurrencyValid)
  const validHotel = isHotelValid(params)

  if (
    validDates &&
    validGuests &&
    validMarketprice &&
    validHotel &&
    validCurrency
  ) {
    return undefined
  }

  return {
    hasDatesError: !validDates,
    hasGuestError: !validGuests,
    hasMarketPriceError: !validMarketprice,
    hasHotelError: !validHotel,
    hasCurrencyError: !validCurrency,
  }
}

const areDatesValid = (params: QueryParams): boolean => {
  const { arrive: checkInParam, depart: checkOutParam } = params

  if (!isSetCheckIn(checkInParam) || !isSetCheckOut(checkOutParam)) {
    return false
  }

  return datesManager.areCheckInAndCheckOutValid(checkInParam, checkOutParam)
}

const isSetCheckIn = (checkIn: QueryParamValue): checkIn is string =>
  isString(checkIn)
const isSetCheckOut = (checkOut: QueryParamValue): checkOut is string =>
  isString(checkOut)

const areGuestsValid = (params: QueryParams): boolean => {
  const {
    rooms: roomsParam,
    adult: adultParam,
    child: childParam,
    childages: childAgesParam,
  } = params

  if (!isRoomsParamValid(roomsParam)) {
    container
      .resolve('logger')
      .error(new Error('Query params - guests are invalid: rooms is invalid'))
    return false
  }

  if (!isSetAdult(adultParam)) {
    container
      .resolve('logger')
      .error(new Error('Query params - guests are invalid: adult not set'))
    return false
  }

  const rooms = isDefined(roomsParam) ? parseInt(roomsParam) : 1
  const adults = adultParam.split(',')
  const children = isSetChild(childParam) ? childParam.split(',') : undefined
  const childrenAges = isSetChildAges(childAgesParam)
    ? childAgesParam.split(',').map(age => age.split('|'))
    : undefined

  return (
    isDistributionValid({
      rooms,
      adults,
      children,
      childrenAges,
    }) &&
    areGuestsParamsValid({
      adults,
      children,
      childrenAges,
    })
  )
}

const isRoomsParamValid = (rooms: QueryParamValue): rooms is string =>
  isUndefined(rooms) ||
  (isString(rooms) && isPositiveNumber(rooms) && parseInt(rooms) > 0)

const isDistributionValid = ({
  rooms,
  adults,
  children,
  childrenAges,
}: {
  rooms: number
  adults: string[]
  children: string[] | undefined
  childrenAges: Array<string[]> | undefined
}): boolean => {
  const numberOfAdults = adults.length
  if (numberOfAdults !== rooms) {
    container
      .resolve('logger')
      .error(
        new Error(
          'Query params - guests are invalid: number of adults different from number of rooms',
        ),
      )
    return false
  }

  const numberOfChildren = children?.length
  if (isDefined(numberOfChildren) && numberOfChildren !== rooms) {
    return false
  }

  const numberOfChildAges = childrenAges?.length

  if (isDefined(numberOfChildAges) && numberOfChildAges !== rooms) {
    container
      .resolve('logger')
      .error(
        new Error('Query params - guests are invalid: distribution is invalid'),
      )
  }

  return !(isDefined(numberOfChildAges) && numberOfChildAges !== rooms)
}

const areGuestsParamsValid = ({
  adults,
  children,
  childrenAges,
}: {
  adults: string[]
  children: string[] | undefined
  childrenAges: Array<string[]> | undefined
}): boolean => {
  if (!areAdultsValid(adults)) {
    container
      .resolve('logger')
      .error(new Error('Query params - guests are invalid: adult invalid'))
    return false
  }

  if (!areChildrenValid(children)) {
    container
      .resolve('logger')
      .error(new Error('Query params - guests are invalid: children invalid'))
    return false
  }

  if (isDefined(children) && isUndefined(childrenAges)) {
    const hasNoChildren = children.every(child => child === '0')
    if (!hasNoChildren) {
      container
        .resolve('logger')
        .error(
          new Error('Query params - guests are invalid: children ages not set'),
        )
    }
    return hasNoChildren
  }

  if (isDefined(children) && isDefined(childrenAges)) {
    const sameAgesThanChildren = children.reduce(
      (prev, childrenCount, index) => {
        const numOfChildren = parseInt(childrenCount)
        const numOfAges = childrenAges[index].length
        const validRoomWithoutChildren =
          numOfChildren === 0 &&
          numOfAges === 1 &&
          childrenAges[index][0] === ''

        return prev && (numOfChildren === numOfAges || validRoomWithoutChildren)
      },
      true,
    )

    if (!sameAgesThanChildren) {
      container
        .resolve('logger')
        .error(
          new Error(
            'Query params - guests are invalid: different number of children and children ages',
          ),
        )
      return false
    }

    if (!areChildrenAgesValid(childrenAges)) {
      container
        .resolve('logger')
        .error(
          new Error('Query params - guests are invalid: children ages invalid'),
        )
      return false
    }
  }

  return true
}

const isPositiveNumber = (word: string) => /^\d+$/.test(word)

const isSetAdult = (adult: QueryParamValue): adult is string => isString(adult)

const areAdultsValid = (adults: string[]) => adults.every(isAdultNumberValid)

const isAdultNumberValid = (adult: string) =>
  isPositiveNumber(adult) && parseInt(adult) > 0

const isSetChild = (child: QueryParamValue): child is string => isString(child)

const areChildrenValid = (children: string[] | undefined) =>
  isUndefined(children) || children.every(isChildParamValid)

const isChildParamValid = isPositiveNumber

const isSetChildAges = (childages: QueryParamValue): childages is string =>
  isString(childages)

const areChildrenAgesValid = (childrenAges: Array<string[]> | undefined) =>
  isUndefined(childrenAges) || childrenAges.every(areChildAgesParamValid)

const areChildAgesParamValid = (ages: string[]) =>
  ages.every(age => isEmpty(age) || isPositiveNumber(age))

const isMarketpriceValid = (params: QueryParams): boolean => {
  const { marketprice: marketpriceParam } = params

  if (!isString(marketpriceParam)) {
    container
      .resolve('logger')
      .error(
        new Error('Query params - marketprice is invalid: marketprice not set'),
      )
  }

  return isString(marketpriceParam)
}

const isCurrencyValid = (
  params: QueryParams,
  isContextCurrencyValid: IsCurrencyValid,
): boolean => {
  const { currency: currencyParam } = params

  if (isString(currencyParam) && !isContextCurrencyValid(currencyParam)) {
    container
      .resolve('logger')
      .error(
        new Error(
          `Query params - currency is invalid: currency is not valid (${currencyParam})`,
        ),
      )
  }

  return isString(currencyParam) && isContextCurrencyValid(currencyParam)
}

const isHotelValid = (params: QueryParams): boolean => {
  const { hotel: hotelParam } = params

  if (!isString(hotelParam)) {
    container
      .resolve('logger')
      .error(new Error('Query params - hotel is invalid: hotel not set'))
  }

  return isString(hotelParam)
}

const isString = (value: QueryParamValue): value is string =>
  typeof value === 'string'
