import { FC } from 'react'

export const ErrorFilledIcon: FC = () => (
  <>
    <rect width="21" height="21" rx="10.5" fill="#E40F13" />
    <path
      d="M10.5 16.125C13.6066 16.125 16.125 13.6066 16.125 10.5C16.125 7.3934 13.6066 4.875 10.5 4.875C7.3934 4.875 4.875 7.3934 4.875 10.5C4.875 13.6066 7.3934 16.125 10.5 16.125Z"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.59375 6.59375L14.4063 14.4063"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default ErrorFilledIcon
