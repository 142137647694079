import { Button, Modal, Text } from 'src/ui/components'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Flex } from 'src/ui/styles/objects/Flex'
import { container } from 'src/core/Shared/_di'
import { createModal, useModal } from 'src/ui/hooks/useModal'

export const ExpiredCartModal = createModal(() => {
  const { hideModal } = useModal()
  const { trans } = useTrans(['common'])

  const resetSearch = async () => {
    container.resolve('deleteCart')()
    hideModal()
  }

  return (
    <Modal
      closable={false}
      size="m"
      type="transactional"
      ariaLabel={trans('expired-cart_error-aria-label')}
      data-quantum-modal="ExpiredCartModal"
    >
      <Flex direction="column" alignItems="center">
        <Text className="mb-m" fontStyle="xl-700" color="dark" as="h1">
          {trans('expired-cart_error-title')}
        </Text>
        <Text as="p" fontStyle="m-300" color="dark" centered>
          {trans('expired-cart_error-subtitle')}
        </Text>
        <Text as="p" className="mb-xl" fontStyle="m-300" color="dark" centered>
          {trans('expired-cart_error-message')}
        </Text>
        <Button onClick={resetSearch} width="fit-content">
          {trans('expired-cart_error-button')}
        </Button>
      </Flex>
    </Modal>
  )
})
