import {
  CancellationPolicy,
  GuaranteePolicy,
  defaultRateCancellationPenalty,
  getDefaultCancellationPenalty,
} from 'src/core/Shared/domain/Policies.model'
import { Price, PriceOptions } from 'src/core/Shared/domain/Price.model'
import { mapPriceDTO } from 'src/core/Shared/infrastructure/mappers/mapPriceDTO'
import { Time } from 'src/core/Shared/infrastructure/Time'
import {
  isDefined,
  isEmpty,
  isUndefined,
  removeHtmlTags,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import {
  getDefaultReservationCancellation,
  MealPlanType,
  ReservationGuest,
  ReservationRoomStay,
} from 'src/core/Reservation/domain/Reservation.model'
import { ReservationCoupon } from '../domain/ReservationCoupon'
import { CouponType } from '../domain/CouponType'
import {
  ReservationCouponDTO,
  ReservationExtraDTO,
  RoomStayDTO,
} from './Reservation.api.dto'
import {
  AppliedExtraPerGuest,
  AppliedExtraPerUnit,
  isExtraPerGuest,
  NotAppliedExtra,
} from 'src/core/Shared/domain/Extra.model'

export const mapRoomStays = (
  roomStays: RoomStayDTO[],
  priceOptions: PriceOptions,
): ReservationRoomStay[] =>
  roomStays.map(stay => {
    const roomStay = {
      id: stay.id,
      confirmationNumber: stay.confirmationNumber,
      status: stay.status,
      startDate: Time.fromString(stay.startDate).toDate(),
      endDate: Time.fromString(stay.endDate).toDate(),
      occupancy: {
        adults: stay.occupancy.adults,
        children: stay.occupancy.children ?? 0,
        childrenAges: stay.occupancy.childrenAges ?? [],
      },
      groupCode: stay.rate.groupCode,
      coupons: mapCoupons(stay.coupon, stay.rate.groupCode),
      roomCount: stay.roomCount,
      extras: mapExtras(stay.extras, priceOptions),
      room: {
        id: stay.room.id,
        name: stay.room.details.name,
      },
      hotel: {
        id: stay.hotel.id,
        name: stay.hotel.details.name,
      },
      cancellation: mapCancellation(stay, priceOptions),
      guarantee: mapGuarantee(stay, priceOptions),
      rate: {
        id: stay.rate.id,
        mealPlan: {
          id: stay.rate.details.mealPlan.id,
          name: stay.rate.details.mealPlan.details.name as MealPlanType,
        },
        averageNightly: {
          netPrice: mapPriceDTO(stay.rate.average.base, priceOptions),
          grossPrice: mapPriceDTO(stay.rate.average.total, priceOptions),
        },
        nightly: stay.rate.nightly.map(night => ({
          date: Time.fromString(night.date).toDate(),
          base: mapPriceDTO(night.base, priceOptions),
          total: mapPriceDTO(night.total, priceOptions),
        })),
        total: {
          taxes: {
            percentage: stay.rate.stay.tax.percentage,
            price: mapPriceDTO(stay.rate.stay.tax.amount, priceOptions),
          },
          netPrice: mapPriceDTO(stay.rate.stay.base, priceOptions),
          grossPrice: mapPriceDTO(stay.rate.stay.total, priceOptions),
          discount: stay.rate.stay.discount?.percentage ?? 0,
        },
        nonConvertedTotal: {
          netPrice: mapPriceDTO(stay.rate.stay.base, {
            getConverted: false,
          }),
          grossPrice: mapPriceDTO(stay.rate.stay.total, {
            getConverted: false,
          }),
        },
        isMember: stay.rate.isMember,
      },
      guest: calculateGuestData(stay.guests),
      ...(isDefined(stay.originalPrice) && {
        originalTotalPrice: stay.originalPrice.stay,
      }),
    }

    return roomStay
  })

const calculateGuestData = (
  guests: RoomStayDTO['guests'],
): ReservationGuest => {
  const emptyGuest = {
    name: '',
    surname: '',
    email: '',
    mainGuest: false,
    address: {
      city: '',
      street: '',
      state: '',
      postalCode: '',
    },
  }

  if (isEmpty(guests)) {
    return emptyGuest
  }

  const guest = {
    name: guests[0]?.givenName ?? '',
    surname: guests[0]?.lastName,
    email: guests[0]?.email ?? '',
    mainGuest: true,
    address: guests[0].address,
  }

  return guests[0].autocompleted ? emptyGuest : guest
}

const calculateCancellationType = (
  cancellation: RoomStayDTO['rate']['cancellation'],
) => {
  if (isUndefined(cancellation)) {
    return 'free'
  }

  if (!cancellation.flexible) {
    return 'non-refundable'
  }

  if (!cancellation.applies) {
    return 'free'
  }

  return 'policy'
}

const normalizePending = (
  stay: RoomStayDTO,
  priceOptions: PriceOptions,
): Price =>
  isDefined(stay.rate.breakdown.payAtHotel)
    ? mapPriceDTO(stay.rate.breakdown.payAtHotel, priceOptions)
    : {
        value: 0,
        currency: mapPriceDTO(stay.rate.stay.base, priceOptions).currency,
      }

const normalizeDeposit = (
  stay: RoomStayDTO,
  priceOptions: PriceOptions,
): Price =>
  isDefined(stay.rate.guarantee.deposit)
    ? mapPriceDTO(stay.rate.guarantee.deposit, priceOptions)
    : {
        value: 0,
        currency: mapPriceDTO(stay.rate.stay.base, priceOptions).currency,
      }

const calculateGuaranteeType = (
  roomStay: RoomStayDTO,
  priceOptions: PriceOptions,
): GuaranteePolicy['type'] => {
  if (
    !roomStay.rate.guarantee.prepay ||
    isUndefined(roomStay.rate.details.policies.guarantee.deposit)
  ) {
    return { key: 'all-hotel' }
  }

  if (normalizePending(roomStay, priceOptions).value === 0) {
    return { key: 'all-prepay' }
  }

  const depositType = roomStay.rate.details.policies.guarantee.deposit.type
  const depositValue = roomStay.rate.details.policies.guarantee.deposit.value

  return {
    key: `partial-${depositType}`,
    value: depositValue,
  }
}

const mapCancellation = (
  stay: RoomStayDTO,
  priceOptions: PriceOptions,
): CancellationPolicy => {
  if (isUndefined(stay.rate.cancellation)) {
    const currency = mapPriceDTO(stay.rate.stay.base, priceOptions).currency
    return getDefaultReservationCancellation(currency)
  }

  return {
    id: stay.rate.cancellation.id,
    cancellationType: calculateCancellationType(stay.rate.cancellation),
    penalty: isDefined(stay.rate.cancellation.penalty)
      ? mapPriceDTO(stay.rate.cancellation.penalty, priceOptions)
      : getDefaultCancellationPenalty(
          mapPriceDTO(stay.rate.stay.base, priceOptions).currency,
        ),
    calculatedCharge: {
      beforeDeadline: mapPriceDTO(
        stay.rate.breakdown.cancellationBeforeDeadline,
        priceOptions,
      ),
      afterDeadline: mapPriceDTO(
        stay.rate.breakdown.cancellationAfterDeadline,
        priceOptions,
      ),
    },
    applies: stay.rate.cancellation.applies ?? false,
    deadline: isDefined(stay.rate.cancellation.deadline)
      ? Time.fromString(stay.rate.cancellation.deadline, { keepTimezone: true })
      : undefined,
    hotelUTC: isDefined(stay.rate.cancellation.deadline)
      ? Time.getUTCOffsetFromISOString(stay.rate.cancellation.deadline)
      : '',
    flexible: stay.rate.cancellation.flexible,
    relative: {
      penalty:
        stay.rate.details.policies.cancellation?.penalty ??
        defaultRateCancellationPenalty,
      deadline: stay.rate.details.policies.cancellation?.deadline,
    },
  }
}

const mapGuarantee = (
  stay: RoomStayDTO,
  priceOptions: PriceOptions,
): GuaranteePolicy => {
  return {
    id: stay.rate.guarantee.id,
    type: calculateGuaranteeType(stay, priceOptions),
    deposit: normalizeDeposit(stay, priceOptions),
    pending: normalizePending(stay, priceOptions),
    deadline: isDefined(stay.rate.guarantee.deadline)
      ? Time.fromString(stay.rate.guarantee.deadline).toDate()
      : undefined,
    prepay: stay.rate.guarantee.prepay,
    relative: {
      deposit: stay.rate.details.policies.guarantee.deposit,
    },
  }
}

const mapCoupons = (
  coupon?: ReservationCouponDTO,
  groupCode?: string,
): ReservationCoupon[] | undefined => {
  const coupons: ReservationCoupon[] = []

  if (isUndefined(groupCode) && isUndefined(coupon)) {
    return undefined
  }

  if (isDefined(groupCode)) {
    coupons.push({
      applies: true,
      type: CouponType.Group,
    })
  }

  if (isDefined(coupon) && coupon.isMemberApplied) {
    coupons.push({
      applies: coupon.applies && coupon.isMemberApplied,
      type: CouponType.Level,
      levelDiscount: parseInt(coupon.tokenDiscount ?? '0'),
    })
  }

  if (
    isDefined(coupon) &&
    isDefined(coupon.isPromotionalCouponApplied) &&
    isDefined(coupon.requested) &&
    !isEmpty(coupon.requested)
  ) {
    coupons.push({
      applies: coupon.applies && coupon.isPromotionalCouponApplied,
      name: coupon.requested,
      type: CouponType.Promotional,
    })
  }

  return !isEmpty(coupons) ? coupons : undefined
}

const mapExtras = (
  extras: ReservationExtraDTO[] | undefined,
  priceOptions: PriceOptions,
): Array<AppliedExtraPerUnit | AppliedExtraPerGuest | NotAppliedExtra> => {
  if (isUndefined(extras) || isEmpty(extras)) {
    return []
  }

  return extras.map(extra => {
    if (!extra.applied) {
      return {
        id: extra.id,
        applied: extra.applied,
      } as NotAppliedExtra
    }

    const commonExtraProps = {
      id: extra.id,
      type: extra.type,
      required: extra.required,
      applied: extra.applied,
      imageUrl: extra.imageUrl,
      name: extra.name,
      description: removeHtmlTags(extra.description),
      ...(extra.summary && {
        summary: {
          netPrice: mapPriceDTO(extra.summary.base, priceOptions),
          tax: {
            percentage: extra.summary.tax.percentage,
            amount: mapPriceDTO(extra.summary.tax.amount, priceOptions),
          },
          grossPrice: mapPriceDTO(extra.summary.total, priceOptions),
        },
      }),
    }

    if (isExtraPerGuest(extra.type)) {
      return {
        ...commonExtraProps,
        adultsQuantity: extra.adultsQuantity,
        childrenQuantity: extra.childrenQuantity ?? 0,
      } as AppliedExtraPerGuest
    }

    return {
      ...commonExtraProps,
      quantity: extra.quantity,
    } as AppliedExtraPerUnit
  })
}
