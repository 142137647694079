import { AnalyticsActionsSharedChangeCoupon } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'

export const changeCoupon: AnalyticsActionsSharedChangeCoupon = coupon => {
  const linkParams = {
    ga4_event_name: 'cta',
    event: 'cta',
    event_category: 'Booking Process',
    event_action: 'Room Selection',
    event_label: 'Coupon Added',
    booking_coupon_name: coupon,
  }
  container
    .resolve('logger')
    .info('Analytics/links/actions/shared/changeCoupon', {
      linkParams,
      utag_data: window.utag_data,
    })
  window.utag.link(linkParams)
}
