import styles from './IconText.module.scss'

import { CSSProperties, FC } from 'react'

import { Color, FontType, IconSize, SpaceType } from 'src/ui/styles/settings'

import { Icon, ViewBoxType } from 'src/ui/components/atoms/Icon/Icon'
import { Text } from 'src/ui/components/atoms/Text'
import { classNames } from 'src/ui/utils/classnames'

interface Props {
  icon: FC<{
    color: string
  }>
  iconSize: IconSize
  iconColor: Color
  text: string
  fontStyle:
    | FontType
    | {
        mobile: FontType
        tablet?: FontType
        laptop?: FontType
        desktop?: FontType
      }
  fontColor:
    | 'light'
    | 'mid'
    | 'dark'
    | 'disabled'
    | 'support-success'
    | 'support-error'
    | 'secondary'
    | 'primary'
  gap: SpaceType
  className?: string
  iconTitle?: string
  viewBox?: ViewBoxType
  withEllipsis?: boolean
}

export const IconText: FC<Props> = ({
  icon,
  iconSize,
  iconColor,
  text,
  fontStyle,
  fontColor,
  gap = 0,
  className,
  iconTitle,
  viewBox,
  withEllipsis,
}) => {
  const styleProps = {
    gap: gap && `var(--b-space-${gap})`,
  } as CSSProperties

  return (
    <span
      className={classNames(styles.container, className)}
      style={styleProps}
    >
      <Icon
        icon={icon}
        size={iconSize}
        color={iconColor}
        iconTitle={iconTitle}
        viewBox={viewBox}
      />
      <Text
        className={classNames(withEllipsis && styles.ellipsis)}
        fontStyle={fontStyle}
        color={fontColor}
      >
        {text}
      </Text>
    </span>
  )
}
