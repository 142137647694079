const availableLanguages = require('./available-languages.json')
const otaClient = require('@crowdin/ota-client').default

const defaultLanguage = 'en-GB'

const getAvailableLanguages = () => {
  return availableLanguages.map(rawLng => rawLng.languageProperties.cbeLanguage)
}

const crowdinConfig = () => ({
  loadPath: '{{lng}}/{{ns}}.json',
  request: async (options, url, payload, callback) => {
    try {
      const client = new otaClient(process.env.NEXT_PUBLIC_CROWDIN_HASH)
      const [language, namespace] = url.split('/')

      const result = await client.getFileTranslations(
        `/content/${getCrowdinLanguageCode(language)}/${namespace}`,
      )

      if (result === null) {
        const translations = await getTranslationsFromLocale(
          language,
          namespace,
        )

        callback(null, {
          status: 200,
          data: JSON.stringify(translations),
        })
        return
      }

      callback(null, {
        status: 200,
        data: JSON.stringify(result),
      })
    } catch (e) {
      // Lo ponemos aquí también ya que Crowdin lanza error cuando se ejecutan las llamadas
      // al manifiesto (la primera llamada que se hace a Crowdin para obtener los idiomas)
      const [language, namespace] = url.split('/')
      const translations = await getTranslationsFromLocale(language, namespace)

      callback(null, {
        status: 200,
        data: JSON.stringify(translations),
      })
    }
  },
})

const getTranslationsFromLocale = async (language, namespace) => {
  const response = await fetch(
    `/locales/${getCrowdinLanguageCode(language)}/${namespace}`,
  )

  return await response.json()
}

const getCrowdinLanguageCode = language => {
  const actualLanguage = availableLanguages.find(
    availableLanguage =>
      language === availableLanguage.languageProperties.cbeLanguage,
  )

  return actualLanguage?.languageProperties.crowdinLanguage ?? defaultLanguage
}

module.exports = {
  getAvailableLanguages,
  defaultLanguage,
  crowdinConfig,
}
