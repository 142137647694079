import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export const mapDiscount = (discount: string | undefined) => {
  if (isUndefined(discount)) {
    return undefined
  }

  const parsedDiscount = parseInt(discount)
  return parsedDiscount > 0 ? parsedDiscount : undefined
}
