import styles from './SelectField.module.scss'
import {
  FocusEventHandler,
  FormEventHandler,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react'
import { classNames } from 'src/ui/utils/classnames'

interface Props {
  placeholder?: string
  options: Array<{ value: any; label: string }>
  ariaLabel?: string
  'data-testid'?: string
  defaultValue?: string

  onChange?: FormEventHandler<HTMLSelectElement>
  onBlur?: FocusEventHandler<HTMLSelectElement>
  name: string
  required?: boolean
  disabled?: boolean
  error?: { message?: string }
}

const SelectFieldWithRef: ForwardRefRenderFunction<HTMLSelectElement, Props> = (
  {
    placeholder,
    options,
    ariaLabel,
    'data-testid': testId,
    defaultValue,
    onChange,
    onBlur,
    name,
    required,
    disabled,
    error,
    ...props
  },
  ref,
) => {
  return (
    <select
      {...props}
      ref={ref}
      onBlur={onBlur}
      name={name}
      required={required}
      disabled={disabled}
      id={name}
      data-testid={testId}
      onChange={onChange}
      aria-label={ariaLabel}
      className={classNames(styles.select, error && styles.error)}
      defaultValue={defaultValue}
    >
      {placeholder && (
        <option disabled value={defaultValue}>
          {placeholder}
        </option>
      )}

      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  )
}

export const SelectField = forwardRef(SelectFieldWithRef)
