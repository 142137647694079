import { FC } from 'react'

interface Props {
  color: string
}

export const CloseIcon: FC<Props> = ({ color }) => (
  <>
    <rect
      x="18.4"
      y="4.2"
      width="2"
      height="20"
      rx="1"
      transform="rotate(45 18.4 4.2)"
      fill={color}
    />
    <rect
      x="19.8"
      y="18.4"
      width="2"
      height="20"
      rx="1"
      transform="rotate(135 19.8 18.4)"
      fill={color}
    />
  </>
)

export default CloseIcon
