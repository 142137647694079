import { createContext, FC, ReactNode, useContext, useMemo } from 'react'
import { container } from 'src/core/Shared/_di'
import { CurrencyISOCode, Price } from 'src/core/Shared/domain/Price.model'
import { MarketPrice } from 'src/core/User/domain/MarketPrice'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { useQueryService } from 'src/ui/hooks/useQuery'

const defaultState = {
  marketPrice: {
    netPrices: true,
    showPricePreviousToDiscount: false,
  },
  calculatePricePreviousToDiscount: () => ({
    value: 0,
    currency: 'USD' as CurrencyISOCode,
  }),
  calculateNightlyPricePreviousToDiscount: () => ({
    value: 0,
    currency: 'USD' as CurrencyISOCode,
  }),
}

export const MarketContext = createContext<{
  marketPrice: MarketPrice | undefined
  calculatePricePreviousToDiscount: (price: Price, discount: Price) => Price
  calculateNightlyPricePreviousToDiscount: (
    price: Price,
    discount: Price,
  ) => Price
}>(defaultState)

interface Props {
  children: ReactNode
}

export const MarketProvider: FC<Props> = ({ children }) => {
  const { queryUtils } = useApplicationRouter()
  const market = queryUtils.getMarketPriceCodeParam()

  const { data: marketPrice, isValidating } = useQueryService(
    'market',
    [market],
    () => {
      const marketPrice = container.resolve('marketRepository').get(market)
      container.resolve('logger').setContext({ market }, 'market-provider')

      return marketPrice
    },
  )

  const getProviderValue = (): MarketPrice | undefined => {
    if (isValidating) {
      return undefined
    }

    if (!marketPrice) {
      return defaultState.marketPrice
    }

    return marketPrice
  }
  const memoizedMarketPrice = useMemo(getProviderValue, [
    isValidating,
    marketPrice,
  ])

  const calculatePricePreviousToDiscount = (price: Price, discount: Price) => {
    return {
      value: price.value + discount.value,
      currency: price.currency,
    }
  }

  const calculateNightlyPricePreviousToDiscount = (
    price: Price,
    discount: Price,
  ) => {
    return {
      value: price.value + discount.value,
      currency: price.currency,
    }
  }

  return (
    <MarketContext.Provider
      value={{
        marketPrice: memoizedMarketPrice,
        calculatePricePreviousToDiscount,
        calculateNightlyPricePreviousToDiscount,
      }}
    >
      {children}
    </MarketContext.Provider>
  )
}

export const useMarket = () => useContext(MarketContext)
