import { container } from 'src/core/Shared/_di'
import { isDefined, isUndefined } from '../wrappers/javascriptUtils'

export function setQuantumUrlToLoggerContext() {
  function tryToSetQuantumUrl() {
    const replayUrl = getQuantumReplayUrl()
    if (isDefined(replayUrl)) {
      container.resolve('logger').setContext({ replayUrl }, 'Quantum')
      container.resolve('logger').info(`Quantum URL: ${replayUrl}`)
      clearInterval(interval)
    }
  }

  const interval = setInterval(tryToSetQuantumUrl, 500)
  tryToSetQuantumUrl()
}

function getQuantumReplayUrl(): string | undefined {
  if (
    isUndefined(window.QuantumMetricAPI) ||
    window.QuantumMetricAPI === null ||
    !window.QuantumMetricAPI.isOn() ||
    window.QuantumMetricAPI.getSessionID() === null
  ) {
    return undefined
  }

  try {
    const url = window.QuantumMetricAPI.getReplay()

    // Puede contener undefined en una parámetro de la url devuelta
    // si quantum no ha podido iniciarlizarse aún.
    if (url.includes('undefined')) {
      return undefined
    }

    return url
  } catch (error) {
    return undefined
  }
}
