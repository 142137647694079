import '/src/ui/styles/index.scss' // Añadimos "/" para que no genere un error knip
import type { AppProps } from 'next/app'
import { ReactElement, ReactNode } from 'react'
import { NextPage } from 'next'
import { registerModules } from 'src/core/Shared/_di/registerModules'
import { appWithTranslation } from 'next-i18next'
import nextI18nConfig from 'next-i18next.config'
import { CountryProvider } from 'src/ui/contexts/CountryContext'
import { ErrorBoundary } from 'react-error-boundary'
import { Error } from 'src/ui/views/Error'
import {
  CustomEvent,
  eventsManager,
} from 'src/core/Shared/infrastructure/eventsManager'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { GlobalAsyncErrorBoundary } from 'src/ui/errors/GlobalAsyncErrorBoundary'
import { container } from 'src/core/Shared/_di'
import { museoSans } from 'src/ui/styles/settings/fonts'
import { Launcher } from 'src/ui/views/_layouts/Launcher'
import { ModalLauncher } from 'src/ui/views/_layouts/ModalLauncher'

if (typeof window !== 'undefined') {
  window.addEventListener(
    'unhandledrejection',
    (event: PromiseRejectionEvent) => {
      // Sólo vamos a mostrar la página de error general cuando el error sea
      // producido por un error de llamada a API no gestionada activamente
      if (event.reason?.name !== 'AxiosError') {
        return
      }
      eventsManager.emit(CustomEvent.APPLICATION_ERROR, event)
    },
  )
}

if (
  process.env.NEXT_PUBLIC_API_MOCKING === 'enabled' &&
  process.env.NEXT_PUBLIC_E2E !== 'true'
) {
  require('../mock-service-worker')
}

if (process.env.NEXT_PUBLIC_DEVTOOLS === 'enabled') {
  require('../src/ui/devtools')
}

registerModules()

export type CustomNextPage = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
  avoidProviders?: boolean
}

type AppPropsWithLayout = AppProps & {
  Component: CustomNextPage
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || (page => page)

  if (isDefined(Component.avoidProviders) && Component.avoidProviders) {
    return (
      <>
        <style jsx global>{`
          :root {
            --b-font-family: ${museoSans.style.fontFamily};
          }
        `}</style>
        <ModalLauncher>
          <ErrorBoundary
            FallbackComponent={Error}
            onError={container.resolve('logger').error}
          >
            <GlobalAsyncErrorBoundary>
              <Component {...pageProps} />
            </GlobalAsyncErrorBoundary>
          </ErrorBoundary>
        </ModalLauncher>
      </>
    )
  }

  return (
    <CountryProvider>
      <style jsx global>{`
        :root {
          --b-font-family: ${museoSans.style.fontFamily};
        }
      `}</style>
      <ErrorBoundary
        FallbackComponent={Error}
        onError={container.resolve('logger').error}
      >
        {/* A pesar de estar por encima del ModalsProvider, GlobalAsyncErrorBoundary puede hacer showModal en el onError
         * y no sabemos por qué. No podemos poner ModalsProvider por encima de GlobalAsyncErrorBoundary porque entonces
         * cualquier Modal no podría usar datos del resto de Providers (Market, Currency, etc.)
         */}
        <GlobalAsyncErrorBoundary>
          <Launcher>{getLayout(<Component {...pageProps} />)}</Launcher>
        </GlobalAsyncErrorBoundary>
      </ErrorBoundary>
    </CountryProvider>
  )
}

export default appWithTranslation(MyApp, nextI18nConfig)
