import { NearbyHotelDTO } from './Hotel.api.dto'
import { mapPriceDTOWithConverted } from 'src/core/Availability/infrastructure/mapPriceDTOWithConverted'

export const mapNearbyHotels = (nearbyHotels: NearbyHotelDTO[] = []) => {
  return nearbyHotels.map(nearbyHotelDTO => ({
    id: nearbyHotelDTO.id,
    bestPriceAverage: {
      netPrice: mapPriceDTOWithConverted(nearbyHotelDTO.bestPriceAverage.base),
      grossPrice: mapPriceDTOWithConverted(
        nearbyHotelDTO.bestPriceAverage.total,
      ),
    },
  }))
}
