import { container } from 'src/core/Shared/_di'
import { CouponValidationType } from 'src/core/Hotel/domain/CouponValidation.model'
import { AnalyticsActionsReservationInvalidPromotionalCoupon } from 'src/core/Shared/domain/Analytics'
import { CouponType } from 'src/core/Reservation/domain/CouponType'

export const invalidPromotionalCoupon: AnalyticsActionsReservationInvalidPromotionalCoupon =
  (coupon, couponValidationResult, applies) => {
    if (coupon.getType() !== CouponType.Promotional) {
      return
    }

    const couponIsValid =
      couponValidationResult.type === CouponValidationType.VALID

    if (couponIsValid && applies) {
      return
    }

    const linkParams = {
      ga4_event_name: 'Coupon error',
      event: 'Coupon error',
      event_category: 'Error',
      event_action: 'Coupon Error',
      event_label: `${coupon.getValue()} ${couponValidationResult.type}`,
    }

    container
      .resolve('logger')
      .info(
        'Analytics/links/actions/choosePayment/invalidPromotionalCoupon',
        linkParams,
      )

    window.utag.link(linkParams)
  }
