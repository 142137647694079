import { EventEmitter } from 'events'

export enum CustomEvent {
  RESET_PRE_RESERVE = 'ResetPreReserve',
  LOGIN = 'Login',
  LOGOUT = 'Logout',
  START_UPDATE_PRE_RESERVE = 'StartUpdatePreReserve',
  UPDATED_PRE_RESERVE_FROM_RESERVE = 'UpdatedPreReserveFromReserve',
  UPDATED_PRE_RESERVE_FROM_CART = 'UpdatePreReserveFromCart',
  APPLICATION_ERROR = 'ApplicationError',
  SHOWED_MARKETING_ALERT = 'ShowedMarketingAlert',
  GET_AVAILABILITY_FRESH_DATA = 'GetAvailabilityFreshData',
}

type Callback<T> = (data?: T) => void

const eventEmitter = new EventEmitter()

export const eventsManager = {
  on: <T>(event: CustomEvent, callback: Callback<T>) => {
    const eventSubscription = eventEmitter.on(event, callback)
    return () => {
      eventSubscription.off(event, callback)
    }
  },
  emit: <T>(event: CustomEvent, ...args: Array<T>) =>
    eventEmitter.emit(event, ...args),
}

export type EventsManager = typeof eventsManager
