import { AnalyticsActionsAvailableRoomsChangeBoardBasis } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'

export const changeBoardBasis: AnalyticsActionsAvailableRoomsChangeBoardBasis =
  mealplan => {
    const linkParams = {
      ga4_event_name: 'click link',
      event: 'click link',
      event_category: 'Booking Process',
      event_action: 'Room Selection',
      event_label: 'Board Basis Change',
      room_board_basis: mealplan?.name ?? 'ALL',
    }
    container
      .resolve('logger')
      .info(
        'Analytics/links/actions/availableRooms/changeBoardBasis',
        linkParams,
      )
    window.utag.link(linkParams)
  }
