import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios'
import { isDefined, isUndefined } from './wrappers/javascriptUtils'
import { getApiAcceptLanguage } from './locales/i18nUtils'
import { container } from 'src/core/Shared/_di'
import { BasicApiClient } from 'src/core/Shared/infrastructure/basicApiClient'
import { UnauthorizedError } from 'src/core/Shared/domain/UnauthorizedError'

export const reservationApiClientAxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BOOKING_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

reservationApiClientAxiosInstance.interceptors.request.use(config => {
  if (isDefined(config.headers)) {
    config.headers['Accept-Language'] = getApiAcceptLanguage()
  }
  return config
})

export class ReservationApiClient extends BasicApiClient {
  itineraryNumber: string | undefined
  auth: {
    isRequired: boolean
    token: string | undefined
  } = {
    isRequired: false,
    token: undefined,
  }

  constructor({
    reservationApiClientAxiosInstance,
  }: {
    reservationApiClientAxiosInstance: AxiosInstance
  }) {
    super(reservationApiClientAxiosInstance)
  }

  modifiesReservation(itineraryNumber: string) {
    this.itineraryNumber = itineraryNumber
    return this
  }

  authorized(token: string | undefined) {
    this.auth = {
      isRequired: true,
      token: token,
    }
    return this
  }

  private addAuthHeader() {
    if (!this.auth.isRequired) {
      return
    }

    if (isDefined(this.auth.token)) {
      this.headers = {
        ...this.headers,
        Authorization: `Bearer ${this.auth.token}`,
      }
    }
  }

  async addChallengeHeader() {
    if (isUndefined(this.itineraryNumber)) {
      return
    }

    const reservationFields = container
      .resolve('reservationStorageRepository')
      .getReservationFields(this.itineraryNumber)

    const encodedItineraryNumber = await container
      .resolve('cryptography')
      .encodeSha256(reservationFields?.itineraryNumber ?? '')

    this.headers = {
      ...this.headers,
      'X-CBE-Challenge': `ITINUM ${encodedItineraryNumber}`,
    }
  }

  private addHeaders = async () => {
    this.addAuthHeader()
    await this.addChallengeHeader()
  }

  async get<P>(
    path: string,
    config?: AxiosRequestConfig,
  ): Promise<P & { data: P }> {
    await this.addHeaders()

    try {
      return await super.get<P & { data: P }>(path, config)
    } catch (error) {
      throw this.mapError(error, path)
    }
  }

  async post<P = void>(
    path: string,
    data?: object,
    config?: AxiosRequestConfig,
  ): Promise<P & { data: P }> {
    await this.addHeaders()

    try {
      return await super.post<P & { data: P }>(path, data, config)
    } catch (error) {
      throw this.mapError(error, path)
    }
  }

  async put<P = void>(
    path: string,
    data?: object,
    config?: AxiosRequestConfig,
  ): Promise<P & { data: P }> {
    await this.addHeaders()

    try {
      return await super.put<P & { data: P }>(path, data, config)
    } catch (error) {
      throw this.mapError(error, path)
    }
  }

  async patch<P = void>(
    path: string,
    data?: object,
    config?: AxiosRequestConfig,
  ): Promise<P & { data: P }> {
    await this.addHeaders()
    try {
      return await super.patch<P & { data: P }>(path, data, config)
    } catch (error) {
      throw this.mapError(error, path)
    }
  }

  private mapError(error: unknown, path: string) {
    if (error instanceof AxiosError) {
      if (error.response?.status === 401) {
        throw new UnauthorizedError(
          JSON.stringify({
            name: 'Unauthorized user',
            path,
            headers: this.headers,
          }),
          error.response?.status,
        )
      }
    }

    throw error
  }
}
