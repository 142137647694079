import { AnalyticsActionsExtrasModifyExtraFromCart } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'

export const modifyExtraFromCart: AnalyticsActionsExtrasModifyExtraFromCart =
  extra => {
    const linkParams = {
      ga4_event_name: 'cart_modify_extras',
      event: 'cart_modify_extras',
      event_category: 'extras',
      event_action: 'click_on_extras_in_cart',
      event_label: 'user modifies its extras selection from cart',
      extra_name: extra.name,
      extra_id: extra.id,
    }
    container
      .resolve('logger')
      .info('Analytics/links/actions/extras/modifyExtraFromCart', {
        linkParams,
        utag_data: window.utag_data,
      })
    window.utag.link(linkParams)
  }
