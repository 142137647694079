import { QueryParamValue } from '../hooks/useApplicationRouter/useApplicationRouter.model'

export const generalConditions = () => {
  return `https://www.barcelo.com/es-es/mybarcelo/condiciones-generales/?redirect=true&lang=true`
}

export const termsAndConditions = () => {
  return `https://www.barcelo.com/es-es/informacion-general/terminos-condiciones/?redirect=auto`
}

export const privacyPolicy = () => {
  return `https://www.barcelo.com/es-es/informacion-general/politica-privacidad/?redirect=true&lang=true`
}

export const hotelList = () => {
  return `https://www.barcelo.com/es-es/hoteles/?redirect=true&lang=true`
}

export const barceloHome = () => {
  return `https://www.barcelo.com/es-es/?redirect=true&lang=true`
}

export const hotelListWithDates = (
  startDate: QueryParamValue,
  endDate: QueryParamValue,
) => {
  return `https://www.barcelo.com/es-es/listado-hoteles/?startDate=${startDate}&endDate=${endDate}/?redirect=true&lang=true`
}

export const redirectTo404 = () => {
  return `https://www.barcelo.com/es-es/404/?redirect=true&lang=auto`
}

export const userPersonalData = () => {
  return `https://www.barcelo.com/es-es/mybarcelo/area-privada/?redirect=true&lang=true#mydata`
}

export const cookies = () => {
  return `https://www.barcelo.com/es-es/informacion-general/politica-cookies/?redirect=true&lang=true`
}

export const legalTerms = () => {
  return `https://www.barcelo.com/es-es/informacion-general/?redirect=true&lang=true`
}

export const legalNotice = () => {
  return `https://www.barcelo.com/es-es/informacion-general/aviso-legal/?redirect=true&lang=true`
}
