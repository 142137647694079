import { AnalyticsPaymentError } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'

/**
 * Se lanza tras volver de ingenico con un status de error de pago.
 */
export const paymentError: AnalyticsPaymentError = ({
  paymentStatus,
  statusCode,
}) => {
  const linkParams = {
    ga4_event_name: 'error',
    event: 'error',
    event_category: 'error',
    event_action: 'payment',
    event_label: `Error after payment page: ${paymentStatus} - ${statusCode}`,
  }
  container
    .resolve('logger')
    .info('Analytics/links/actions/choosePayment/paymentError', {
      linkParams,
      utag_data: window.utag_data,
    })
  window.utag.link(linkParams)
}
