import { FC, Children, cloneElement, ReactElement } from 'react'

import styles from './Media.module.scss'
import { classNames } from 'src/ui/utils/classnames'

interface Props {
  mobile?: boolean
  tablet?: boolean
  laptop?: boolean
  desktop?: boolean
  children: ReactElement | ReactElement[]
}

// Los children del componente Media deben recibir la prop className para que poder ser estilados
// con las clases hideOnMobile, hideOnTablet, hideOnLaptop y hideOnDesktop
export const Media: FC<Props> = ({
  mobile,
  tablet,
  laptop,
  desktop,
  children,
}) => (
  <>
    {Children.map(children, child =>
      cloneElement(child, {
        className: classNames(
          child.props.className,
          !mobile && styles.hideOnMobile,
          !tablet && styles.hideOnTablet,
          !laptop && styles.hideOnLaptop,
          !desktop && styles.hideOnDesktop,
        ),
      }),
    )}
  </>
)
