import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { UserLoyaltyProfileDTO } from './User.api.dto'
import { UserExists, UserNotFound } from '../domain/User'
import { mapDiscount } from './mapDiscount'

export const mapUserExists = (userLoyaltyProfileDTO: UserLoyaltyProfileDTO) => {
  if (
    isUndefined(userLoyaltyProfileDTO.profileLevelCode) ||
    userLoyaltyProfileDTO.profileLevelCode === '-1'
  ) {
    const notFound: UserNotFound = {
      found: false,
    }

    return notFound
  }

  const existingUser: UserExists = {
    found: true,
    discount: mapDiscount(userLoyaltyProfileDTO.discount),
  }
  return existingUser
}
