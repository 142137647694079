import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { isDefined } from './wrappers/javascriptUtils'
import { getApiAcceptLanguage } from './locales/i18nUtils'
import { BasicApiClient } from 'src/core/Shared/infrastructure/basicApiClient'
import type { CacheRequestConfig } from 'axios-cache-interceptor'
import { setupCache } from 'axios-cache-interceptor'

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_HOTEL_INFO_BASE_URL,
})

// @ts-ignore
export const hotelInfoApiClientAxiosInstance = setupCache(axiosInstance)

hotelInfoApiClientAxiosInstance.interceptors.request.use(config => {
  if (isDefined(config.headers)) {
    config.headers['Accept-Language'] = getApiAcceptLanguage()
  }

  return config
})

export class HotelInfoApiClient extends BasicApiClient {
  constructor({
    hotelInfoApiClientAxiosInstance,
  }: {
    hotelInfoApiClientAxiosInstance: AxiosInstance
  }) {
    super(hotelInfoApiClientAxiosInstance)
  }

  async get<P>(
    path: string,
    config?: AxiosRequestConfig & CacheRequestConfig,
  ): Promise<P & { data: P }> {
    return super.get<P & { data: P }>(path, {
      ...config,
      // @ts-ignore
      cache: isDefined(config?.cache)
        ? config?.cache
        : { interpretHeader: false },
    })
  }
}
