import { CurrencyISOCode, Price } from './Price.model'
import { Time } from 'src/core/Shared/infrastructure/Time'

export type CancellationType = 'non-refundable' | 'free' | 'policy'
type PenaltyType = 'percentage' | 'days'

type CancellationDeadlineType = 'before-arrival' | 'after-booking'
type CancellationDeadlineUnit = 'day'

export interface PoliciesDetails {
  cancellation: CancellationPolicy
  guarantee: GuaranteePolicy
}

export interface CancellationPolicy {
  id: string
  cancellationType: CancellationType
  penalty: Price
  applies: boolean
  deadline?: Time
  hotelUTC?: string
  flexible: boolean
  relative: {
    penalty: {
      type: PenaltyType
      value: number
      taxInclusive: boolean
    }
    deadline?: {
      type: CancellationDeadlineType
      value: string
      unit: CancellationDeadlineUnit
    }
  }
  calculatedCharge: CancellationCalculatedCharge
}

export interface CancellationCalculatedCharge {
  beforeDeadline: Price
  afterDeadline: Price
}

export type GuaranteeType =
  | 'all-prepay'
  | 'partial-percentage'
  | 'partial-days'
  | 'all-hotel'

export type DepositType = 'percentage' | 'days'

export interface GuaranteePolicy {
  id: string
  type: {
    key: GuaranteeType
    value?: number
  }
  deposit: Price
  pending: Price
  deadline?: Date
  prepay: boolean
  relative: {
    deposit?: {
      type: DepositType
      value: number
      taxInclusive: boolean
    }
  }
}

export const defaultRateCancellationPenalty: CancellationPolicy['relative']['penalty'] =
  {
    type: 'percentage',
    value: 0,
    taxInclusive: false,
  }

export const getDefaultCancellationPenalty = (
  currency: CurrencyISOCode,
): CancellationPolicy['penalty'] => ({
  value: 0,
  currency,
})

export const getDefaultCancellation = (
  currency: CurrencyISOCode,
): CancellationPolicy => ({
  id: 'FREE',
  applies: false,
  cancellationType: 'free',
  flexible: true,
  penalty: getDefaultCancellationPenalty(currency),
  relative: defaultRelativeCancellation,
  calculatedCharge: {
    beforeDeadline: {
      value: 0,
      currency,
    },
    afterDeadline: {
      value: 0,
      currency,
    },
  },
})

export const defaultRelativeCancellation: CancellationPolicy['relative'] = {
  penalty: defaultRateCancellationPenalty,
}
