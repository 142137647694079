import { FC, ReactNode } from 'react'

import styles from './Card.module.scss'

interface Props {
  children: ReactNode
}
export const Card: FC<Props> = ({ children }) => (
  <div className={styles.container}>{children}</div>
)
