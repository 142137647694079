import { CSSProperties, FC } from 'react'

import styles from './LoaderGradient.module.scss'

import { Color, SpaceType } from 'src/ui/styles/settings'

interface Props {
  size: SpaceType
  colors: Color[]
}

export const LoaderGradient: FC<Props> = ({ size, colors }) => {
  const styleProps = {
    '--size': `var(--b-space-${size})`,
    '--color1': `var(--b-color-${colors[0]})`,
    '--color2': `var(--b-color-${colors[1]})`,
  } as CSSProperties

  return (
    <div
      className={styles.container}
      style={styleProps}
      data-testid="loader-gradient"
      aria-label="loading"
      role="alert"
    >
      <div className={styles.loader} />
    </div>
  )
}
