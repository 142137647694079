import { FC } from 'react'

export const WarningFilledIcon: FC = () => (
  <>
    <rect x="0.5" y="0.5" width="21" height="21" rx="10.5" fill="#FFC83D" />
    <path
      d="M11.0004 13.9629C10.8944 13.9629 10.8086 14.0487 10.8086 14.1547C10.8086 14.2606 10.8944 14.3464 11.0004 14.3464C11.1063 14.3464 11.1921 14.2606 11.1921 14.1547C11.1921 14.0487 11.1063 13.9629 11.0004 13.9629V13.9629"
      stroke="#384044"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 12.429V8.59375"
      stroke="#384044"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8104 5.87988C11.6586 5.57082 11.3443 5.375 10.9999 5.375C10.6556 5.375 10.3413 5.57082 10.1894 5.87988L5.453 15.5289C5.33709 15.7647 5.35111 16.0436 5.49007 16.2666C5.62903 16.4896 5.87325 16.625 6.136 16.6249H15.8639C16.1266 16.625 16.3708 16.4896 16.5098 16.2666C16.6488 16.0436 16.6628 15.7647 16.5469 15.5289L11.8104 5.87988Z"
      stroke="#384044"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default WarningFilledIcon
