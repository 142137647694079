import { useTrans } from 'src/ui/hooks/useTrans'
import { Button, Modal, Text } from 'src/ui/components'
import { Flex } from 'src/ui/styles/objects/Flex'
import { createModal } from 'src/ui/hooks/useModal'

interface Props {
  onClose: () => void
  onSubmit: () => void
}

export const UserErrorModal = createModal(({ onClose, onSubmit }: Props) => {
  const { trans } = useTrans(['common'])

  return (
    <Modal
      size="m"
      type="transactional"
      onClose={onClose}
      data-quantum-modal="UserErrorModal"
    >
      <Text fontStyle="xl-700" color="dark" as="h1" centered className="mb-m">
        {trans('auth-error_modal_title')}
      </Text>
      <Text fontStyle="m-300" color="dark" as="p" centered>
        {trans('auth-error_modal_description')}
      </Text>
      <Flex justifyContent="center">
        <Button
          className="mt-xl"
          size={{
            mobile: 'fullWidth',
            laptop: 'large',
          }}
          onClick={onSubmit}
        >
          {trans('auth-error_modal_button')}
        </Button>
      </Flex>
    </Modal>
  )
})
