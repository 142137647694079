import { CartRepository } from 'src/core/Cart/domain/Cart.repository'
import { Cart, isCartObsolete } from 'src/core/Cart/domain/Cart.model'
import { WithInjectedParams } from 'src/core/Shared/_di/types'
import { ReservationDTO } from 'src/core/Reservation/infrastructure/Reservation.api.dto'
import { ReservationStorageRepository } from 'src/core/Reservation/infrastructure/Reservation.storage.repository'
import { AvailabilityCriteria } from 'src/core/Availability/domain/Availability.model'
import {
  CustomEvent,
  EventsManager,
} from 'src/core/Shared/infrastructure/eventsManager'
import { StepperManager } from 'src/core/Shared/infrastructure/stepperManager'
import { ReservationApiClient } from 'src/core/Shared/infrastructure/reservationApiClient'
import { Analytics } from 'src/core/Shared/domain/Analytics'
import { AxiosError } from 'axios'
import {
  ReservationError,
  ReservationErrorType,
} from 'src/core/Reservation/domain/ReservationError'
import { mapReservationDTOIntoCart } from './mapReservationDTOIntoCart'
import { RatePairStorageRepository } from 'src/core/Shared/infrastructure/RatePair.repository'

interface CartRepositoryDependencies {
  reservationStorageRepository: ReservationStorageRepository
  ratePairStorageRepository: RatePairStorageRepository
  eventsManager: EventsManager
  reservationApiClient: ReservationApiClient
  stepperManager: StepperManager
  analytics: Analytics
}

export const sessionStorageCartRepository: WithInjectedParams<
  CartRepository,
  CartRepositoryDependencies
> = ({
  reservationStorageRepository,
  ratePairStorageRepository,
  eventsManager,
  reservationApiClient,
  stepperManager,
  analytics,
}) => ({
  get: async (reservationId, itineraryNumber, token, currency) => {
    try {
      const reservationDTO = await reservationApiClient
        .modifiesReservation(itineraryNumber)
        .authorized(token)
        .get<ReservationDTO>(`/v1/reservations/${reservationId}`, {
          headers: {
            'X-CBE-Customer-Currency': currency,
          },
        })
      return mapReservationDTOIntoCart(reservationDTO)
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 410) {
          throw new ReservationError(
            error.response?.data?.message ?? 'Unknown error',
            ReservationErrorType.NON_EXISTENT,
          )
        }
        analytics.errors.generic({
          code: error.code,
          status: error.response?.status ?? -1,
          data: error.response?.data,
        })
      }

      throw error
    }
  },
  set: reservation => {
    stepperManager.reset()
    reservationStorageRepository.setReservationFields(
      reservation.id,
      reservation.itineraryNumber,
    )
  },
  deleteObsolete: (cart: Cart, availabilityCriteria: AvailabilityCriteria) => {
    if (!isCartObsolete(cart, availabilityCriteria)) {
      return
    }
    stepperManager.reset()
    eventsManager.emit(CustomEvent.RESET_PRE_RESERVE)
  },
  deleteCart: () => {
    ratePairStorageRepository.remove()
    stepperManager.reset()
    eventsManager.emit(CustomEvent.RESET_PRE_RESERVE)
  },
})
