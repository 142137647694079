import { FC, ReactNode } from 'react'
import { ModalsProvider } from 'src/ui/contexts/ModalsProvider'
import { useMaxModalHeight } from 'src/ui/hooks/useMaxModalHeight'

interface ChildrenProps {
  children: ReactNode
}

export const ModalLauncher: FC<ChildrenProps> = ({ children }) => {
  useMaxModalHeight()

  return <ModalsProvider>{children}</ModalsProvider>
}
