import { AuthRepository } from 'src/core/User/domain/Auth.repository'
import { Analytics } from 'src/core/Shared/domain/Analytics'

export const getUser =
  ({ authRepository, analytics }: Dependencies) =>
  async (token: string) => {
    const user = await authRepository.getUser(token)
    analytics.requests.user(user)

    return user
  }

interface Dependencies {
  authRepository: AuthRepository
  analytics: Analytics
}
