import { useTrans } from 'src/ui/hooks/useTrans'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { Button, Divider, Link, Text } from 'src/ui/components'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Translate } from 'src/ui/i18n/Translate'
import styles from './Error.module.scss'
import { useCallCenter } from 'src/ui/hooks/useCallCenter'
import { Image } from 'src/ui/components/atoms/Image'
import { barceloHome } from 'src/ui/navigation/cmsLinks'
import { useEffect, useRef } from 'react'
import { Media } from 'src/ui/styles/objects/Media'
import { location } from 'src/ui/navigation/location'
import { container } from 'src/core/Shared/_di'

export const Error = ({ resetErrorBoundary }: any) => {
  const { trans } = useTrans(['common'])
  const { reload, asPath, query } = useApplicationRouter()
  const errorLocation = useRef(asPath)

  const { callCenterCountryCode, callCenterPhone, callCenterFreePhone } =
    useCallCenter()

  const getImgUrl = () =>
    `${container.resolve('envManager').getBaseImagesUrl()}default-error-page`

  const handleClick = () => {
    const {
      itineraryNumber,
      orderNumber,
      paymentCurrency,
      statusCode,
      paymentMethodCode,
      token,
      transactionId,
      paymentAmount,
      orderSignature,
      merchantAccount,
      ...queryWithoutParams
    } = query

    location.toAvailabilityWithReload(queryWithoutParams)
  }

  useEffect(() => {
    if (asPath !== errorLocation.current) {
      resetErrorBoundary()
    }
  }, [asPath])

  return (
    <>
      <header className={styles.header}>
        <div className={styles.content}>
          <a href={barceloHome()}>
            <Image
              src="/assets/images/main-logo.svg"
              height={32}
              width={91}
              alt="barcelo-logo"
            />
          </a>
        </div>
      </header>
      <section className={styles.imageContainer}>
        <Image
          alt="error"
          src={getImgUrl()}
          fill
          sizes={`(min-width: 0px) 100vw`}
          className={styles.image}
          quality={75}
        />
      </section>
      <section
        className={styles.textContainer}
        data-quantum-error="unhandled error"
      >
        <Text fontStyle="2xl-700" as="h1">
          {trans('generic-error_title')}
        </Text>
        <Media mobile tablet>
          <Divider className={styles.divider} dir="horizontal" />
        </Media>
        <Flex direction="column" gap="m" className="mt-m">
          <Text fontStyle="m-300">{trans('generic-error_description')}</Text>

          <Translate
            i18nKey="generic-error_call-us"
            values={{
              free: `${callCenterPhone}${callCenterFreePhone}`,
              moreOptions: trans('generic-error_other-options'),
            }}
            components={{
              regular: <Text as="p" fontStyle="m-300" />,
              bold: (
                <Link
                  data-data-phone={callCenterCountryCode}
                  link={`tel:${callCenterPhone}`}
                  variant="secondary"
                >
                  {`${callCenterPhone}${callCenterFreePhone}`}
                </Link>
              ),
              options: (
                <Text fontStyle="m-300">
                  {trans('generic-error_other-options')}
                </Text>
              ),
            }}
          />
          <div className={styles.buttonWrapper}>
            <Button
              type="submit"
              size={{
                mobile: 'fullWidth',
                laptop: 'large',
              }}
              variant="secondary"
              onClick={handleClick}
            >
              {trans('generic-error_go-home')}
            </Button>
            <Button
              type="submit"
              size={{
                mobile: 'fullWidth',
                laptop: 'large',
              }}
              onClick={reload}
            >
              {trans('generic-error_reload')}
            </Button>
          </div>
        </Flex>
      </section>
    </>
  )
}
