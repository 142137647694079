import { FC } from 'react'

interface Props {
  color: string
}

export const ChevronIcon: FC<Props> = ({ color }) => (
  <path
    d="M9 18.5L15.75 11.75L9 5"
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)

export default ChevronIcon
