import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export interface User {
  level: UserLevel
  givenName: string
  familyName: string
  middleName?: string
  discount?: number
  email: string
  address: string
  city: string
  crmId: string
  crsId: string
  phoneNumber: string
  postalCode: string
  profileLevelCode: string
  state: string
  street: string
}

export enum UserLevel {
  NotFound = 'notFound',
  Start = 'start',
  Intense = 'intense',
  Unique = 'unique',
}

export interface UserExists {
  found: true
  discount: number | undefined
}

export interface UserNotFound {
  found: false
}

export const hasLevelDiscount = (user: User) => {
  return isDefined(user.discount) && user.discount > 0
}
