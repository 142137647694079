import { FC } from 'react'

interface Props {
  color: string
}

export const UncheckedHoveredCheckboxIcon: FC<Props> = ({ color }) => (
  <path
    d="M5 3.5H19C19.8284 3.5 20.5 4.17157 20.5 5V19C20.5 19.8284 19.8284 20.5 19 20.5H5C4.17157 20.5 3.5 19.8284 3.5 19V5C3.5 4.17157 4.17157 3.5 5 3.5Z"
    fill="#EAEEF1"
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)

export default UncheckedHoveredCheckboxIcon
