import { ExtraType } from 'src/core/Shared/domain/Extra.model'
import { NightlyRate } from 'src/core/Shared/domain/NightlyRate.model'
import { PoliciesDetails } from 'src/core/Shared/domain/Policies.model'
import { Price } from 'src/core/Shared/domain/Price.model'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import {
  RoomStayRate,
  SelectedMealplan,
} from 'src/core/Reservation/domain/Reservation.model'
import { PromotionalValidCoupon } from 'src/core/Hotel/domain/PromotionalValidCoupon.model'

export interface Availability {
  hotel: {
    id: string
    name: string
  }
  stays: AvailabilityStay[]
  groupCode?: string
}

export interface AvailabilityStay {
  details: StayDetails
  mealplanFilters: AvailabilityMealplanFilter[]
  rooms: AvailabilityRoom[]
}

export interface StayDetails {
  startDate: Date
  endDate: Date
  occupancy: {
    adults: number
    children: number
  }
}

export interface AvailabilityRoom {
  id: string
  name: string
  occupancy: { adults: number; children: number; guests: number }
  mealplans: AvailabilityMealplan[]
  inventory: number
}

export interface AvailabilityMealplan {
  id: AvailabilityMealplanId
  name: string
  rates: AvailabilityRate[]
}

export type AvailabilityMealplanId =
  | 'RO'
  | 'BB'
  | 'HB'
  | 'FB'
  | 'AI'
  | 'AIPR'
  | 'AIPL'

export interface AvailabilityMealplanFilter {
  id: AvailabilityMealplanId
  name: string
}

export type AvailabilityRoomTypeFilter = string

export interface AvailabilityRate extends PoliciesDetails {
  id: string
  name: string
  description?: string
  averageNightly: {
    netPrice: Price
    grossPrice: Price
    netDiscount?: Price
    grossDiscount?: Price
  }
  total: {
    netPrice: Price
    grossPrice: Price
    netDiscount?: Price
    grossDiscount?: Price
  }
  nightly: NightlyRate[]
  nonConvertedTotal: {
    netPrice: Price
    grossPrice: Price
  }
  isMember: boolean
  isMobile: boolean
  isRelated: boolean
  relatedRate?: AvailabilityRelatedRateMetadata
  coupon?: Coupon
}

type AvailabilityRelatedRate = Omit<AvailabilityRate, 'relatedRate'>
export type AvailabilityFlatRate = AvailabilityRelatedRate

export interface AvailabilityRelatedRateMetadata {
  rate: AvailabilityRelatedRate
  association: RelatedRateAssociation
  convertedDifference: Price
}

export interface Coupon {
  id: string
  applies: boolean
}

export interface AvailabilityCriteria {
  market: string
  hotel: string
  checkIn: Date
  checkOut: Date
  adults: number[]
  children: number[]
  childrenAges?: Array<Array<number>>
  currency: string
  coupons?: string[]
  groupCode?: string
  membership: boolean
}

export type AvailabilityExtra =
  | AvailabilityExtraPerGuest
  | AvailabilityExtraPerUnit

interface BaseAvailabilityExtra {
  id: string
  type: ExtraType
  rates: string[]
  required: boolean
  name: string
  description: string
  maxQuantity: number
  imageUrl: string | undefined
}

export interface AvailabilityExtraPerUnit extends BaseAvailabilityExtra {
  price: {
    netPrice: Price
    tax: {
      percentage: string
      amount: Price
    }
    grossPrice: Price
  }
}

export interface AvailabilityExtraPerGuest extends BaseAvailabilityExtra {
  adultsPrice: {
    netPrice: Price
    tax: {
      percentage: string
      amount: Price
    }
    grossPrice: Price
  }
  childrenPrice?: {
    netPrice: Price
    tax: {
      percentage: string
      amount: Price
    }
    grossPrice: Price
  }
}

export interface AvailabilityExtrasCriteria {
  hotel: string
  checkIn: Date
  checkOut: Date
  adults: number[]
  children: number[]
  childrenAges?: Array<Array<number>>
  roomIds: string[]
  rateIds: string[]
  ratesCurrencies: string[]
  currency: string
}

export interface MealplanFilterWithRelatedLowestPrice {
  mealplanFilter: AvailabilityMealplanFilter
  lowestPrice: AvailabilityRate['total'] | undefined
}

export type RelatedRateAssociation =
  | 'EXACT_MATCH'
  | 'GUARANTEE_MISMATCH'
  | 'CANCELLATION_MISMATCH'
  | 'GUARANTEE_AND_CANCELLATION_MISMATCH'

export interface RoomImageSlide {
  src: string
  alt: string
}

export const shouldGetAvailabilityWithGroupCode = (
  availabilityCriteria: AvailabilityCriteria | undefined,
) => {
  return isDefined(availabilityCriteria?.groupCode)
}

const isAvailabilityRate = (
  object: AvailabilityRate | RoomStayRate,
): object is AvailabilityRate => 'name' in object

const isExtraWithSimplePrice = (
  object: AvailabilityExtra,
): object is AvailabilityExtraPerUnit => 'price' in object

export const getLowestExtraPrice = (extra: AvailabilityExtra) => {
  if (isExtraWithSimplePrice(extra)) {
    return {
      netPrice: extra.price.netPrice,
      grossPrice: extra.price.grossPrice,
    }
  }

  if (isDefined(extra.childrenPrice)) {
    return {
      netPrice:
        extra.adultsPrice.netPrice.value > extra.childrenPrice.netPrice.value
          ? extra.childrenPrice.netPrice
          : extra.adultsPrice.netPrice,
      grossPrice:
        extra.adultsPrice.grossPrice.value >
        extra.childrenPrice.grossPrice.value
          ? extra.childrenPrice.grossPrice
          : extra.adultsPrice.grossPrice,
    }
  }

  return {
    netPrice: extra.adultsPrice.netPrice,
    grossPrice: extra.adultsPrice.grossPrice,
  }
}

export const isFreeExtra = (
  extra: AvailabilityExtraPerGuest | AvailabilityExtraPerUnit,
) => {
  if (isExtraWithSimplePrice(extra)) {
    return extra.price.netPrice.value === 0
  }

  if (isDefined(extra.childrenPrice)) {
    return (
      extra.adultsPrice.netPrice.value === 0 &&
      extra.childrenPrice.netPrice.value === 0
    )
  }

  return extra.adultsPrice.netPrice.value === 0
}

export const getTotalNightsFromAvailability = (
  availability: AvailabilityStay | undefined,
) => {
  if (isUndefined(availability)) {
    return
  }

  return datesManager.calculateNights(
    availability?.details.startDate,
    availability?.details.endDate,
  )
}

export const isAvailabilityExtraPerGuest = (
  extra: AvailabilityExtra,
): extra is AvailabilityExtraPerGuest => 'adultsPrice' in extra

const chooseLowestPrice = (
  lowestMemberPrice: AvailabilityRate['total'] | undefined,
  lowestStandardPrice: AvailabilityRate['total'] | undefined,
): AvailabilityRate['total'] | undefined => {
  if (isUndefined(lowestMemberPrice) && isUndefined(lowestStandardPrice)) {
    return undefined
  }

  if (isUndefined(lowestMemberPrice)) {
    return lowestStandardPrice
  }

  if (isUndefined(lowestStandardPrice)) {
    return lowestMemberPrice
  }

  if (
    lowestStandardPrice.grossPrice.value < lowestMemberPrice.grossPrice.value
  ) {
    return lowestStandardPrice
  } else {
    return lowestMemberPrice
  }
}

export const getRateFromRoom = (room: AvailabilityRoom, rateId?: string) => {
  return room.mealplans
    .flatMap(mealplan => {
      return mealplan.rates.flatMap(rate =>
        [rate, rate.relatedRate?.rate].filter(isDefined),
      )
    })
    .find(rate => rate.id === rateId)
}

export const getAllRatesIdsFromRoom = (room: AvailabilityRoom) => {
  return room.mealplans.flatMap(mealplan => {
    return mealplan.rates.flatMap(rate =>
      [rate.id, rate.relatedRate?.rate.id].filter(isDefined),
    )
  })
}

export const getMealplanLowestPrice = (mealplan: AvailabilityMealplan) => {
  const totalMemberPrices = mealplan.rates.map(rate => {
    return rate.total
  })
  const totalStandardPrices = mealplan.rates
    .map(rate => {
      return rate.relatedRate?.rate.total
    })
    .filter(isDefined)

  const lowestMemberPrice = getLowestPriceFrom(totalMemberPrices)
  const lowestStandardPrice = getLowestPriceFrom(totalStandardPrices)

  return chooseLowestPrice(lowestMemberPrice, lowestStandardPrice)
}

export const getMealplanLowestNightlyPrice = (
  mealplan: AvailabilityMealplan,
) => {
  const averageNightlyMemberPrices = mealplan.rates.map(rate => {
    return rate.averageNightly
  })
  const averageNightlyStandardPrices = mealplan.rates
    .map(rate => {
      return rate.relatedRate?.rate.averageNightly
    })
    .filter(isDefined)

  const lowestMemberPrice = getLowestPriceFrom(averageNightlyMemberPrices)
  const lowestStandardPrice = getLowestPriceFrom(averageNightlyStandardPrices)

  return chooseLowestPrice(lowestMemberPrice, lowestStandardPrice)
}

const getLowestPriceFrom = (rateTotalPrices: AvailabilityRate['total'][]) => {
  if (rateTotalPrices.length === 0) {
    return undefined
  }

  return rateTotalPrices.reduce((previous, current) => {
    return previous.grossPrice.value < current.grossPrice.value
      ? previous
      : current
  })
}

export const findBaseRateByItsRelated = (
  availability: Availability,
  selectedMealplan: SelectedMealplan,
  roomName: string,
  rateId: string,
) => {
  let baseRate: AvailabilityRate | undefined = undefined

  init: for (const stay of availability.stays) {
    for (const room of stay.rooms) {
      if (room.name === roomName) {
        for (const mealplan of room.mealplans) {
          if (mealplan.name === selectedMealplan.name) {
            for (const rate of mealplan.rates) {
              if (
                isDefined(rate.relatedRate) &&
                rate.relatedRate.rate.id === rateId
              ) {
                baseRate = rate
                break init
              }
            }
          }
        }
      }
    }
  }

  return baseRate
}

export const hasAvailabilityDiscount = (availability: AvailabilityStay) => {
  return availability.rooms.some(room =>
    room.mealplans.some(mealplan =>
      mealplan.rates.some(rate => {
        return (
          isDefined(rate.averageNightly.netDiscount) ||
          isDefined(rate.averageNightly.grossDiscount)
        )
      }),
    ),
  )
}

export interface HowCouponAppliesInRate {
  couponAppliesIn: 'bothRates' | 'memberRate' | 'standardRate' | 'none'
  is: 'standardRate' | 'memberRate'
  baseRate: AvailabilityRate | undefined
}

export const getHowCouponAppliesInRate = (
  roomName: string,
  mealplan: SelectedMealplan,
  selectedRate: AvailabilityRate,
  availability: Availability,
): HowCouponAppliesInRate => {
  const baseRate = findBaseRateByItsRelated(
    availability,
    mealplan,
    roomName,
    selectedRate.id,
  )

  const couponAppliesInBothRates =
    isDefined(selectedRate.coupon) &&
    selectedRate.coupon.applies &&
    (isDefined(baseRate)
      ? isDefined(baseRate.coupon) && baseRate.coupon.applies
      : true)

  const isMemberRate = selectedRate.isMember && !selectedRate.isRelated
  const appliesInSelectedRate = selectedRate.coupon?.applies
  const appliesInSelectedRelatedRate =
    selectedRate.relatedRate?.rate.coupon?.applies
  const appliesInBaseRate = baseRate?.coupon?.applies

  const couponAppliesOnlyInStandardRate = isMemberRate
    ? !appliesInSelectedRate && appliesInSelectedRelatedRate
    : !appliesInBaseRate && appliesInSelectedRate

  const couponAppliesOnlyInMemberRate = isMemberRate
    ? appliesInSelectedRate && !appliesInSelectedRelatedRate
    : appliesInBaseRate && !appliesInSelectedRate

  return {
    couponAppliesIn: couponAppliesInBothRates
      ? 'bothRates'
      : couponAppliesOnlyInMemberRate
        ? 'memberRate'
        : couponAppliesOnlyInStandardRate
          ? 'standardRate'
          : 'none',
    is: isMemberRate ? 'memberRate' : 'standardRate',
    baseRate,
  }
}

export const findSelectedRate = (
  availability: Availability,
  selectedMealplan: SelectedMealplan,
  roomName: string,
  rateId: string,
): AvailabilityRate | undefined => {
  let selectedRate:
    | AvailabilityRate
    | AvailabilityRelatedRateMetadata
    | undefined = undefined

  init: for (const stay of availability.stays) {
    for (const room of stay.rooms) {
      for (const mealplan of room.mealplans) {
        for (const rate of mealplan.rates) {
          if (
            mealplan.name === selectedMealplan.name &&
            room.name === roomName
          ) {
            if (rate.id === rateId) {
              selectedRate = rate
              break init
            } else {
              if (
                isDefined(rate.relatedRate) &&
                rate.relatedRate.rate.id === rateId
              ) {
                selectedRate = rate.relatedRate.rate
                break init
              }
            }
          }
        }
      }
    }
  }

  return selectedRate
}

export const doesCouponAppliesInSelectedRate = (
  rate: AvailabilityRate | RoomStayRate,
  mealplan: SelectedMealplan,
  roomName: string,
  availability: Availability,
) => {
  const selectedRate = isAvailabilityRate(rate)
    ? rate
    : findSelectedRate(availability, mealplan, roomName, rate.id)

  return selectedRate?.coupon?.applies ?? false
}

export function getBestPriceForRoom(room: AvailabilityRoom) {
  return room.mealplans
    .flatMap(mealplan => {
      return mealplan.rates.flatMap(rate => {
        const ratePrices = [
          {
            netPrice: rate.averageNightly.netPrice,
            grossPrice: rate.averageNightly.grossPrice,
          },
        ]

        if (isDefined(rate.relatedRate)) {
          ratePrices.push({
            netPrice: rate.relatedRate.rate.averageNightly.netPrice,
            grossPrice: rate.relatedRate.rate.averageNightly.grossPrice,
          })
        }

        return ratePrices
      })
    })
    .reduce(
      (bestRatePrice, ratePrice) => {
        if (ratePrice.grossPrice.value < bestRatePrice.grossPrice.value) {
          return ratePrice
        }
        return bestRatePrice
      },
      {
        netPrice: { value: Number.MAX_VALUE, currency: 'EUR' },
        grossPrice: { value: Number.MAX_VALUE, currency: 'EUR' },
      },
    )
}

export const isCouponAppliedInRate = (
  rate: AvailabilityRate,
  promotionalCoupon: PromotionalValidCoupon,
) => {
  if (isUndefined(promotionalCoupon)) {
    return false
  }

  return (
    (rate.coupon?.id ?? '').toLowerCase() ===
      promotionalCoupon.id.toLowerCase() && rate.coupon?.applies
  )
}

export const howCouponAppliesInRate = (
  baseRate: AvailabilityRate,
  promotionalCoupon?: PromotionalValidCoupon,
) => {
  // Necesita recibir un base rate para comprobar si aplica el cupón tanto en el rate como en el related rate
  if (isUndefined(promotionalCoupon)) {
    return
  }
  if (
    isCouponAppliedInRate(baseRate, promotionalCoupon) &&
    isDefined(baseRate.relatedRate) &&
    isCouponAppliedInRate(baseRate.relatedRate.rate, promotionalCoupon)
  ) {
    return 'both'
  }
  if (
    isCouponAppliedInRate(baseRate, promotionalCoupon) ||
    (isDefined(baseRate.relatedRate) &&
      isCouponAppliedInRate(baseRate.relatedRate.rate, promotionalCoupon))
  ) {
    return 'some'
  }
}

export const transformTextToValidChildren = (value: string) => {
  if (value === 'undefined') {
    return {
      validChildren: '0',
    }
  }

  return {
    validChildren: value,
  }
}

export const transformTextToChildAges = (
  value: string,
  child: string | undefined,
) => {
  if (isUndefined(child)) {
    return {
      validChildAges: '',
    }
  }

  // Si las últimas posiciones de child vienen con 0, están viniendo las edades de los niños sin coma. Se las ponemos.
  const numChildren = child.split(',').length
  const numChildAges = value.split(',').length

  const numCommasToAdd = numChildren - numChildAges
  const childAgesWithCommas =
    value.trim() + ','.repeat(numCommasToAdd > 0 ? numCommasToAdd : 0)

  //Si el valor de child contiene un 0, sustituimos en la misma posición de childAges por cadena vacía porque está viniendo 0
  const childArray = child.split(',')

  const resultArray = childArray.map((childValue, index) => {
    if (childValue === '0') {
      return ''
    }
    return childAgesWithCommas.split(',')[index] || ''
  })
  const validChildAges = resultArray.join(',')

  return {
    validChildAges,
  }
}
